export const firebaseConfig={
    apiKey: "AIzaSyCXozyGWYSxm7r692ggcOQsYeLnV4PdvWI",
    authDomain: "snow-intel.firebaseapp.com",
    databaseURL: "https://snow-intel.firebaseio.com",
    projectId: "snow-intel",
    storageBucket: "snow-intel.appspot.com",
    messagingSenderId: "432407524945",
    appId: "1:432407524945:web:7143e7bfa75b474398ea70",
    measurementId: "G-RMNHZYG6C8"
}

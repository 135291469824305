import {LOCATION, LOCATION_SUCCESS, LOCATION_FAIL} from '../actions/types.js'

const INITIAL_STATE = {
  location: [],
  locationLoading: false,
  locationError: '',
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOCATION:
      return {...state, locationLoading: true, location: action.payload}
    case LOCATION_SUCCESS:
      return {...state, ...INITIAL_STATE, locationLoading: false, location: action.payload}
    case LOCATION_FAIL:
      return {
        ...state,
        locationError: action.payload,
        locationLoading: false,
      }
    default:
      return state
  }
}
import {FORECAST, FORECAST_SUCCESS, FORECAST_FAIL} from '../actions/types.js'

const INITIAL_STATE = {
  forecast: [],
  forecastLoading: false,
  forecastError: '',
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FORECAST:
      return {...state, forecastLoading: true, forecast: action.payload}
    case FORECAST_SUCCESS:
      return {...state, ...INITIAL_STATE, forecastLoading: false, forecast: action.payload}
    case FORECAST_FAIL:
      return {
        ...state,
        forecastError: action.payload,
        forecastLoading: false,
      }
    default:
      return state
  }
}
import React, {Component} from 'react';
import { Modal, Card,Row,Form,Input, Icon, Button,Alert, message} from 'antd';
import firebase from "./firebase";


class  ChangePasswordModal extends Component{
    constructor(props) {
        super(props);
        this.state={
            showModal:true,
            mailId: null,
            showPassAlertValue:false,
            newPassword:'',
            currentPassword:'',
            showAlertValue:false
        }
    }
    handleCancel(){
        // this.setState({ showModal : false})
        this.props.history.push('/')
    }

    forgotPass(e){
        const {mailId}=this.state
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                console.log('Received values of form: ', values);
                if(values.mailId && values.mailId != null){
                    firebase.auth().sendPasswordResetEmail(values.mailId).then((res)=>{
                        console.log("forgotpass",res)
                        this.setState({showPassAlertValue:true},()=>{
                                this.props.onResetPassCallBack(false,this.state.showPassAlertValue);
                        })
                    }).catch((err)=>{
                        console.log("forgotpass",err)
                    })
                }
            }
        });
    }

     updatePassword(e){
        const {newPassword,currentPassword}=this.state
        e.preventDefault();
         this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                console.log('Received values of form: ', values);
                if(values.newPassword && values.newPassword != null && values.newPassword.length >= 6 &&
                    values.currentPassword && values.currentPassword != null){

                    var user = firebase.auth().currentUser;
                    var credential = firebase.auth.EmailAuthProvider.credential(
                        firebase.auth().currentUser.email,
                        values.currentPassword
                    );

                  // Prompt the user to re-provide their sign-in credentials
                     user.reauthenticateWithCredential(credential).then(function() {
                        // User re-authenticated.
                                firebase.auth().currentUser.updatePassword(values.newPassword).then(function(){
                                    message.success('Password updated!')

                                    //Do something
                                    console.log("Password updated!");
                                    // this.props.onChangePassCallBack(false,true);
                                            this.setState({showAlertValue:true},()=>{
                                                console.log("showAlertValue",this.state.showAlertValue)
                                                this.props.onChangePassCallBack(false,this.state.showAlertValue);
                                            });
                                    // if (this.timer) {
                                    //     clearTimeout(this.timer)
                                    // }
                                    // this.timer = setTimeout(function() {
                                    //     if(this.state.showAlertValue){
                                    //         this.setState({ showAlertValue: false });
                                    //     }
                                    //     this.timer = null;
                                    // }.bind(this), 2000);
                                }).catch(function(err){
                                    //Do something
                                    // console.log(err,"err")
                                });
                    }).catch(function(error) {
                        // console.log(error,"error")
                        // An error happened.
                    });
                }
                else{
                    message.error('Password length must be greater than or equal to 6')
                }

            }
        });
    }

    render(){

        const {showPassAlertValue,showAlertValue} = this.state;
        const {screen} =this.props;
        const { getFieldDecorator, getFieldsError, getFieldError, isFieldTouched } = this.props.form;
        console.log("showAlertValue",showAlertValue)
        return(
            <div>
                <Card className="mdl-card mdl-shadow--2dp firebaseui-container firebaseui-id-page-sign-in">
                    {screen === "login" &&
                    <Form onSubmit={(e) => this.forgotPass(e)}>

                        <Form.Item label="E-mail">
                            {getFieldDecorator('mailId', {
                                rules: [
                                    {
                                        type: 'email',
                                        message: 'Enter Valid E-mail!',
                                    },
                                    {
                                        required: true,
                                        message: 'Please input your E-mail!',
                                    },
                                ],
                            })(<Input/>)}
                        </Form.Item>
                        <div className="forgotSubmit">
                            <Button type="primary" id="common-button-view" htmlType="submit" disabled={showPassAlertValue}>
                                Submit
                            </Button>
                        </div>
                    </Form>
                    }
                    {screen === "changePassword" &&
                    <Form onSubmit={(e) => this.updatePassword(e)}>
                        <Form.Item label="Current Password">
                            {getFieldDecorator('currentPassword', {
                                rules: [
                                    {
                                        required: true,
                                        message: 'Please Enter Current Password!',
                                    },
                                ],
                            })(<Input.Password />)}
                        </Form.Item>
                        <Form.Item label="New Password">
                            {getFieldDecorator('newPassword', {
                                rules: [
                                    {
                                        required: true,
                                        message: 'Please Enter New Password!',
                                    },
                                ],
                            })(<Input.Password/>)}
                        </Form.Item>
                        <div className="forgotSubmit">
                            <Button type="primary" id="common-button-view" htmlType="submit">
                                Submit
                            </Button>
                        </div>
                    </Form>
                    }
                </Card>
            </div>
        )

}
}

export default Form.create()(ChangePasswordModal);

import React from 'react';
import { Link } from "react-router-dom";
import {Card} from 'antd';
import styles from '../payment.Style';

const CardList = (props) => {
  console.log(props,'props')

    return (
        <div style={{padding: '10px' }}>
        <Card style={styles.card} id="modal-card">
            <h2>Card List</h2>
            <Link to='./addcard'>
              {/* <a> */}
                <button type="button" className="btn btn-primary" id="common-button-view">
                  Add new card
                </button>
              {/* </a> */}
            </Link>
            </Card>
          </div>
    );
  } 

export default CardList;

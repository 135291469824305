export default {
    fullscreenControlStyle: {
        position: 'absolute',
        top: 0,
        left: 0,
        padding: '10px'
      },
      geolocateStyle: {
        position: 'absolute',
        // top: 50,
        right: 0,
        margin: 15,
        backgroundColor:'#fff'
      },
      placeholderStyle: {
        position: 'absolute',
        bottom: 0,
        right: 50,
        marginBottom: 20,
        width: 85,
        height: 34,
        color: 'white',
        backgroundColor: 'black'
      },
      alertStyle: {
        height: 40,
        width: 173,
        top: 51,
        position: 'absolute',
        left: 8,
      },
      confirmStyle: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: 40,
        width: 267,
        left: 500,
        marginTop: 20
        // top: 51,
        // position: 'absolute',
        // left: 8,
      },
       navStyle :{
        position: 'fixed',
        top: 70, //100
        right: 0,
        padding: '10px',
		marginTop: '10px'
      },
      feedbackStyle: {
        position: 'absolute',
        top: 0,
        left: 0,
        // marginTop: 10,
        backgroundColor:'#fff',
        width:'100%'
      },
      iconDivStyle: {
        position: 'absolute',
        flexDirection: 'column',
        display: 'flex',
        right: 0,
        bottom:'8%',
        padding: 10,
      },
      iconEnableViewStyle: {
        backgroundColor:'#FFF',
        borderRadius:10,
        marginBottom:10,
        padding:13,
        cursor:'pointer'
      },
      iconDisableViewStyle: {
        backgroundColor:'#000',
        borderRadius:10,
        marginBottom:10,
        padding:13,
        cursor:'pointer'
      },
      iconEnableStyle: {
        color: 'black',
        fontSize: 20 ,
        cursor:'pointer',
      },
      iconDisableStyle: {
        color: 'white',
        fontSize: 20 ,
        cursor:'pointer'
      },
      infoIconStyle: {
        paddingLeft:0,
        paddingRight:0,
        color: 'white',
        fontSize: 16 ,
      },
      switcherIconStyle: {
        // paddingLeft:6,
        paddingRight:6,
        color: 'white',
        fontSize: 18 ,
        // marginTop: 18,
      },
      ellipsisIconStyle: {
        paddingLeft:12,
        paddingRight:12,
        color: 'white',
        fontSize: 20 ,
      },
      ellipsisIconView: {
        paddingLeft:12,
        paddingRight:12,
        color: 'white',
        fontSize: 20 ,
        paddingTop: 5
        // width:75,
      },
      helpIcon: {
        color: 'white',
      },
      infoContentView: {
        display:'flex',
        alignItems:'center',
        paddingLeft:10,
        position:'absolute',
        bottom:'12%',
        right:'6%',
        backgroundColor:'#000',
        borderRadius:10,
        padding:15
      },
      radioView: {marginLeft:12,marginRight:20},
      radioGroupStyle: {display:'flex',flexDirection:'column'},
      helpViewStyle: {display:'flex',alignItems:'center',marginBottom:5},
      helpTextStyle: {color: 'white',marginBottom:0,marginLeft:10},
      addViewStyle: {display:'flex',alignItems:'center',marginBottom:0},
      addTextStyle: {color: 'white',marginBottom:0,marginLeft:10},
      seeViewStyle: {display:'flex',alignItems:'center',marginBottom:0},
      seeTextStyle: {color: 'white',marginBottom:0,marginLeft:10},
      latestIconEnable: {
        color: 'black',
        fontSize: 16 ,
      },

      lastetIconDisable: {
        color: 'white',
        fontSize: 16,
      },
      latestIconEnableView: {
        backgroundColor:'#FFF',
        borderRadius:10,
        marginBottom:10,
        paddingLeft:8,
        paddingRight:8,
        paddingTop:2,
        paddingBottom:2,
        borderWidth: 2, 
        borderColor: 'gray',
        textAlign:'center',
        cursor:'pointer',
        width:75
        // marginLeft:5
        // borderStyle:'solid',
      },

      latestIconDisableView: {
        backgroundColor:'#000',
        borderRadius:10,
        marginBottom:10,
        paddingLeft:8,
        paddingRight:8,
        paddingTop:2,
        paddingBottom:2,
        borderWidth: 2,
        borderColor: 'gray',
        textAlign:'center',
        borderStyle:'solid',
        cursor:'pointer',
        width:75
        // marginLeft:5
      },
      latestEnableTextStyle: {
        color: 'black',
        fontSize: 14 ,
        marginBottom: 0
      },
      latestDisableTextStyle : {
        color: 'white',
        fontSize: 14 ,
        marginBottom: 0
      },
      iconView: {
          display:'flex'
      },
      stackContentView: {
        display:'flex',
        flexDirection:'column',
        alignItems:'center',
        maxWidth:'365px',
        paddingLeft:10,
        position:'absolute',
        top: 'calc(100% - 220px)',
        right:'20px',
        backgroundColor:'#222222',
        borderRadius:10,
        padding:10
      },

      terrainIconEnable: {
        color: 'black',
        fontSize: 16 ,
      },

      terrainIconDisable: {
        color: 'white',
        fontSize: 16 ,
      },
      terrainIconEnableView: {
        backgroundColor:'#FFF',
        borderRadius:10,
        marginBottom:10,
        paddingLeft:8,
        paddingRight:8,
        paddingTop:2,
        paddingBottom:2,
        borderWidth: 2,
        borderColor: 'gray',
        textAlign:'center',
        marginLeft:5,
        cursor:'pointer',
        width:75

      },

      terrainIconDisableView: {
        backgroundColor:'#000',
        borderRadius:10,
        marginBottom:10,
        paddingLeft:8,
        paddingRight:8,
        paddingTop:2,
        paddingBottom:2,
        borderWidth: 2,
        borderColor: 'gray',
        textAlign:'center',
        borderStyle:'solid',
        marginLeft:5,
        cursor:'pointer',
        width:75
      },
      terrainEnableTextStyle: {
        color: 'black',
        fontSize: 14 ,
        marginBottom: 0
      },
      terrainDisableTextStyle : {
        color: 'white',
        fontSize: 14 ,
        marginBottom: 0
      },


      topoIconEnable: {
        color: 'black',
        fontSize: 20 ,
      },

      topoIconDisable: {
        color: 'white',
        fontSize: 20 ,
      },
      topoIconEnableView: {
        backgroundColor:'#FFF',
        borderRadius:10,
        marginBottom:10,
        // padding: 10, 
        paddingLeft:8,
        paddingRight:8,
        paddingTop:2,
        paddingBottom:2,
        borderWidth: 2,
         borderColor: 'gray',
         textAlign:'center',
         marginLeft:5,
         cursor:'pointer',
         width:75
      },

      topoIconDisableView: {
        backgroundColor:'#000',
        borderRadius:10,
        marginBottom:10,
        paddingLeft:8,
        paddingRight:8,
        paddingTop:2,
        paddingBottom:2,
        borderWidth: 2, 
        borderColor: 'gray',
        textAlign:'center',
        borderStyle:'solid',
        marginLeft:5,
        cursor:'pointer',
        width:75
      },
      topoEnableTextStyle: {
        color: 'black',
        fontSize: 14 ,
        marginBottom: 0
      },
      topoDisableTextStyle : {
        color: 'white',
        fontSize: 14 ,
        marginBottom: 0
      },
      pickerText:{
        // display: 'flex',
        // justifyContent: 'center',
        fontSize: 15,
        fontWeight: 600,
        // marginBottom:15
        // marginTop: 10,
    },
    alignSpin:{
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      textAlign: 'center',
      width: '100%'
    }
      
}

export default {
    sider:{
        width:'100',
        // background: '#0F0D6E',
    },
    textStyle: {
        fontSize: 15,
        fontWeight: 'bold',
        textTransform:'Uppercase',
        width: 'max-content',
        display: 'flex'
    },

    iconStyle: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'left',
        alignItems: 'center'

    },
    approveguideicon:{
        color: 'green',
        fontWeight: 'bold'
    },
    approvetouricon:{
        color: 'green',
        fontWeight: 'bold'
    },
    support:{
        fontWeight: 'bold'
    },
    Contacticon:{
        color: 'black',
    },
    logouticon:{
        color: 'red',
        fontWeight: 'bold'
    },
    titleStyle: {
        color: 'white',
        // fontWeight: 'bold',
        fontSize: 30,
        textAlign: 'center',
        // padding: 10,
        display: 'flex',
        justifyContent: 'center',
        textTransform: 'uppercase',
        alignItems: 'center'

    },
    menuStyle: {
        height: '100%',
        borderRight: 0,
        width: 250,
      },
      sideMenuText: {
        fontSize: 15,
        fontWeight: 500,
      },
      sideMenuContent: {
        display: 'flex',
        justifyContent: 'flex-start',
      },
    displayName: {
        color: 'white',
        fontWeight: 'bold',
        fontSize: 15,
        // textAlign: 'center',
        textTransform: 'uppercase',
        // marginLeft: 15,  

        whiteSpace: 'nowrap', 
        width: '60px', 
        overflow: 'hidden',
        textOverflow: 'ellipsis',

    },
    onHeaderRow:{
            color: 'white',
            fontWeight: '500',
            fontSize: 15,
            textAlign: 'center',
            // padding: 10,
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection:'row',
            textTransform: 'uppercase',
			width: '100vw'
    }

}

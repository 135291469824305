import React from 'react';
import CardList from './components/CardList';

const PaymentsScreen = ({ element }) => (
        <div className="cart-totals">
          <CardList />
        </div>
);

export default PaymentsScreen;

export default {
    header:{
        display: 'flex',
        justifyContent: 'center',
        color: 'black',
        fontSize: 20,
        fontWeight: 500
    },
	subheader:{
        display: 'flex',
        justifyContent: 'center',
        color: 'black',
        fontSize: 16,
        fontWeight: 300
    },
    card:{
		position: 'absolute',
		top: 0,
        width: '95%',
		minHeight: '95vh',
		marginTop: '70px',
		overflowY: 'auto !important'
    },
    locationStyle: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    cardStyle: {
        // backgroundColor: 'black',
        // marginTop: 20
    },
    subsType: {
        color: 'black',
        display: 'flex',
        flexDirection: 'column',
        // justifyContent: 'center'
        alignItems: 'center',
        fontWeight: 500,
        // marginTop:'10%'
    },
    subsBuyType: {
        color: 'white',
        display: 'flex',
        flexDirection: 'column',
        // justifyContent: 'center'
        alignItems: 'center',
        fontWeight: 500,
        // marginTop:'10%'
    },
    subsTypeMon: {
        color: 'white',
        display: 'flex',
        flexDirection: 'column',
        // justifyContent: 'center'
        alignItems: 'center',
        justifyContent: 'center'
    },
    subsAction: {
        marginTop: '50px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        fontWeight: 'bolder',
        color: '#333333',
        textDecoration:'underLine',
        // marginBottom:'20px'
    },
    buttonStyle: {
        display: 'flex',
        justifyContent: 'center',
        // flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: '#999999',
        width:'150px'
        // marginLeft: 55
    },
    buttonStyleMon: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#999999',
        // marginLeft: 55
    },
    confirmCancel: {  
        display: 'flex',
        justifyContent: 'center'
   },
   radioStyle: {
       height: 4
   },
   subsTitle: {
       display: 'flex',
       justifyContent: 'center',
	   marginTop: '50px'
   },
   subsFree: {
    marginTop: 50,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    fontWeight: 'bolder',
    color: '#333333',
    textDecoration:'underLine',
    paddingBottom: 10
    // marginBottom:'20px'
},
pdiv: {
    marginTop: 0,
    marginBottom: '.3em'
}
}
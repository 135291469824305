import React, { Component } from "react";
import Switch from "react-switch";
import moment from 'moment';
import MapGL, { Marker, Popup, NavigationControl, FullscreenControl, GeolocateControl, Source, Layer } from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import Geocoder from 'react-map-gl-geocoder';
import 'react-map-gl-geocoder/dist/mapbox-gl-geocoder.css';
import PlacesAutocomplete from 'react-places-autocomplete';
import Slider from 'react-slick';
import { geocodeByAddress, geocodeByPlaceId, getLatLng } from 'react-places-autocomplete';
import Autocomplete from 'react-google-autocomplete';
import { dataLayer, colorLayer, initialLayer, topoShadeLayer } from '../styles/map-style';
// import {colorLayer} from '../styles/map-initialStyle';
import { fromJS } from 'immutable';
import { json as requestJson } from 'd3-request';
import { Alert, Button, Card, Input, Modal, AutoComplete, Icon, message, Radio, Checkbox, Spin } from 'antd';
import { connect } from 'react-redux'
import { getLocation } from '../../actions/locationAction';
import CityPin from '../controls/city-pin';
import CityInfo from '../controls/city-info';
import styles from './UsersHome.Style';
import layersWhite from '../../themes/assets/images/layersWhite.png';
import layersBlack from '../../themes/assets/images/layersBlack.png';
import infoWhite from '../../themes/assets/images/infoWhite.png';
import infoBlack from '../../themes/assets/images/infoBlack.png';
import angleWhite from '../../themes/assets/images/angle_thick.png';
import angleBlack from '../../themes/assets/images/angleBlack.png';
import cameraWhite from '../../themes/assets/images/ar-camera1.png';
import cameraBlack from '../../themes/assets/images/ar-camera.png';
import topoWhite from '../../themes/assets/images/topoWhite.png';
import topoBlack from '../../themes/assets/images/topoBlack.png';
import snowWhite from '../../themes/assets/images/snow(1).png';
import snowBlack from '../../themes/assets/images/snowblack.png';
import satelliteBlack from '../../themes/assets/images/satelliteBlack.png';
import sunnyday from '../../themes/assets/images/sunnyday.png';
import Dot from '../../themes/assets/images/dot.png';
import * as userApi from "../../api/users";
import { userFields } from "../../api/collectionName";
import * as userFeedback from "../../api/feedback";
import { AuthService } from "../auth/auth.service";
import { collectionConfig } from "../../api/collectionName";
import { async } from "q";
import { CoverageMap } from "istanbul-lib-coverage";
import { getLatestImageryData, getTopoLayersData, getForecastLayersData, tileLayerStyle } from '../../services/snowIntelAPIs';

const { TextArea } = Input;

const TOKEN =
  "pk.eyJ1IjoicGF2aXRocmEtbSIsImEiOiJjazA5ZTljdDEwN3dyM2JwbGh5OXY1N2xoIn0.opM-LgYh959FB_84PdGb7w"; // Set your mapbox token here

let tempTimeout;

let currentDate = moment(new Date()).format("MMMM Do YYYY, h:mm:ss a");

const settings = {
  // dots: true,
  // infinite: true,
  // speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1
};

const dateArray = [
  {
    date: '12/31/19',
    text: 'Latest Satellite imagery'
  },
  {
    date: '1/12/20',
    text: '2nd Latest Satellite imagery'
  },
  {
    date: '2/05/20',
    text: '3rd Latest Satellite imagery'
  },
  {
    date: '8/08/20',
    text: '4th Latest Satellite imagery'
  },
];

const snowDepth = {
  Legend_Colors: [
    "#FFFFFF",
    "#EBEBFF",
    "#D7D7FF",
    "#C3C3FF",
    "#AFAFFF",
    "#9B9BFF",
    "#8888FF",
    "#7474FF",
    "#6060FF",
    "#4C4CFF",
    "#3838FF",
    "#2424FF",
    "#1010FF",
    "#0002FC",
    "#0016E8",
    "#002AD4",
    "#003EC0",
    "#0052AC",
    "#006698",
    "#007985",
    "#008D71",
    "#00A15D",
    "#00B549",
    "#00C935",
    "#00DD21",
    "#00F00E",
    "#05FF00",
    "#19FF00",
    "#2DFF00",
    "#41FF00",
    "#55FF00",
    "#68FF00",
    "#7CFF00",
    "#90FF00",
    "#A4FF00",
    "#B8FF00",
    "#CCFF00",
    "#DFFF00",
    "#F3FF00",
    "#FFFB00",
    "#FFF400",
    "#FFEE00",
    "#FFE600",
    "#FFDF00",
    "#FFD800",
    "#FFD200",
    "#FFCA00",
    "#FFC300",
    "#FFBD00",
    "#FFB500",
    "#FFAE00",
    "#FFA800",
    "#FF9D00",
    "#FF9000",
    "#FF8300",
    "#FF7700",
    "#FF6A00",
    "#FF5D00",
    "#FF5000",
    "#FF4300",
    "#FF3600",
    "#FF2A00",
    "#FF1D00",
    "#FF1000",
    "#FF0300",
    "#FA0205",
    "#F4060C",
    "#EE0A14",
    "#E80D1B",
    "#E21123",
    "#DB152A",
    "#D51932",
    "#CF1C39",
    "#C92041",
    "#C32448",
    "#BD2850"
  ],
  Legend_Values: [
    "0", "2", "4", "6", "8", "10"
  ]
}

let legendCount = 0

class UsersHomeScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // mapStyle: defaultMapStyle,
      data: null,
      mapView: null,
      mapViewStyle: null,
      viewport: {
        width: "fir-content",
        height: 700,
        latitude: 39.4817,
        longitude: -106.0384,
        zoom: 8,
      },
      city: {
        width: "fir-content",
        height: 668,
        latitude: "",
        longitude: "",
        zoom: 8,
      },
      popupInfo: null,
      checked: false,
      jsonResponse: null,
      features: null,
      visible: true,
      coverageArea: false,
      submitButton: false,
      visibleSubmit: false,
      visibleFeedback: false,
      mapClick: false,
      targetName: null,
      locationName: null,
      locationDesc: null,
      cityLat: null,
      cityLong: null,
      confirmSubmit: false,
      coverageFeedback: null,
      type: "initial",
      warnCoverage: false,
      warnModal: true,
      feedback: true,
      currentUser: null,
      locations: null,
      currentUserUid: null,
      address: "",
      visibleModal: false,
      enableInfo: false,
      enableStack: true,
      enableLatestIcon: false,
      enableTerrainIcon: false,
      enableTopoIcon: true,
      enableForecastIcon: false,
      enableSlopeIcon: false,
      slopeAngleCheck: false,
      pitNameCheck: true,
      latestImageryData: [],
      forecastLayerData: null,
      selectedForecastLayer: "Today_Snow",
      radioValue: 1,
      latestLayer: "sat1", //"mapbox://styles/fishish1/ck2wtp3fb02q41co04i76sc1h",
      initialMap: "mapbox://styles/fishish1/ck2wtp3fb02q41co04i76sc1h",
      expiryDate: null,
      paymentStatus: null,
      lastLogin: null,
      paymentSubs: false,
      subsVisible: true,
      currentUserInfo: '',
      timeTaken: ''
    };
    this.handleChange = this.handleChange.bind(this);
  }

  mapRef = React.createRef();

  componentDidMount() {
    this.setState(
      {
        currentUserUid: localStorage.getItem("currentUserUid"),
      },
      () => {
        this.props.getLocation(this.state.currentUserUid);
      }
    );

    if (this.state.enableTopoIcon === true) {
      this.changeSlopeLayer()
    }

    this.jsonFunction();
    let viewPortData = localStorage.getItem('viewport');
      if(viewPortData && viewPortData !== null) {
        let viewPortValue = JSON.parse(viewPortData)
        this.setState({
          viewport: {
            height: viewPortValue.height,
            latitude: viewPortValue.latitude,
            longitude: viewPortValue.longitude,
            width: viewPortValue.width,
            zoom: viewPortValue.zoom,
          }
        })
      }
    if (
      this.props &&
      this.props.history.location &&
      this.props.history.location.state
    ) {
      this.setState({
        viewport: {
          height: 668,
          latitude: this.props.history.location.state.lat,
          longitude: this.props.history.location.state.long,
          width: "auto !important",
          zoom: 8,
        },
        coverageFeedback: this.props.history.location.state.feedbackTitle,
      });
    }
  }

  componentWillReceiveProps(nxtprops) {
    const {lastLogin,expiryDate, paymentStatus, currentUserInfo, timeTaken} = this.state
    this.setState({
      currentUserUid: JSON.parse(localStorage.getItem("currentUserAuth")),
      currentUser:JSON.parse(localStorage.getItem('currentUserAuth')),
      currentUserInfo : JSON.parse(localStorage.getItem('currentUserInfo'))
    });
    console.log(currentUserInfo, "currentUserInfo")
    if(currentUserInfo && currentUserInfo.lastlogin){
      console.log(currentUserInfo.lastlogin,"lastLoginAt")
      this.setState({
        lastLogin: moment(currentUserInfo.lastlogin).format('MM/DD/YYYY')
      })
    }
    if(nxtprops && nxtprops.location && nxtprops.location.paymentInfo){
      this.setState({
        expiryDate: nxtprops.location.paymentInfo.subscriptionEndDate,
        paymentStatus: nxtprops.location.paymentInfo.status,
        timeTaken: moment.unix(expiryDate).format("MM/DD/YYYY")
      })
    }
    if (nxtprops.location && nxtprops.location.location) {
      this.setState(
        {
          locations: nxtprops.location.location,
        },
        () => { }
      );
    }
		console.log("exp:", expiryDate)
	console.log("ll:", parseInt((new Date(lastLogin).getTime() / 1000).toFixed(0)))
    // console.log(lastLogin, "lastLogin", expiryDate, "expiryDate", paymentStatus, "paymentStatus", lastLogin <= expiryDate)
			console.log("paymnt:", paymentStatus)
/*
    if((paymentStatus === 'Free' || paymentStatus === 'Cancelled') && (parseInt((new Date(lastLogin).getTime() / 1000).toFixed(0)) >= expiryDate)){
      this.setState({
       // visible : true,
       paymentSubs: true

      })
   }

   if(paymentStatus === 'Cancelled'){
    this.setState({
      // visible : true,
      paymentSubs: true

     })
   }*/
  }



  componentWillUnmount() {
    legendCount = 0
  }

  subsOk = () => {
    this.setState({
      subsVisible: false
    },()=>{
      this.props.history.push('./subscription')
    })
  }

  jsonFunction = (value) => {
    requestJson("sample-layer.geojson", (error, response) => {
      this.setState({ jsonResponse: response });
      if (!error && value !== undefined) {
        if (value === "empty") this._loadData(response, "normal");
        else this._loadData(response, "initial");
      }
      if (!error && value === undefined) {
        this._loadData(response, "initial");
      }
    });
  };

  handleChange(checked) {
    const { mapStyle, mapView } = this.state;
    if (checked === true) {
      this.setState(
        {
          checked,
          data: mapView,
          // mapViewStyle: mapStyle
        },
        () => { }
      );
      this.jsonFunction("empty");
    } else {
      this.setState({
        checked,
        data: null,
        // mapViewStyle: {},
      });
      this.jsonFunction("null");
    }
  }
  handleCancelModal = () => {
    // console.log('Clicked cancel button');
    this.setState({
      visibleModal: false,
    });
  }

  _loadData = (data, type) => {
    if (type !== null && type !== undefined) {
      this.setState({
        type: type,
      });
    }
    this.setState({ data });
  };

  updateViewport = (viewport) => {
    this.setState({ viewport });
  };

  handleViewportChange = (viewport) => {
    this.setState({
      viewport: { ...this.state.viewport, ...viewport },
    });
  };

  handleGeocoderViewportChange = (viewport) => {
    const geocoderDefaultOverrides = { transitionDuration: 1000 };

    return this.handleViewportChange({
      ...viewport,
      ...geocoderDefaultOverrides,
    });
  };

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = async (city) => {
    const { cityLat, cityLong, visible, visibleFeedback } = this.state;
    this.setState({
      cityLat: city.latitude,
      cityLong: city.longitude,
      visible: false,
      visibleFeedback: true,
    });
  };

  handleWarning = (city) => {
    const { cityLat, cityLong, visible, visibleFeedback } = this.state;
    this.setState(
      {
        cityLat: city.latitude,
        cityLong: city.longitude,
        visible: false,
        warnModal: true,
        warnCoverage: true,
        coverageArea: true,
        feedback: false,
      },
      () => { }
    );
  };

  handleSubmit = async (popupInfo) => {
    const { locationName, locationDesc, locations } = this.state;
    this.renderCityMarker(this.state.city)
    let tempArr = [];
    if (locationName !== null) {
      this.setState({
        visibleSubmit: false,
        visible: false,
        warnModal: false,
        newLat: this.state.city.latitude,
        newLong: this.state.city.longitude,
      });
      let latObj = {
        lat: this.state.city.latitude,
      };
      let lanObj = {
        lan: this.state.city.longitude,
      };

      let locationObject = {};
      locationObject = {
        name: locationName,
        desc: locationDesc,
        coords: [latObj, lanObj],
        date: currentDate,
        clicked: false,
      };
      if (
        locations !== null &&
        locations !== undefined &&
        locations.length > 0
      ) {
        tempArr = locations;
        tempArr.push(locationObject);
        this.setState({
          locations: tempArr,
          visible: false,
          warnModal: false,
        });
      } else {
        locationObject = {
          name: locationName,
          desc: locationDesc,
          coords: [latObj, lanObj],
          date: currentDate,
          clicked: false,
        };
        tempArr.push(locationObject);
        this.setState({
          locations: tempArr,
          visible: false,
          warnModal: false,
        });
      }
      await userApi.updateUserLocation(
        this.state.currentUserUid.uid,
        tempArr,
        userFields.LOCATION
      );
	  this.onLocationSelect({
            longitude: lanObj,
            latitude: latObj,
            name: locationName
          })
      // this.props.history.push({
      //   pathname: "./users",
      //   state: {
      //     newPlace: locationName,
      //     clicked: false,
      //     newLatitude: this.state.city.latitude,
      //     newLongitude: this.state.city.longitude,
      //   },
      // });
    } else {
      message.warning("Location name field cannot be empty");
    }
  };

  handleCancelSubmit = () => {
    this.setState({
      visibleSubmit: false,
      visible: false,
    });
  };

  handleCancel = (e) => {
    this.setState({
      visible: false,
      warnModal: false,
    });
  };

  handleAdd = async () => {
    const {
      currentUserUid,
      coverageFeedback,
      locationDesc,
      cityLat,
      cityLong,
    } = this.state;
    if (locationDesc !== null && locationDesc !== undefined) {
      this.setState({ confirmSubmit: true, visibleFeedback: false }, () => {
        tempTimeout = setTimeout(() => {
          this.setState({ confirmSubmit: false });
        }, 5000);
      });
    } else {
      message.warning("Description field cannot be empty");
    }

    let latObj = {
      lat: this.state.city.latitude,
    };
    let lanObj = {
      lan: this.state.city.longitude,
    };
    let prefObject = {
      type: "Coverage Area",
      location: null,
      coords: [latObj, lanObj],
      desc: locationDesc,
      dateOnFeedback: currentDate,
      date: currentDate,
      email: currentUserUid.email,
      uid: currentUserUid.uid,
    };
    await userFeedback.updateUserFeedback(
      this.state.currentUserUid.uid,
      prefObject,
      collectionConfig.FEEDBACK
    );
  };

  handle = async () => {
    const { locationDesc, currentUserUid } = this.state;
    if (locationDesc !== null && locationDesc !== undefined) {
      this.setState({ confirmSubmit: true, feedback: false }, () => {
        tempTimeout = setTimeout(() => {
          this.setState({ confirmSubmit: false, coverageFeedback: null });
        }, 5000);
      });
    } else {
      message.warning("Description field cannot be empty");
    }

    let latObj = {
      lat: this.state.city.latitude,
    };
    let lanObj = {
      lan: this.state.city.longitude,
    };
    let prefObject = {
      type: "Coverage Area",
      location: null,
      coords: [latObj, lanObj],
      desc: locationDesc,
      dateOnFeedback: currentDate,
      date: currentDate,
      email: currentUserUid.email,
      uid: currentUserUid.uid,
    };
    await userFeedback.updateUserFeedback(
      this.state.currentUserUid.uid,
      prefObject,
      collectionConfig.FEEDBACK
    );
  };

  warnCancel = () => {
    this.setState({
      visible: false,
    });
  };

  feedbackCancel = () => {
    this.setState({
      visible: false,
      warnModal: false,
    });
  };

  submitConfirm = () => {
    this.setState({
      confirmSubmit: false,
      coverageFeedback: null
    });
  };

  handleDiscard = () => {
    this.setState({
      visibleFeedback: false,
    });
  };

  handleFeedback = () => {
    this.setState({
      feedback: false,
    });
  };

  _onClick = (event) => {
    const { checked, coverageArea, coverageFeedback, type } = this.state;
    const target = event.target.className;
    const { features } = event;
    // const hoveredFeature = features && features.find(f => f.source === 'jsx-source-0');
    const hoveredFeature =
      features && features.find((f) => f.layer.id === "data");
    if (
      event !== null &&
      event.lngLat !== null &&
      target === "overlays" &&
      coverageFeedback === null
    ) {
      this.setState(
        {
          coverageArea: false,
          visible: true,
          isgeocoding: false,
          city: {
            height: 668,
            latitude: event.lngLat[1],
            longitude: event.lngLat[0],
            width: "auto !important",
            zoom: 16,
          },
        },
        () => {
          this.renderClickMarker(this.state.city);
        }
      );
    }
    if (coverageFeedback === "Coverage Area") {
      if (
        event !== null &&
        event.lngLat !== null &&
        target === "overlays" &&
        coverageFeedback === "Coverage Area" &&
        hoveredFeature === undefined
      ) {
        this.setState(
          {
            coverageArea: true,
            visibleSubmit: true,
            feedback: true,
            isgeocoding: false,
            city: {
              height: 668,
              latitude: event.lngLat[1],
              longitude: event.lngLat[0],
              width: "auto !important",

              zoom: 16,
            },
          },
          () => {
            this.renderClickMarker(this.state.city);
          }
        );
      } else if (
        hoveredFeature &&
        coverageFeedback === "Coverage Area" &&
        target === "overlays"
      ) {
        this.setState(
          {
            coverageArea: false,
            visible: true,
            isgeocoding: false,
            city: {
              height: 668,
              latitude: event.lngLat[1],
              longitude: event.lngLat[0],
              width: "auto !important",

              zoom: 16,
            },
            // visibleSubmit : false,
          },
          () => {
            this.renderClickMarker(this.state.city);
          }
        );
      }
    }

    if (target !== null) {
      this.setState({
        targetName: target,
        isgeocoding: false,
        city: {
          height: 668,
          latitude: event.lngLat[1],
          longitude: event.lngLat[0],
          width: "auto !important",

          zoom: 16,
        },
      });
      this.renderClickMarker(this.state.city);
    }
    if (hoveredFeature && target === "overlays" && coverageFeedback === null) {
      this.setState(
        {
          coverageArea: true,
          visibleSubmit: true,
          warnModal: true,
          warnCoverage: true,
          isgeocoding: false,
          city: {
            height: 668,
            latitude: event.lngLat[1],
            longitude: event.lngLat[0],
            width: "auto !important",

            zoom: 16,
          },
        },
        () => {
          this.renderClickMarker(this.state.city);
        }
      );
    }
    if (
      type === "initial" &&
      hoveredFeature &&
      target === "overlays" &&
      coverageFeedback === null
    ) {
      this.setState(
        {
          warnCoverage: true,
          coverageArea: true,
          warnModal: true,
          visibleSubmit: false,
          isgeocoding: false,
          city: {
            height: 668,
            latitude: event.lngLat[1],
            longitude: event.lngLat[0],
            width: "auto !important",

            zoom: 16,
          },
          // visible: false
        },
        () => {
          this.renderClickMarker(this.state.city);
        }
      );
    }
  };

  renderClickMarker = (viewport) => {
    return (
      <Marker longitude={viewport.longitude} latitude={viewport.longitude} offsetLeft={-20/2} offsetTop={0}>
        <CityPin
          size={20}
          onClick={() => this.setState({ popupInfo: viewport })}
        />
      </Marker>
    );
  };

  renderCityMarker = (city) => {
    return (
      <Marker longitude={city.longitude} latitude={city.latitude} offsetLeft={-20/2} offsetTop={0}>
        <CityPin size={20} onClick={() => this.setState({ popupInfo: city })} />
      </Marker>
    );
  };

  onLocationSelect = (data) => {
    const { locations } = this.state

    let locationName = data.name

    if (locations !== null) {

      locations !== null && locations.map((value, index) => {
        if (value.name === locationName) {
          this.setState({
            popupInfo: data
          }, () => {
            this.props.history.push({
              pathname: '/location',
              state: { detail: value.name, lat: value.coords[0].lat, long: value.coords[0].lan, preference: null, index: index }
            })
          })
        }
      })
    }
  }


  renderMarker = (locations) => {
    const { pitNameCheck, enableTerrainIcon, viewport } = this.state
    if (locations !== null && locations.length > 0) {
      return locations && locations.map((location, key) => {
        if (location.coords.length > 0) {
          let temObj = {
            longitude: location.coords[1].lan,
            latitude: location.coords[0].lat,
            name: location.name
          }
          return (
            <Marker longitude={location.coords[1].lan} latitude={location.coords[0].lat} key={key} offsetLeft={-20/2} offsetTop={0}>
              <CityPin size={20} onClick={() => this.onLocationSelect(temObj)} />
              {(pitNameCheck === true && viewport.zoom > 10) ?
                <p class={"color: #fff"}>{temObj.name}</p>
                : null
              }
            </Marker>
          );
        } else {
          return null;
        }
      })
    } else {
      return null;
    }

  }

  renderPopup() {
    const { popupInfo } = this.state;

    return (
      popupInfo && (
        <Popup
          tipSize={5}
          anchor="top"
          longitude={popupInfo.longitude}
          latitude={popupInfo.latitude}
          closeOnClick={false}
          onClose={() => this.setState({ popupInfo: null })}
        >
          <CityInfo info={popupInfo} />
        </Popup>
      )
    );
  }

  addLocation = (value, coverage) => {
    const { features } = this.state;
    this.setState({
      features: value,
      // visible: false
    });
  };

  confirmAdd = (addLocationConfirm) => {
    this.setState({
      confirmLocation: addLocationConfirm,
      visible: false,
    });
  };

  renderSubmit = () => {
    return (
      <div>
        <Button
          id="common-button-view"
          style={styles.placeholderStyle}
          size={"large"}
          onClick={() => this.submit("submit")}
        >
          Submit
        </Button>
      </div>
    );
  };

  submit = (value) => {
    if (value === "submit") {
      this.setState({
        visibleSubmit: true,
      });
    }
    return (
      <Modal
        title="Please describe coverage to add"
        visible={this.state.visibleSubmit}
        onOk={this.handleSubmit}
        onCancel={this.handleCancelSubmit}
      >
        <TextArea
          rows={4}
          placeholder="Description"
          style={{ height: "auto" }}
          onChange={(event) => this.handleUserDesc(event)}
        />
      </Modal>
    );
  };

  handleUserName = (e) => {
    this.setState({
      locationName: e.target.value,
    });
  };

  handleUserDesc = (e) => {
    this.setState({
      locationDesc: e.target.value,
    });
  };

  handleaddChange = (address) => {
    // console.log("handleaddChange called",address)
    this.setState({
      address,
      latitude: null,
      longitude: null,
    });
  };

  selectValue = (address) => {
    // console.log('address', address)
    this.setState({
      address,
    }, () => {
    });
  }

  handleSelect = (address) => {
    // console.log("handleSelect called", address)
    document.activeElement.blur();
    geocodeByAddress(address)
      .then((results) => getLatLng(results[0]))
      .then(({ lat, lng }) => {
        let viewPortData = {
          height: 668,
          latitude: lat,
          longitude: lng,
          width: "auto !important",
          zoom: 12
        }
        this.setState(
          {
            isgeocoding: true,
            viewport: viewPortData,
          },
          () => { 
            localStorage.setItem('viewport', JSON.stringify(viewPortData));
          });
      })
      .catch((error) => console.error("Error", error));
  };

  changeInfoStatus = (value) => {
    this.setState({
      enableInfo: !value,
      enableStack: false,
    })
  }

  changeStackStatus = (value) => {
    this.setState({
      enableStack: !value,
      enableInfo: false,
      enableLatestIcon: false,
      enableTopoIcon: false,
      enableTerrainIcon: false,
      enableForecastIcon: false,
      enableSlopeIcon: false,
      slopeAngleCheck: false,
    }, () => {
      // if(this.state.enableStack === true){
      //   this.getLatestLayer()
      // }else {
      //   this.setState({
      //     latestLayer: null
      //   })
      // }
    })
  }

  getLatestLayer = () => {
    getLatestImageryData().then(response => {
      if (response && response.data && response.data.Layers.length > 0) {
        let lastLayerArray = this.sortLatestLayerByCurrentDate(this.sortLatestLayer(response.data.Layers))
        let layerValue;
        if (localStorage.getItem('slopeLayer') && localStorage.getItem('slopeLayer') !== null) {
          layerValue = lastLayerArray.findIndex(layer => layer.Layer_Date === localStorage.getItem('slopeLayer'));
          layerValue = layerValue > 0 ? layerValue : 0
        } else {
          layerValue = lastLayerArray.length - 1
        }
        this.setState({
          latestImageryData: lastLayerArray,
          latestLayer: lastLayerArray[layerValue].Layer_Name,
        }, () => {
          // console.log("getLatestImageryData called", lastLayerArray[layerValue].Layer_Name, lastLayerArray[layerValue].Layer_Date)
        })
      }
    })
  }
  //To sort the latestImageryData
  sortLatestLayer(latestImageryData) {
    latestImageryData && latestImageryData.length && latestImageryData.sort(function (a, b) {
      return new Date(a.Layer_Date) - new Date(b.Layer_Date);
    });
    return latestImageryData
  }
  //To sort the latestImageryData by current date
  sortLatestLayerByCurrentDate(latestImageryData) {
    let latestLayer = [];
    let oldLayer = []
    latestImageryData && latestImageryData.length && latestImageryData.map((layer, key) => {
      if (new Date() <= new Date(layer.Layer_Date)) {
        latestLayer.push(layer)
      } else {
        oldLayer.push(layer)
      }
      return layer;
    });
    return [...latestLayer, ...oldLayer]
  }

  changeLatestIconStatus = (value) => {
    // console.log("changeLatestIconStatus called", value)
    this.setState({
      enableLatestIcon: !value,
      enableForecastIcon: false,
      enableSlopeIcon: false,
      slopeAngleCheck: false,
      initialMap: this.state.initialMap,
    }, () => {
      this.getLatestLayer()
    })
  }

  changeSlopeIconStatus = (value) => {
    this.setState({
      enableSlopeIcon: !value,
      slopeAngleCheck: true,
      enableLatestIcon: false,
      enableForecastIcon: false,
      initialMap: this.state.initialMap,
    }, () => {
      //if (value === false) {
        this.changeSlopeLayer()
     // } else {
        /*this.setState({
          selectedForecastLayer: null
        })*/
     // }
    })
  }

  changeTopo = (value) => {
    this.setState({
      enableTopoIcon: !value
    }, () => {
      if (!value === true) {
        this.setState({
          initialMap: "mapbox://styles/fishish1/ck2wtp3fb02q41co04i76sc1h"
        })
      } else {
        this.setState({
          initialMap: "mapbox://styles/mapbox/satellite-v9"
        })
      }
    })
  }

  changeTopoIconStatus = (value) => {
    this.setState({
      enableTopoIcon: !value
    }, () => {
      
    })
  }

  changeForecastIconStatus = (value) => {
    if(value === false){
      this.setState({
       // enableTopoIcon: false,
      })
    }else{
      this.setState({
       // enableTopoIcon : true,
        initialMap: this.state.initialMap
      })
    }
    this.setState({
      enableForecastIcon: !value,
      enableLatestIcon: false,
      // enableTopoIcon: false,
      enableSlopeIcon: false,
      slopeAngleCheck: false,
    }, () => {
      this.changeForecastLayer()
    })
  }

  changeForecastLayer = () => {
    legendCount = 0
    getForecastLayersData().then(response => {
      // console.log(response,"forecast")
      if (response && response.data && response.data.Layers.length > 0) {
        let layerValue;
        if (localStorage.getItem('forecastLayer') && localStorage.getItem('forecastLayer') !== null) {
          layerValue = response.data.Layers.findIndex(layer => layer.Layer_Name === localStorage.getItem('forecastLayer'));
          layerValue = layerValue > 0 ? layerValue : 0
        } else {
          layerValue = 0
        }
        this.setState({
          forecastLayerData: response.data,
          selectedForecastLayer: response.data.Layers[layerValue],
          latestLayer: response.data.Layers[layerValue].Layer_Name
        }, () => {
        })
      }
    })
  }

  selectForecastLayer = (value) => {
    this.setState({
      selectedForecastLayer: value,
      latestLayer: value.Layer_Name
    }, () => {
      localStorage.setItem('forecastLayer', value.Layer_Name);
      this.handleCancelModal();
    });
  }

  changeSlopeLayer = () => {
    const { slopeAngleCheck } = this.state
    getTopoLayersData().then(response => {
      if (response && response.data && response.data.Layers.length > 0) {
        this.setState({
          topoLayerData: response.data.Layers,
          selectedForecastLayer: response.data.Layers[0],
          latestLayer: slopeAngleCheck ? response.data.Layers[0].Layer_Name : "SAS"
        }, () => { })
      }
    })
  }

  onChange = (e) => {
    this.setState({
      radioValue: e.target.value,
    });
  }

  onChangeSlopeCheck = (e) => {
    this.setState({
      slopeAngleCheck: e.target.checked,
    }, () => {
      this.changeSlopeLayer()
    });
  }

  onChangePitCheck = (e) => {
    this.setState({
      pitNameCheck: e.target.checked,
    }, () => {
      this.renderMarker(this.state.locations)
    });
  }

  onChangeSlider = (current, next) => {
    const { latestImageryData } = this.state
    let filterData = latestImageryData.filter((value, key) => {
      if (key === next) {
        return value;
      }
    });
    if (filterData && filterData !== null && filterData !== undefined && filterData.length > 0) {
      this.setState({
        latestLayer: filterData[0].Layer_Name
      }, () => { })
      localStorage.setItem('slopeLayer', filterData[0].Layer_Date);
    }
  }

  showLegend = (legendValues, legendColors, index) => {
    let tempCount = legendCount
    let count = legendColors / legendValues.length;
    console.log(' showLegend count legendColors', legendColors)
    console.log(' showLegend count legendValues', legendValues.length)
    console.log(' showLegend count', index, parseInt(count))
    console.log(' showLegend count', ((index * parseInt(legendValues.length)) / parseInt(count)))
    console.log(' showLegend count', ((index * parseInt(legendValues.length)) % parseInt(count)))
    console.log("index % Legend_Values.length ", tempCount, legendColors, legendValues.length, index, count, (index * legendValues.length) % count)
    console.log("parseInt(index * legendValues.length) % parseInt(count)", parseInt(index * legendValues.length) % parseInt(count))
    if (((index * parseInt(legendValues.length)) % parseInt(count)) === 0 && index !== 0) {
      legendCount++;
      console.log("index % Legend_Values.length legendValues[tempCount]", legendValues[tempCount])
      return <p style={{ color: '#fff', marginBottom: 0 }}>1</p>
    } else if (index === 0) {
      return <p style={{ color: '#fff', marginBottom: 0 }}>{legendValues[0]}</p>
    } else {
      return null
    }
  }

   addViewPortValue(value) {
     const { viewport } = this.state;
    let viewPortData = localStorage.getItem('viewport');
    if (viewPortData === null || (value.height !== 0 && value.weight !== 0 && viewport.width !== "fir-content")) {
    this.setState({ viewport: value },() => {
      localStorage.setItem('viewport', JSON.stringify(value));
    });
  }
  }

  render() {

    const {
      viewport,lastLogin,timeTaken,
      isgeocoding,
      address, coverageFeedback, locationName,
      visibleSubmit, locationDesc, checked, enableLatestIcon, enableTerrainIcon,
      enableTopoIcon, enableStack, targetName, coverageArea, popupInfo, confirmSubmit,
      type, data, enableInfo, visibleModal, slopeAngleCheck, pitNameCheck, locations, latestImageryData, latestLayer,
      enableForecastIcon, enableSlopeIcon, forecastLayerData, selectedForecastLayer, initialMap, expiryDate, paymentStatus, paymentSubs
    } = this.state;

    //To find the latest layer index
    let latestLayerIndex;
    if (localStorage.getItem('slopeLayer') && localStorage.getItem('slopeLayer') !== null) {
      latestLayerIndex = latestImageryData.findIndex(layer => layer.Layer_Date === localStorage.getItem('slopeLayer'));
      latestLayerIndex = latestLayerIndex > 0 ? latestLayerIndex : 0
    } else {
      latestLayerIndex = latestImageryData.length - 1
    }

    return (
      <Card className="card-view">
        <div className="mapView">
          <MapGL
            {...viewport}
            ref={this.mapRef}
            // mapStyle={"mapbox://styles/fishish1/ck2wtp3fb02q41co04i76sc1h"}
            mapStyle={enableSlopeIcon === true || enableLatestIcon === true || enableForecastIcon === true ? tileLayerStyle(latestLayer, initialMap) : initialMap}
            onViewportChange={(v) => {
                this.addViewPortValue(v);
            }}
            mapboxApiAccessToken={TOKEN}
            scrollZoom={true}
            onClick={this._onClick}
          >
            {this.renderMarker(locations)}
            {initialMap === "mapbox://styles/fishish1/ck2wtp3fb02q41co04i76sc1h" && enableForecastIcon === false && enableSlopeIcon === false && enableLatestIcon === false &&
              <Source type="geojson" data={data}>
                <Layer {...initialLayer} />
              </Source>
            }
            {(enableForecastIcon === true || enableSlopeIcon === true || enableLatestIcon === true || initialMap === "mapbox://styles/mapbox/satellite-v9") &&
              <Source type="geojson" data={data}>
                <Layer {...dataLayer} />
              </Source>
            }

          {(expiryDate !== null && parseInt((new Date(lastLogin).getTime() / 1000).toFixed(0)) >= expiryDate) &&
          
          <Modal
              title="Subscription Required!"
              visible={this.state.subsVisible}
              footer={null}
              // onOk={this.subsOk}
              onCancel={this.subsOk}
              // cancelText='Subscribe Now'
            >
              <div>
                Your account does not have a current subscription or active free trial.
              </div>
              <div style={{ display: 'flex', justifyContent: 'center', marginTop: 20}}>
                <Button id="common-button-view" onClick={()=> this.subsOk()}>
                  Subscribe Now
                </Button>
              </div>
            </Modal>
      
        }
            {coverageArea === false &&
              targetName === "overlays" &&
              type !== "initial" && (
                <div>
                  {this.renderCityMarker(this.state.city)}

                  <div style={styles.alertStyle}>
                    <Modal
                      title="No Coverage Here!! If we should have coverage here, please select 'Give Feedback' and tell us!"
                      visible={this.state.visible}
                      onOk={() => this.handleOk(this.state.city)}
                      onCancel={this.handleCancel}
                      okText="Give Feedback"
                      cancelText="Dismiss"
                    ></Modal>
                  </div>
                </div>
              )}
            {coverageArea === false &&
              targetName === "overlays" &&
              coverageFeedback === "Coverage Area" && (
                <div>
                  {this.renderCityMarker(this.state.city)}

                  <div style={styles.alertStyle}>
                    <Modal
                      title="Warning: Coverage exists"
                      visible={this.state.visible}
                      onOk={() => this.handleWarning(this.state.viewport)}
                      onCancel={this.handleCancel}
                      okText="Add Location"
                      cancelText="Dismiss"
                    >
                      <div>
                        Snow intel already provides forecasts for this area.
                        Please tap 'Add Location' to get forecasts for this
                        location by adding it to your account.
                      </div>
                      {/* <TextArea rows={4} /> */}
                    </Modal>
                  </div>
                </div>
              )}
            {this.state.coverageArea === true &&
              targetName === "overlays" &&
              type !== "initial" &&
              coverageFeedback === "Coverage Area" && (
                <div>
                  {this.renderCityMarker(this.state.city)}
                  <div style={styles.alertStyle}>
                    <Modal
                      title="Please describe coverage to add"
                      visible={this.state.feedback}
                      onOk={this.handle}
                      onCancel={this.handleFeedback}
                      okText="Send Feedback"
                      cancelText="Discard"
                    >
                      <TextArea
                        rows={4}
                        placeholder="Description"
                        style={{ height: "auto" }}
                        onChange={(event) => this.handleUserDesc(event)}
                      />
                    </Modal>
                  </div>
                </div>
              )}
            {this.state.coverageArea === false &&
              targetName === "overlays" &&
              type === "initial" &&
              coverageFeedback === null &&
              // enableTerrainIcon === false && // terrainIcon false for not show coverage area alert when terrain mode
              (
                <div>
                  {this.renderCityMarker(this.state.city)}
                  <div style={styles.alertStyle}>
                    <Modal
                      title="Warning: Outside Coverage Area"
                      visible={this.state.visible}
                      onOk={() => this.handleOk(this.state.city)}
                      onCancel={this.warnCancel}
                      okText="Give Feedback"
                      cancelText="Dismiss"
                    >
                      <div>
                        This location is outside our current coverage area.
                        Please select "Give Feedback" to let us know why we
                        should add coverage here!
                      </div>
                    </Modal>
                  </div>
                </div>
              )}
            {this.state.visibleFeedback === true && (
              <div style={styles.alertStyle}>
                {/* {this.renderCityMarker(this.state.city)} */}
                <Modal
                  title="Please describe coverage to add"
                  visible={this.state.visibleFeedback}
                  onOk={this.handleAdd}
                  onCancel={this.handleDiscard}
                  okText="Send Feedback"
                  cancelText="Discard"
                >
                  <TextArea
                    rows={4}
                    placeholder="Description"
                    style={{ height: "auto" }}
                    onChange={(event) => this.handleUserDesc(event)}
                  />
                </Modal>
              </div>
            )}
            {this.state.warnCoverage === true &&
              this.state.coverageArea === true && (
                <div>
                  {/* {this.renderCityMarker(this.state.city)} */}

                  <div style={styles.alertStyle}>
                    <Modal
                      title="Name Location"
                      visible={this.state.warnModal}
                      onOk={() => this.handleSubmit(popupInfo)}
                      onCancel={this.handleCancel}
                      okText="Add to account"
                      cancelText="Dismiss"
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          marginBottom: 10,
                        }}
                      >
                        Name this location to add it to your account
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Input
                          placeholder="Name"
                          id="add-location-view"
                          style={{
                            width: "auto",
                            height: "auto",
                            marginBottom: 10,
                          }}
                          onChange={(event) => this.handleUserName(event)}
                        />
                        {/* <TextArea rows={4} /> */}
                        <TextArea
                          id="add-location-view"
                          rows={4}
                          placeholder="Description"
                          style={{ width: "183px", height: "auto" }}
                          onChange={(event) => this.handleUserDesc(event)}
                        />
                      </div>
                    </Modal>
                  </div>
                </div>
              )}
            {this.state.coverageArea === false &&
              targetName === "overlays" &&
              type === "initial" &&
              coverageFeedback === "Coverage Area" && (
                <div style={styles.alertStyle}>
                  <Modal
                    title="Warning: Coverage exists"
                    visible={this.state.visible}
                    onOk={() => this.handleWarning(this.state.city)}
                    onCancel={this.handleCancel}
                    okText="Add Location"
                    cancelText="Dismiss"
                  >
                    <div>
                      Snow intel already provides forecasts for this area.
                      Please tap 'Add Location' to get forecasts for this
                      location by adding it to your account.
                    </div>
                    {/* <TextArea rows={4} /> */}
                  </Modal>
                </div>
              )}
            {this.state.coverageArea === true &&
              type === "initial" &&
              targetName === "overlays" &&
              coverageFeedback === "Coverage Area" && (
                <div>
                  {this.renderCityMarker(this.state.city)}
                  <Modal
                    title="Please describe coverage to add"
                    visible={this.state.feedback}
                    onOk={this.handle}
                    onCancel={this.handleFeedback}
                    okText="Send Feedback"
                    cancelText="Discard"
                  >
                    <TextArea
                      rows={4}
                      placeholder="Description"
                      style={{ height: "auto" }}
                      onChange={(event) => this.handleUserDesc(event)}
                    />
                  </Modal>
                </div>
              )}
            {confirmSubmit === true && (
              <div style={styles.alertStyle}>
                <Modal
                  title="Feedback Received!!"
                  visible={this.state.confirmSubmit}
                  footer={null}
                  // onOk={this.handleAdd}
                  onCancel={this.submitConfirm}
                  // okText='Send Feedback'
                  cancelText="Continue"
                >
                  <div>
                    <div>
                      {" "}
                      You've been entered into our Give Feedback, Get Skis
                      Lottery! Each piece of feedback gives you an additional
                      entry, up to five! The winner will be randomly drawn from
                      the 25% of feedback deemed 'most helpful'.
                    </div>
                    {"\n"}

                    <div>Thanks for helping us to improve Snow Intel!</div>
                  </div>
                </Modal>
              </div>
            )}
            {/* <div style={{display:'flex',background:'red'}}> */}
            {coverageFeedback === "Coverage Area" && (
              //  <div>
              <div className="mobile-view-header" style={styles.feedbackStyle}>
                <div
                  className="feedback-details-view"
                  style={{
                    fontSize: 18,
                    fontWeight: 800,
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  {/* <div style={{width:'100%'}}> */}
                  <div
                    className="feedback-content-view"
                    style={{
                      width: "63%",
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    Tell us where else to add coverage
                  </div>
                  {/* </div> */}
                  {/* <label>
                    <div
                      className="toggle-view"
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-around",
                      }}
                    >
                      <div
                        style={{
                          fontSize: 18,
                          fontWeight: 800,
                          marginRight: 8,
                          paddingTop: "2px",
                          paddingLeft: "5px",
                        }}
                      >
                        Show Coverage Area
                      </div>
                      <div>
                        <Switch
                          onChange={this.handleChange}
                          checked={this.state.checked}
                          className="react-switch"
                          // id="location-switch"
                        />
                      </div>
                    </div>
                  </label> */}
                </div>
              </div>
            )}
            {/* {coverageFeedback !== 'Coverage Area' && 
                      <div className="mobile-view-header" style={styles.feedbackStyle}>
                      <div className="feedback-details-view" style={{ fontSize: 18, fontWeight: 800,display:'flex',justifyContent:'space-between' }}>
                        <div style={{width:'100%'}}>
                        <div className="feedback-content-view" style={{width:'63%',display:'flex',justifyContent:'flex-end'}}>
                        Add location by clicking inside coverage area
                      </div>
                      </div>
                      <label>
                    <div className="toggle-view" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around'}}>
                      <div style={{ fontSize: 18, fontWeight: 800, marginRight: 8,paddingTop:'2px',paddingLeft:'5px'}}>
                      Show Coverage Area
                      </div>
                    <div>
                    <Switch
                      onChange={this.handleChange}
                      checked={this.state.checked}
                      className="react-switch"
                      // id="location-switch"
                    />
                    </div>
                      </div>
                  </label>
                      </div>
                </div>
                      } */}


            <div className="nav" style={styles.navStyle}>
              <NavigationControl />
            </div>

            {/* <div style={styles.iconDivStyle} id="home-icon-view"> */}
            {/* <div
              
                style={
                  enableInfo === true
                    ? styles.iconEnableViewStyle
                    : styles.iconDisableViewStyle
                }
                onClick={() => this.changeInfoStatus(enableInfo)} 
              >
                <img src={enableInfo === true ? infoBlack : infoWhite}    
                  style={
                    enableInfo === true
                      ? styles.iconEnableStyle
                      : styles.iconDisableStyle
                  }
                    alt="Info" 
                    align="left" 
                    width='23' 
                    height='23' 
                    onClick={() => this.changeInfoStatus(enableInfo)} 
                  />
              </div> */}
            {/* <div
               
                style={
                  enableStack === true
                    ? styles.iconEnableViewStyle
                    : styles.iconDisableViewStyle
                }
                onClick={() => this.changeStackStatus(enableStack)} 
              >
                <img src={enableStack === true ? layersBlack : layersWhite}    
                  style={
                    enableStack === true
                      ? styles.iconEnableStyle
                      : styles.iconDisableStyle
                  }
                    alt="Layers" 
                    align="left" 
                    width='23' 
                    height='23' 
                    onClick={() => this.changeStackStatus(enableStack)} 
                  />
              </div> */}
            {/* </div>  */}

            

            {/* {enableInfo === true && (
              <div id="location-content-view" style={styles.infoContentView}>
                <img src={infoWhite}    
                    style={styles.infoIconStyle}
                    alt="Info" 
                    align="left" 
                    width='20' 
                    height='20' 
                  />
                <div style={styles.radioView} id="radio-button-view">
                  <div style={styles.addViewStyle}>
                  <div style={{width: 15,height:15, alignSelf: 'left',borderRadius:'50%',borderWidth:1,borderStyle:'solid',borderColor:'lightgrey'}}></div>
                    <p style={styles.addTextStyle}>Tap Map To Add A Pit</p>
                  </div>
                  <div style={styles.seeViewStyle}>
                  <img src={Dot} style={{width: 15,height:15, alignSelf: 'left'}} />
                    <p style={styles.seeTextStyle}>Tap Pit To See Details</p>
                  </div>
                  <div style={styles.helpViewStyle}>
                    <Icon type="question-circle-o" style={styles.helpIcon} />
                    <p style={styles.helpTextStyle}>Tap Help For More Info</p>
                  </div>
                </div>
              </div>
            )} */}
            <Modal
              title="Choose Overlay:"
              className="header-modal-view"
              visible={visibleModal}
              footer={null}
              onCancel={this.handleCancelModal}
            >
              {forecastLayerData !== null &&
                <div>
                  {forecastLayerData.Layers.length > 0 && forecastLayerData.Layers.map(Option => {
                    return <p style={{ color: 'white', fontSize: 14, cursor: 'pointer' }} onClick={() => { this.selectForecastLayer(Option) }}>{Option.Layer_Display}</p>
                  })}
                  <p style={{ color: 'white', fontSize: 14, textAlign: 'right' }}>Date Updated: {forecastLayerData.Updated}</p>
                </div>
              }
            </Modal>
          </MapGL>
		  {enableStack === true && (
              <div id="stack-content-view" style={styles.stackContentView}>
			  <p style={{ color: '#fff', marginBottom: 10, marginRight: 0 }}>Toggle Map Data Layers</p>
                <div style={styles.iconView}>
                  <div style={enableForecastIcon === true ? styles.latestIconEnableView : styles.latestIconDisableView}
                    onClick={() => this.changeForecastIconStatus(enableForecastIcon)}>
                    <img src={enableForecastIcon === true ? snowBlack : snowWhite} style={enableForecastIcon === true ? styles.latestIconEnable : styles.lastetIconDisable}
                      alt="Satellite" align="center" width='20' height='20'
                      onClick={() => this.changeForecastIconStatus(enableForecastIcon)}
                    />
                    <p style={enableForecastIcon === true ? styles.latestEnableTextStyle : styles.latestDisableTextStyle}>Forecast</p>
                  </div>

                  <div style={enableSlopeIcon === true ? styles.terrainIconEnableView : styles.terrainIconDisableView} onClick={() => this.changeSlopeIconStatus(enableSlopeIcon)}>
                    <img src={enableSlopeIcon === true ? angleBlack : angleWhite} style={enableSlopeIcon === true ? styles.terrainIconEnable : styles.terrainIconDisable}
                      alt="Terrain" align="center" width='20' height='20'
                      onClick={() => this.changeSlopeIconStatus(enableSlopeIcon)} />

                    <p style={enableSlopeIcon === true ? styles.terrainEnableTextStyle : styles.terrainDisableTextStyle}>Slope</p>
                  </div>

                  <div style={enableLatestIcon === true ? styles.topoIconEnableView : styles.topoIconDisableView} onClick={() => this.changeLatestIconStatus(enableLatestIcon)}>
                    <img src={enableLatestIcon === true ? cameraBlack : cameraWhite} style={enableLatestIcon === true ? styles.topoIconEnable : styles.topoIconDisable}
                      alt="Terrain" align="center" width='20' height='20'
                      onClick={() => this.changeLatestIconStatus(enableLatestIcon)}
                    />
                    <p style={enableLatestIcon === true ? styles.topoEnableTextStyle : styles.topoDisableTextStyle}>Satellite</p>
                  </div>

                </div>
                {enableForecastIcon === true && enableLatestIcon === false && (
                  <div style={{fontSize: 15, fontWeight: 600, marginBottom: 10, justifyContent: 'center'}} id="home-slider">
                    <div style={{ backgroundColor: 'black', flex: 'none', borderRadius: 5 }} onClick={() => { this.setState({ visibleModal: true }) }}>
                      {selectedForecastLayer !== null ?
                        <p style={{ color: 'white', fontSize: 14, paddingLeft: 10, marginBottom: '.2em'}}>{selectedForecastLayer.Layer_Display}
                          <Icon type="down" style={styles.ellipsisIconView} /></p>:<div style={styles.alignSpin}><Spin /> </div>}
                    </div>
                  </div>
                )}
                {enableLatestIcon === true &&
                  (latestImageryData && latestImageryData.length > 0 ?
                    <Slider {...settings} initialSlide={latestLayerIndex} beforeChange={this.onChangeSlider}>
                      {latestImageryData.map(option => {
                        return (
                          <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                            <p style={{ color: 'white', fontSize: 14, marginBottom: 0 }}>{option.Layer_Date}</p>
                            {/* Hidding Name */}
                            {/* <p style={{color: 'white', fontSize: 12,marginBottom:0}} id="slider-text-view">{option.Layer_Name}</p> */}
                          </div>
                        )
                      })}
                    </Slider> : <div style={styles.alignSpin}><Spin /> </div>
                  )}
                {enableSlopeIcon === true && 
                  <div style={{ display: 'flex', width: '88%', minHeight: '25px', justifyContent: 'center'}} id="slope-angle-view">
                        <p style={{ color: '#fff', marginBottom: 10, marginRight: 0 }}>{selectedForecastLayer.Legend_Name}:</p>
                  </div>
                }
                {/* {enableSlopeIcon === true && enableLatestIcon === false && (
          <div style={{display:'flex',width:'88%'}} id="slope-angle-view">
            <div style={{position:'relative',right:8}}>
            <Icon type="ellipsis"  style={styles.ellipsisIconStyle}  />
            </div>
              <p style={{color: 'white', fontSize: 14,paddingLeft:10}}>Slope Angle Shading:  
              <Checkbox onChange={this.onChangeSlopeCheck} checked={slopeAngleCheck} style={{marginLeft: 10}}>
              </Checkbox>
              </p>
          </div>
          )} */}
		                      {selectedForecastLayer !== null && (enableForecastIcon === true || enableSlopeIcon === true) &&
                      <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <div style={{ flexDirection: 'column' }} id="column-view">
                          <div style={{ display: 'flex' }}>
                            {selectedForecastLayer !== null && selectedForecastLayer.Legend_Colors.map((color, index) => {
                              return (
                                <div style={{ display: 'block', flexDirection: 'column', justifyContent: 'center' }}>
                                  <div id="linear-container-modal" style={{
                                    backgroundImage: `linear-gradient(${color}, ${(selectedForecastLayer.Legend_Colors.length - 1 < index + 1)
                                      ? selectedForecastLayer.Legend_Colors[selectedForecastLayer.Legend_Colors.length - 1]
                                      : selectedForecastLayer.Legend_Colors[index + 1]})`, height: 10
                                  }}></div>
                                  {/* {this.showLegend(snowDepth.Legend_Values,snowDepth.Legend_Colors.length,index)} */}
                                </div>
                              )
                            })}
                          </div>
                          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }} id="linear-container-view">
                            {selectedForecastLayer !== null && enableForecastIcon === false && selectedForecastLayer.Legend_Values.map((color, index) => {
                              return (
                                <div>
                                  {index !== 0 ?
                                    <div className="aqua-view" id="linear-container-modal" 
                                    style={{ color: '#fff'}}>
                                      {selectedForecastLayer.Legend_Values[index]}</div>
                                    :
                                    <div id="linear-container-modal" style={{ color: '#fff' }}>
                                      {selectedForecastLayer.Legend_Values[index]}
                                    </div>
                                  }
                                </div>
                              )
                            })}
                          </div>
						      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }} id="linear-container-view">
                            {selectedForecastLayer !== null && enableForecastIcon === true && selectedForecastLayer.Legend_Values.map((color, index) => {
                              return (
                                <div>
                                  {index !== 0 ?
                                    <div className="aqua-view" id="linear-container-modal" 
                                    style={{ color: '#fff'}}>
                                      {selectedForecastLayer.Legend_Values[index]}"</div>
                                    :
                                    <div id="linear-container-modal" style={{ color: '#fff' }}>
                                      {selectedForecastLayer.Legend_Values[index]}"
                                    </div>
                                  }
                                </div>
                              )
                            })}
                          </div>
                        </div>
                      </div>
                    }
					{selectedForecastLayer !== null && (enableLatestIcon === true) &&
                      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <p style={{ color: '#fff', marginBottom: 0, marginRight: 10 }}>No Image For Date:</p>
						<div id='latestLegendColor' style={{backgroundColor: '#ed286a', width: '2vh', height:"2vh"}}/>
                        
                      </div>
                    }
		  
              </div>
            )}
			
			<div id="map-banner">
			<p style={{ color: '#fff', marginBottom: 0, marginRight: 0, fontSize: 12 }}>Tap A Location To Get Detailed Conditions</p>
                        
                      </div>
			            <PlacesAutocomplete
              value={address}
              onChange={this.handleaddChange}
              onSelect={this.handleSelect}
			  onEnterKeyDown={this.handleSelect}
			  clearItemsOnError = {true}
            >
              {({
                getInputProps,
                suggestions,
                getSuggestionItemProps,
                loading,
              }) => (
                  <div id="top_controls" className="Demo__search-bar-container">
                    <div id="search-topo-icon">
                      <img src={enableTopoIcon ? sunnyday : topoBlack}
                        onClick={() => this.changeTopo(enableTopoIcon)}
                        alt="Terrain" align="center" width={enableTopoIcon ? '70%' : '90%'} height={enableTopoIcon ? '70%' : '90%'}
                      />
                    </div>
                    <div style={{width:'100%'}}>
                    <div className="Demo__search-input-container">
                      <Icon
                        type="search"
                        style={{ color: 'black', position: 'relative', height: '100%', alignItems: 'center', left: 10, top: 40 }}
                      />
                      <input
                        style={{ paddingLeft: 30 }}
                        {...getInputProps({
                          placeholder: "Search",
                          className: "Demo__search-input",

                        })}
                      />
                      <div className="autocomplete-dropdown-container">
                        {loading && <div>Loading...</div>}
                        {suggestions.map((suggestion) => {
                          const className = suggestion.active
                            ? "suggestion-item--active"
                            : "suggestion-item";
                          // inline style for demonstration purpose
                          const style = suggestion.active
                            ? { backgroundColor: "#fafafa", cursor: "pointer" }
                            : { backgroundColor: "#ffffff", cursor: "pointer" };
                          const onClick = suggestion.active
                            ? () => this.selectValue(suggestion.description)
                            : null;
                          return (
                            <div
                              {...getSuggestionItemProps(suggestion, {
                                //  className,
                                style,
                                onClick,
                              })}
                            >
                              <span>{suggestion.description}</span>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                    </div>
                  </div>
                )}
            </PlacesAutocomplete>
			
        </div>
      </Card>
    );
  }
}

const mapStateToProps = (state) => {
  const { location, locationLoading } = state.locationData; // Reducer Name
  return {
    location, // Reducer variable
    locationLoading,
  };
};

const mapDispatchToProps = {
  getLocation, //Action
};
export default connect(mapStateToProps, mapDispatchToProps)(UsersHomeScreen);

import React, { Component } from 'react';
import { Layout, Menu, Icon } from 'antd';
import { Link, withRouter } from "react-router-dom";
import userProfile from '../../themes/assets/images/user_profile.png';
import { AuthService } from '../auth/auth.service';
import logo from '../../themes/assets/images/logo.png';
import styles from './styles';
import n from '../navigation/Route.Names';
import routeLabels from './routeLabels';
import Logout from './logout'
import MenuItem from 'antd/lib/menu/MenuItem';
import { PlusOutlined } from '@ant-design/icons';

const { Header, Sider } = Layout;

const beforeAddMenuItems = [
  
  {
    name: routeLabels.HOME,
    value: n.HOME,
    path: n.HOME,
    icon: "home",
  },
  {
    name: routeLabels.USERSHOME,
    value: n.USERSHOME,
    path: n.USERSHOME,
    icon: "file-add"
  },
  {
    name: routeLabels.PREFERENCE,
    value: n.PREFERENCE,
    path: n.PREFERENCE,
    icon: "like"
  },
  {
    name: routeLabels.FEEDBACK,
    value: n.FEEDBACK,
    path: n.FEEDBACK,
    icon: "meh"
  },
  {
    name: routeLabels.SUBSCRIPTION,
    value: n.SUBSCRIPTION,
    path: n.SUBSCRIPTION,
    icon: "credit-card"
  },
  {
    name: routeLabels.ADDTOHOMESCREEN,
    value: "",
    path: "",
    icon: routeLabels.ADDTOHOMESCREEN
  },
];

const afterAddMenuItems = [
  
  {
    name: routeLabels.HOME,
    value: n.HOME,
    path: n.HOME,
    icon: "home",
  },
  {
    name: routeLabels.USERSHOME,
    value: n.USERSHOME,
    path: n.USERSHOME,
    icon: "file-add"
  },
  {
    name: routeLabels.PREFERENCE,
    value: n.PREFERENCE,
    path: n.PREFERENCE,
    icon: "like"
  },
  {
    name: routeLabels.FEEDBACK,
    value: n.FEEDBACK,
    path: n.FEEDBACK,
    icon: "meh"
  },
  {
    name: routeLabels.SUBSCRIPTION,
    value: n.SUBSCRIPTION,
    path: n.SUBSCRIPTION,
    icon: "credit-card"
  }
];

let deferredPrompt = null;

export class SideMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentUser: {},
      addPrompt: true
    };
  }

  componentDidMount() {
    //subscribe the urrent user details
    // console.log(this.props, "did mount")
    this.subscription = AuthService.getCurrentUser().subscribe(user => {
      if (user) {
        this.setState({ currentUser: user.currentUser });
      }
    });

    window.addEventListener('beforeinstallprompt', function (e) { 
      // console.log('beforeinstallprompt called', e)
      deferredPrompt = e;
    }); 

    window.addEventListener('appinstalled', (evt) => {
      // Log install to analytics
      // console.log('INSTALL: Success');
      deferredPrompt = null
    });

    this.hideAddToHomeScreen();
  }

  componentWillReceiveProps(nextProps) {
    // console.log("nextProps", nextProps)
    this.subscription = AuthService.getCurrentUser().subscribe(user => {
      // console.log("componentWillReceiveProps this.state.currentUser",user)
      if (user) {
        this.setState({ currentUser: user.currentUser });
        // console.log("this.state.currentUser",this.state.currentUser)
      }
    });
  }

  componentWillUnmount(){
    try{
      if(deferredPrompt !== null){
        window.removeEventListener('beforeinstallprompt');
      }
    }catch(error){
      console.log("error in removeEventListener")
    }

  }
  

  hideAddToHomeScreen = () => {
    setTimeout(() => {
      // console.log("hideAddToHomeScreen called", deferredPrompt) 
      if (window.matchMedia('(display-mode: standalone)').matches) {
        // console.log("matches")
        deferredPrompt = null
      }
      this.setState({addPrompt: deferredPrompt !== null ? true : false},()=>{
        // console.log("deferredPrompt", this.state.addPrompt)
      })
    },3000)
  }
  

  addToHomeScreen = () => {
    // console.log("addToHomeScreen called") 
    // Show the prompt
    if(deferredPrompt !== null){
      deferredPrompt.prompt();
    }

    this.showAddToHomeScreen();
  }

  showAddToHomeScreen = () => { 
    // console.log("showAddToHomeScreen called")
      // Wait for the user to respond to the prompt
      if(deferredPrompt !== null){
        deferredPrompt.userChoice
        .then((choiceResult) => {
          if (choiceResult.outcome === 'accepted') {
            // console.log('User accepted the A2HS prompt');
            deferredPrompt = null;
            this.hideAddToHomeScreen();
          } else {
            // console.log('User dismissed the A2HS prompt');
          }
        });
      }
  }

  render() {
    const { addPrompt } = this.state;
    // console.log("SIDEMENU deferredPrompt", addPrompt, typeof(addPrompt));
     let menuItems = addPrompt === true ? beforeAddMenuItems : afterAddMenuItems

    return (

      <Layout>
        <Sider
          style={styles.sider}
          id="sidebarHeaderContainer"
        >
          <Header style={styles.onHeaderRow} id="sidebarHeader">
            {/* <div  style={{display:'flex',justifyContent:'center'}}>
                        <img src={userProfile} alt="Profile" align="left" width='40' height='40' marginTop='10' style={{borderRadius:45,marginTop:'10px',marginLeft:'5px'}} />
                        {currentUser && (currentUser.displayName) &&
                        <p style={styles.displayName}>{currentUser.displayName}</p>
                        }
                    </div> */}
          </Header>
          <div style={{ backgroundColor: '#333333' }} />
          <Menu theme="dark" mode="inline"   {...this.props}
            selectedKeys={
              this.props.location.pathname === n.USERSHOME && this.props.location.state !== undefined ?
                null : this.props.location.pathname
            }>
            {/* <div className=""></div> */}
            {menuItems.map(element => (

              <Menu.Item key={element.value}>
               
                {routeLabels.ADDTOHOMESCREEN === element.icon ?
                  <Link id="sidebar-icon-view" onClick={this.addToHomeScreen} style={styles.iconStyle} >
                    <PlusOutlined />
                    <span style={styles.textStyle}> {element.name} </span>
                  </Link>:
                  <Link id="sidebar-icon-view" to={element.path} onClick={this.props.onchange(false)} style={styles.iconStyle} >
                    <Icon type={element.icon} theme="outlined"/> 
                    <span style={styles.textStyle}>{element.name}</span>
                  </Link> 
                }
              </Menu.Item>
            ))}
            <Menu.Item>
          
            <Logout />
          </Menu.Item>
      </Menu>
    </Sider>
  </Layout >
        )
  }
}

export default withRouter(SideMenu);

import React, {Component} from 'react';
import {CardCvcElement, CardExpiryElement, CardNumberElement, injectStripe,} from 'react-stripe-elements';
import {Card, message, Spin,Button} from 'antd';
import styles from '../payment.Style';
// import { useHistory } from "react-router-dom";
import * as subscriptionAPI from "../../../api/subscriptions";

const handleBlur = () => {
    // console.log('[blur]');
};
const handleChange = change => {
    // console.log('[change]', change);
};
const handleClick = () => {
    // console.log('[click]');
};
const handleFocus = () => {
    // console.log('[focus]');
};
const handleReady = () => {
    // console.log('[ready]');
};

const createOptions = (fontSize, padding) => {
    return {
        style: {
            base: {
                fontSize,
                color: '#424770',
                letterSpacing: '0.025em',
                fontFamily: 'Source Code Pro, monospace',
                '::placeholder': {
                    color: '#aab7c4',
                },
                ...(padding ? {padding} : {}),
            },
            invalid: {
                color: '#9e2146',
            },
        },
    };
};

class CardForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            cardName: '',
            cardToken: null,
            chosenValue: this.props.choosenValue,
            price: 0,
            interval: '',
            currentUserAuth: null,
            street: '',
            city: '',
            state: '',
            code: '',
            country: '',
            loading: false,
            responseData: null,
        }
    }

    componentDidMount() {
        console.log("choosenValue", this.state.chosenValue)
        let price = 0
        let interval = ''
        if (this.state.chosenValue === '1') {
            price = 15
            interval = 'year'
        } else if (this.state.chosenValue === '2') {
            price = 5
            interval = 'month'
        }
        console.log("price", price, interval)
        this.setState({
            price: price,
            interval: interval,
            currentUserAuth: JSON.parse(localStorage.getItem('currentUserAuth')),
        })
    }

    handleSubmit = (ev) => {
        this.setState({
            loading: true
        })
        // console.log('handleSubmit123', ev, this.props, this.state.cardName);
        const {street, city, state, code, country} = this.state
        ev.preventDefault();
        // let history = useHistory();
        if (this.props.stripe) {
            // console.log('handleSubmit123 if', props.stripe);
            this.props.stripe.createToken().then((payload, err) => {
                // console.log('handleSubmit123 payload', payload,);
                if(payload.hasOwnProperty('error')){
                    // console.log('handleSubmit123 error', payload.error);
                    this.setState({
                        loading: false
                    })
                    message.error(payload.error.message);
                }else{
                    this.setState({
                        cardToken: payload.token.id
                    }, () => {
                        if (street !== null && street != ''
                            && city !== null && city != ''
                            && state !== null && state != ''
                            && code !== null && code != ''
                            && country !== null && country != '') {
                            let paymentObject = {
                                amount: this.state.price,
                                interval: this.state.interval,
                                currency: "usd",
                                token: this.state.cardToken,
                                name: this.state.currentUserAuth.displayName,
                                userId: this.state.currentUserAuth.uid,
                                street: street,
                                city: city,
                                state: state,
                                code: code,
                                country: country
                            }
                            // console.log("paymentObject", paymentObject)
                            subscriptionAPI.createSubscriptionPlan(paymentObject).then(response => {
                                // console.log('response', response)
                                message.success(response.message);
                                this.setState({
                                    loading: false
                                })
                                window.location.reload();
                                // window.location = '/subscription'
                                // this.props.history.push({
                                //     pathname: '/subscription'
                                // })

                            }).catch((err)=>{
                                message.error(err);
                            })
                        } else {
                            // console.log('fill all fields');
                            message.error('Fill all fields');
                            this.setState({
                                loading: false
                            })
                        }
                    })
                }

            }).catch((err)=>{
                message.error(err);
            })
        } else {
            // console.log('handleSubmit123 error');
            console.log("Stripe.js hasn't loaded yet.");
        }
    }

    onChange = (e) => {
        // console.log(`checked = ${e.target.checked}`);
    }

    setCardName = (value) => {
        this.setState({
            cardName: value
        })
    }

    setStreetName = (value) => {
        this.setState({
            street: value
        })
    }

    setCity = (value) => {
        this.setState({
            city: value
        })
    }

    setStateName = (value) => {
        this.setState({
            state: value
        })
    }

    setCode = (value) => {
        this.setState({
            code: value
        })
    }

    setCountry = (value) => {
        this.setState({
            country: value
        })
    }

    render() {
        const {cardName, cardToken, street, city, state, code, country,responseData} = this.state

        return (
            <div>
                {/* {responseData === null && */}
            <div style={{padding: '10px', display: 'block', left:'50vw', width:'100%'}}>
                <Card style={styles.card} >
                    <div className="formContainer">
                        <h2 style={{display:'flex',justifyContent:'center', marginTop:'50px'}}>Payment Method</h2>
                        <form onSubmit={this.handleSubmit}>
                            <div id='add-card' style={{marginLeft: window.innerWidth > 900 ? '30vw' : '10vw'}}>
                            <label className="paymentLabel">
                                Card number
                                <CardNumberElement
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    onFocus={handleFocus}
                                    onReady={handleReady}
                                    {...createOptions(14)}
                                />
                            </label>

                            <label className="paymentLabel">
                                Expiration date
                                <CardExpiryElement
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    onFocus={handleFocus}
                                    onReady={handleReady}
                                    {...createOptions(14)}
                                    value="774"
                                />
                            </label>
                            <label className="paymentLabel">
                                CVC
                                <CardCvcElement
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    onFocus={handleFocus}
                                    onReady={handleReady}
                                    {...createOptions(14)}
                                />
                            </label>
                            <label className="paymentLabel">
                                Name on Card
                                <input
                                    value={cardName}
                                    name="name"
                                    type="text"
                                    placeholder="Enter name"
                                    onChange={(event) => this.setCardName(event.target.value)}
                                    // required
                                    className="paymentNameLabel"
									autocomplete="name"
                                />
                            </label>
                            <label className="paymentLabel">
                                Address in line
                                <input
                                    value={street}
                                    name="name"
                                    type="text"
                                    placeholder="Enter your street name"
                                    onChange={(event) => this.setStreetName(event.target.value)}
                                    // required
                                    className="paymentNameLabel"
									autocomplete="billing street-address"
                                />
                            </label>
                            <label className="paymentLabel">
                                City
                                <input
                                    value={city}
                                    name="name"
                                    type="text"
                                    placeholder="Enter your City"
                                    onChange={(event) => this.setCity(event.target.value)}
                                    // required
                                    className="paymentNameLabel"
									autocomplete="billing address-level2"
                                />
                            </label>
                            <label className="paymentLabel">
                                State
                                <input
                                    value={state}
                                    name="name"
                                    type="text"
                                    placeholder="Enter your state"
                                    onChange={(event) => this.setStateName(event.target.value)}
                                    // required
                                    className="paymentNameLabel"
									autocomplete="billing address-level1"
                                />
                            </label>
                            <label className="paymentLabel">
                                Postal Code
                                <input
                                    value={code}
                                    name="name"
                                    type="text"
                                    placeholder="Enter your postal code"
                                    onChange={(event) => this.setCode(event.target.value)}
                                    // required
                                    className="paymentNameLabel"
									autocomplete="billing postal-code"
                                />
                            </label>
                            <label className="paymentLabel">
                                Country
                                <input
                                    value={country}
                                    name="name"
                                    type="text"
                                    placeholder="Enter your country name"
                                    onChange={(event) => this.setCountry(event.target.value)}
                                    // required
                                    className="paymentNameLabel"
									autocomplete="billing country"
                                />
                            </label>
                            </div>
                            {/*<Checkbox onChange={this.onChange}>Save card information to maintain access to Snow*/}
                            {/*Intel</Checkbox>*/}
                            <div style={{marginTop: 20}}></div>
                            <div style={{display:'flex',justifyContent:'center', paddingBottom: '120px'}}>
                            <button id="payButton" className="btn btn-primary" type="submit" style={{minHeight: 50, minWidth: 200, fontWeight: 700, fontSize: 18, marginTop:30}}>
                                Submit Purchase
                            </button>
                            </div>
                            <Spin 
                            style={{ display: 'flex', justifyContent: 'center'}}
                            spinning={this.state.loading}>
                            </Spin>
                        </form>
                    </div>
                </Card>
            </div>
                {/* } */}
            </div>
        )
    }
}

export default injectStripe(CardForm);

import React, {Component} from 'react';
import moment from 'moment';
import { Alert, Button, Card, List, Dropdown , Modal, Menu, Input, DatePicker, Icon, Radio, Form, Select  } from 'antd';
import styles from './feedback.Style';
import * as userFeedback from '../../api/feedback';
import { getLocation } from '../../actions/locationAction';
import { connect } from 'react-redux';
import {AuthService} from "../auth/auth.service";
import {collectionConfig} from '../../api/collectionName';
import n from '../navigation/Route.Names'

const { Option } = Select;
const FormItem = Form.Item;
const { TextArea } = Input;

const feedback = [

    {
      title: 'App/Features',
      description: 'Show the skiing quality of each layer based on both the density and hardness of each layer.',
      radioChecked: '1'
    },
    {
      title: 'Coverage Area',
      description: 'Show the density of each layer. The denser the snow, the wetter and more consolidated it is.',
      radioChecked: '2'
    },
    {
      title: 'Forecast Accuracy',
      description: 'Show the hardness of each layer. The harder the snow, the more it behaves like a cohesive slab.',
      radioChecked: '3'
    },
    // {
    //   title: 'Lottery flow',
    //   description: 'Show the temperature of each layer.',
    //   radioChecked: '4'
    // },
  ];

  const formItemLayout = {
    labelCol: { span: 8 },
    // wrapperCol: { span: 16 },
  };

  let tempTimeout

class FeedbackScreen extends Component {
    constructor(props){
        super(props);
        this.state = {
           feedbackTitle : null,
           visible: true,
           visiblelottery: false,
           value: null,
           valueRadio: null,
           feedbackLat: null,
           feedbackLong: null,
           forecastDesc: null,
           feedbackDate: '',
           currentUser: null,
           locations: [],
           subsCancelMonth: false,
           confirmSubmit: false,
           addValue: 2
        }
    }

    componentDidMount(){
      if(this.props && this.props.history && this.props.history.location.state){
        this.setState({
          feedbackLat: this.props.history.location.state.feedbackLat,
          feedbackLong: this.props.history.location.state.feedbackLong,
        })
      }
        //subscribe the urrent user details
      //   this.subscription = AuthService.getCurrentUser().subscribe(user => {
      //     if (user) {
      //         this.setState({currentUser:user.currentUser});
      //     }
      // });
        this.setState({
          currentUser:JSON.parse(localStorage.getItem('currentUserAuth'))
        },()=>{
          this.props.getLocation(this.state.currentUser.uid)
        });
    }


    componentWillReceiveProps(nxtprops){
        this.setState({currentUser:JSON.parse(localStorage.getItem('currentUserAuth'))});
        if (nxtprops && nxtprops.location && nxtprops.location.location) {
          this.setState({
            locations: nxtprops.location.location,
          },()=> {
          })
        }
  }


    feedbackClick = (data) => {
         if(data !== null){
             this.setState({
                feedbackTitle: data,
                visible: true
             },()=>{
                // console.log(this.state.feedbackTitle,'feedbackTitle1122')

             })
         }
     }

     handleOk = (e) => {
          this.setState({
            visible: false,
          });
            this.props.history.push('./home')

      };

     handleCancel = (e) => {
        this.setState({
          visible: false,
        });
      };

      handleLotteryok = () => {
        this.setState({
          visiblelottery: false,
        });
      }

      handleLottery = () =>{
        this.setState({
          visiblelottery: false,
        });
      }

      onChange = (value, dateString) => {
      }

      onOk = (value) => {
      let date = moment(value).format('MMMM Do YYYY, h:mm:ss a');
      this.setState({
        feedbackDate: date
      })
      }

      onChangeRadio = (e) => {
        this.setState({
          valueRadio : e.target.value,
        });
      };

      handleSubmit = (e) => {
        let date = moment(new Date()).format('MMMM Do YYYY, h:mm:ss a');
        const {feedbackTitle,feedbackDate,currentUser} = this.state
        e.preventDefault();
        this.props.form.validateFields(async(err, values) => {
        if (!err && values !== undefined) {
             this.setState({
              forecastDesc: values.feedback,
              confirmSubmit: true,
              // visible: false
             },()=>{
              tempTimeout = setTimeout(() => {
                this.setState({confirmSubmit: false, visible: false}, this.props.history.push('./home'))
              }, 5000)
             })
             if(feedbackTitle === 'Forecast Accuracy'){
              let prefObject={
                "type": feedbackTitle,
                "location":values.location,
                "desc":values.feedback,
                "condition":values.conditions,
                "dateOnFeedback":feedbackDate,
                "date":date,
                "email": currentUser.email,
                "uid": currentUser.uid
            }
            await userFeedback.updateUserFeedback(this.state.currentUser.uid,prefObject,collectionConfig.FEEDBACK);
             }
             else if(feedbackTitle === 'App/Features'){
              let prefObject={
                "type": feedbackTitle,
                "location":null,
                "desc":values.feedback,
                "title":values.title,
                "experience":values.experience,
                "dateOnFeedback":date,
                "date":date,
                "email": currentUser.email,
                "uid": currentUser.uid
            }
            await userFeedback.updateUserFeedback(this.state.currentUser.uid,prefObject,collectionConfig.FEEDBACK);
             }

            //  this.props.history.push('./home')
          }
        });
      };

      onChange = (value)=> {
        // console.log(`selected ${value}`);
      }

      onBlur() {
        // console.log('blur');
      }

      onFocus() {
        // console.log('focus');
      }

      onSearch(val) {
        // console.log('search:', val);
      }

    featuresView = () => {
      const { getFieldDecorator } = this.props.form
      return (
        <Modal
        title="Features Feedback"
        visible={this.state.visible}
        footer={null}
        onCancel={this.handleCancel}
        >
             <Form className="formView">
             <Form.Item {...formItemLayout} onSubmit={this.handleSubmit} label="Title">
        {getFieldDecorator('title', {
          rules: [
            {
              required: true,
              message: 'Please give your title',
            },
          ],
        })( <TextArea rows={1} />)}
      </Form.Item>
             <Form.Item {...formItemLayout} onSubmit={this.handleSubmit} label="Experience with current app/feature:">
        {getFieldDecorator('experience', {
          rules: [
            {
              required: true,
              message: 'Please give your experience',
            },
          ],
        })( <TextArea rows={3} />)}
      </Form.Item>
        <Form.Item {...formItemLayout} onSubmit={this.handleSubmit} label="Ideas for a better experience">
        {getFieldDecorator('feedback', {
          rules: [
            {
              required: true,
              message: 'Please input your feedback',
            },
          ],
        })( <TextArea rows={4} />)}
      </Form.Item>
      <Form.Item style={{ display: 'flex', justifyContent: 'center'}}>
          <Button id="common-button-view" type="primary" htmlType="submit" onClick={this.handleSubmit}>
            Submit
          </Button>
        </Form.Item>
        </Form>

        </Modal>
       )
    }

    forecastView = () => {
      const { getFieldDecorator } = this.props.form
      const options = this.state.locations.map(d => <Option key={d.name}>{d.name}</Option>);
      const config = {
        rules: [{ type: 'object', required: true, message: 'Please select date and time!' }],
      };
      const configLocation = {
        rules: [{ type: 'string', required: true, message: 'Please select location!' }],
      };
        return (
            <Modal
            title="Forecast Feedback"
            visible={this.state.visible}
            footer={null}
            onCancel={this.handleCancel}
            >
             <Form>
             <Form.Item label="Select date and time">
          {getFieldDecorator('date', config)(
            <DatePicker  showTime={{format: 'HH:mm', minuteStep: 15}}
            format="MM-DD-YYYY HH:mm"
            onOk={this.onOk} />,
          )}
        </Form.Item>
        <Form.Item label="Select Location">
          {getFieldDecorator('location', configLocation)(
            <Select showSearch
            style={{ width: 200 }}
            placeholder="Select a location"
            optionFilterProp="children"
            onChange={this.onChange}
            onFocus={this.onFocus}
            onBlur={this.onBlur}
            onSearch={this.onSearch}
            filterOption={(input, option) =>
              option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            >
              {options}
            </Select>,
          )}
        </Form.Item>
        <Form.Item label="Expected conditions based on Forecast" {...formItemLayout} onSubmit={this.handleSubmit}>
        {getFieldDecorator('conditions', {
          rules: [
            {
              required: true,
              message: 'Please input your feedback',
            },
          ],
        })(<TextArea rows={2} />)}

      </Form.Item>
        <Form.Item label="Conditions Encountered" {...formItemLayout} onSubmit={this.handleSubmit}>
        {getFieldDecorator('feedback', {
          rules: [
            {
              required: true,
              message: 'Please input your feedback',
            },
          ],
        })(<TextArea rows={3} />)}

      </Form.Item>
      <Form.Item style={{ display: 'flex', justifyContent: 'center'}}>
          <Button id="common-button-view" type="primary" htmlType="submit" onClick={this.handleSubmit}>
            Submit
          </Button>
        </Form.Item>
        </Form>
            </Modal>
        )
    }

    renderCancelMonthly = (cancelMonth) => {
      const {visible} = this.state
      if (cancelMonth !== null && cancelMonth === true) {
          this.setState({
              subsCancelMonth: cancelMonth,
              visiblelottery: true
          })
      }
  }

    handleOkButton = (key) => {
      if(key !== null){
        this.setState({
          value: key,
          visible: true
        })
      }
    };

    render(){
        const { getFieldDecorator } = this.props.form
        const {feedbackTitle, visible,visiblelottery, value, valueRadio, feedbackLat, feedbackLong,subsCancelMonth, confirmSubmit} = this.state
        console.log(visible,'visible')
       return (
            <div className="card-modal" style={{ padding: 10}}>
                <Card style={styles.card} id="modal-card">
                    <div style={styles.header}>Help us Improve!</div>
                    <div style={{ marginTop: 50 }}>
                    <Card style={styles.locationStyle}>
                        <div style={{ display: 'flex',justifyContent:'center'}}>
                        <div style={styles.location}>Give Feedback on our:</div>
                        </div>
                        {/* <Radio.Group onChange={this.onChangeRadio} value={valueRadio}> */}
                    <List
                     style={{ marginTop: 10, display: 'flex',justifyContent:'center' }}
                     itemLayout="horizontal"
                     dataSource={feedback}
                     renderItem={(item, index) => (
                    <List.Item>
                    <List.Item.Meta
                    id="feedback-list"
                    style={styles.listStyle}
                    title={item.title}
                    onClick={() => this.feedbackClick(item.title)}
                     />
                     {/* <Radio
                      style={styles.radioStyle}
                      value={item.radioChecked}>
                      </Radio> */}
                     {
                         feedbackTitle === 'App/Features' && visible === true &&
                         <div>
                         {this.featuresView()}
                         </div>

                     }
                     {
                       feedbackTitle === 'Coverage Area' && visible === true &&
                       <div>
                         {this.props.history.push({
                           pathname: './home',
                           state: { feedbackTitle: feedbackTitle,  lat: 39.4817, long: -106.0384 }
                           })}
                       </div>
                     }
                     {
                          feedbackTitle === 'Forecast Accuracy' && visible === true &&
                          <div>
                          {this.forecastView()}
                          </div>
                     }
                     {/* {
                       feedbackTitle === 'Lottery flow' && visible === true &&
                       <div>
                         {this.lotteryView()}
                       </div>
                     } */}

                 </List.Item>
                )}
              />
               <div style={{marginTop: 20}}>
               <a onClick={() => this.renderCancelMonthly(true)} style={{display: 'flex', justifyContent: 'center'}}>Learn more about "Give Feedback, Get Skis"</a>
               {/* Learn more about "Give Feedback, Get Skis" */}
               </div>
              {subsCancelMonth === true && visiblelottery === true &&
               <Modal
               title="Feedback for Skis"
               visible={this.state.visiblelottery}
               
               onOk={this.handleLotteryok}
               onCancel={this.handleLottery}
                 >
              <Card>
                <div>
                <p>On March 1st, 2021, these brand new skis will belong to a lucky shredder who gives us feedback to help improve Snow Intel!</p>  

<p>This raffle will randomly select one user from the 25% of feedback submissions deemed most helpful. The most helpful feedback will reference experiences using Snow Intel and how it helped you or could have been improved. Even if you submit feedback during a free trial, you can be entered in the lottery. You can earn additional submissions for additional helpful pieces of feedback, but are limited to five submissions.</p>

The winner will be emailed using the email address associated with their account and has 30 days to claim his/her price.  So go get after it and tell us what you think.
</div>
              </Card>
              </Modal>
              }
              {confirmSubmit === true &&
       <div style={styles.alertStyle}>
       <Modal
        title="Feedback Received!!"
        visible={this.state.confirmSubmit}
        footer={null}
        // onOk={this.handleAdd}
        onCancel={this.submitConfirm}
        // okText='Send Feedback'
        cancelText='Continue'
        >
          <div>
          <div>You've been entered into our Give Feedback, Get Skis Lottery! Each piece of feedback gives you an additional entry, up to five! The winner will be randomly drawn from the 25% of feedback deemed 'most helpful'.<br /></div>

<div>Thanks for helping us to improve Snow Intel!</div>

          </div>
        </Modal>
         </div>
      } 
             
                {/* </Radio.Group> */}
                    </Card>
                    </div>
                 </Card>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
  const { location, locationLoading } = state.locationData 
  return {
    location,
    locationLoading,
  }
}

const mapDispatchToProps = {
  getLocation, 
}
export default Form.create()(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(FeedbackScreen));

import React, { Component } from 'react';
import { Spin } from 'antd';
import firebase from '../../common/firebase';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';

import { AuthService , TemplateService} from '../auth/auth.service';
import * as userApi from '../../api/users';
import moment from "moment";
import { connect } from 'react-redux';
import { getLocation } from '../../actions/locationAction';
import Email_Style from './email_btn.css';

// This is our firebaseui configuration object
const uiConfig = ({
    // signInSuccessUrl: '/',
	signInFlow: "popup",
    signInOptions: [
		{provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID},
		{
            provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
            signInMethod: firebase.auth.EmailAuthProvider.EMAIL_PASSWORD_SIGN_IN_METHOD
        }
		
    ],
    // credentialHelper: firebaseui.auth.CredentialHelper.NONE,
    credentialHelper: 'none',
    tosUrl: '/', // This doesn't exist yet
    callbacks: {
        signInSuccessWithAuthResult: function (authResult, redirectUrl) {
            var user = authResult.user;
            // console.log("signInSuccessWithAuthResult user",user,authResult)
            if (user) {
                var currentUser = user;
				var g_auth = 0;
				
				try{
				user.providerData.forEach(function(providerData) {
					console.log("Signed in with "+providerData.providerId);
						if (providerData.providerId == "google.com"){
							g_auth = 1;
						}
	
				})} catch {console.log("Going To Erroneously Send Verification Email")}

                AuthService.setCurrentUser(user);
                localStorage.setItem('currentUserUid', user.uid);
                localStorage.setItem('currentUserAuth', JSON.stringify(user));
                var currentUserInfo = {
                    email: currentUser.email,
                    username: currentUser.displayName,
                    lastlogin: currentUser.metadata.lastSignInTime,
                    createdAt: currentUser.metadata.creationTime,
                    emailVerified: user.emailVerified
                }
				
				console.log("BBB:",  user.metadata.creationTime)
				console.log("CCC:",  user.metadata.lastSignInTime)
				console.log("DDD:", (user.metadata.creationTime == user.metadata.lastSignInTime))
				console.log("EEE:", (user.metadata.creationTime === user.metadata.lastSignInTime))

					var paymentInfo = {
						paymentDetails: null,
						interval: null,
						paymentMethod: null,
						status: "Free",
						subscriptionStartDate: parseInt((moment(new Date(), "MM/DD/YYYY HH:mm").valueOf()) / 1000),
						subscriptionEndDate: parseInt((moment(moment().add(7, 'days').calendar(), "MM/DD/YYYY HH:mm").valueOf()) / 1000),
						subsId: null
					}
					var pref = {
                        stake: "Hd",
                        detail: "St"
                    }
				
                if (authResult.additionalUserInfo.isNewUser ||(user.metadata.creationTime === user.metadata.lastSignInTime)) {
                    					
                        	if(g_auth == 0){
                    user.sendEmailVerification();
					}	
					
						userApi.createUser(currentUser.uid, currentUserInfo, paymentInfo, pref)						
						
                        let options = {
                            mode :'cors',  //tried both with and without
                            method: 'GET',
                            credentials: 'include',
                            cache: 'no-cache',
                        };
                        
						fetch(`https://email-add.glitch.me/email/${currentUserInfo.email}`)
                                  
                     
                    // return true;
				
                }
                else {
                    // !authResult.additionalUserInfo.isNewUser &&
                    // This is an existing user, show them a welcome back screen.
                    console.log("currentUserInfo", currentUserInfo)
                    userApi.updateUser(currentUser.uid, currentUserInfo, paymentInfo, pref).then((res) => {
                        window.location = '/home';
                    })

                }
            }
        },
        uiShown: function () {
            // The widget is rendered.
            // Hide the loader.
            document.getElementById('loader').style.display = 'none';
        },
        signInFailure: function (error) {
            // console.log("signInFailure",error)
        }
    }
})


// on auth state changes
firebase.auth().onAuthStateChanged(function(user) {
    // console.log("firebaseUI onAuthStateChanged",user)
    // on auth state change function
    if (user) {
        let interval = null;
        interval = setInterval(() => {
            let handleData = user.reload().then(
                () => {
                    if (interval && user.emailVerified) {
                        clearInterval(interval);
                        interval = null;
                        // resolve(user);
                        AuthService.setCurrentUser(user);
                        localStorage.setItem('currentUserProfile', user);
                        localStorage.setItem('currentUserAuth', JSON.stringify(user));
                        TemplateService.setVerify(user.emailVerified);
                        // console.log("sendEmailVerification user", user)
                    }
                }, error => {
                    if (interval) {
                        clearInterval(interval);
                        interval = null;
                        // console.log('registerUserAndWaitEmailVerification: reload failed ! ' + error.message + ' (' + error.code + ')');
                        // console.log("sendEmailVerification1 error", error)
                    }
                }
            );
        }, 1000);
        var currentUser = user;
        AuthService.setCurrentUser(user);
        localStorage.setItem('currentUserUid', user.uid);
        localStorage.setItem('currentUserAuth', JSON.stringify(user));
        let currentUserInfo={
            email:currentUser.email,
            username:currentUser.displayName,
            lastlogin:currentUser.metadata.lastSignInTime,
            createdAt:currentUser.metadata.creationTime,
            emailVerified:user.emailVerified
        }
        console.log(currentUserInfo,"currentUserInfo")
        let subs = localStorage.setItem('currentUserInfo', JSON.stringify(currentUserInfo))    
		if(user.metadata.creationTime === user.metadata.lastSignInTime){
		var paymentInfo = {
						paymentDetails: null,
						interval: null,
						paymentMethod: null,
						status: "Free",
						subscriptionStartDate: parseInt((moment(new Date(), "MM/DD/YYYY HH:mm").valueOf()) / 1000),
						subscriptionEndDate: parseInt((moment(moment().add(7, 'days').calendar(), "MM/DD/YYYY HH:mm").valueOf()) / 1000),
						subsId: null
					}
					var pref = {
                        stake: "Hd",
                        detail: "St"
                    }
			userApi.createUser(currentUser.uid, currentUserInfo, paymentInfo, pref)						

		}
	} else {
        localStorage.setItem('currentUserUid', null);
        localStorage.setItem('currentUserAuth', null);
        AuthService.setCurrentUser(null);
    }
});


class StartFirebaseUI extends Component {

    constructor(props) {
        super(props);
        this.state = {
            currentUserUid: null,
            paymentStatus: null
        }
    }

    // componentDidMount() {
    //     console.log(this.props,'did mount')
    //     this.setState({
    //       currentUserUid:(localStorage.getItem('currentUserUid'))
    //     },()=>{
    //       this.props.getLocation(this.state.currentUserUid)
    //     });
    // }

    // componentWillReceiveProps(nextProps) {
    //     console.log(nextProps,'nextProps')
    //     this.setState({currentUserUid:(localStorage.getItem('currentUserUid'))});
    //     if(nextProps && nextProps.location && nextProps.location.paymentInfo){
    //         this.setState({
    //             paymentStatus: nextProps.location.paymentInfo.status
    //         })
    //     }
    // }

    render() {
        return (
            <div>
                <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()}  style = {Email_Style}/>

                <div id="loader" style={{ fontWeight: 'bold', fontSize: 18, textAlign: 'center' }}>
                    <Spin tip="Loading..." size="large" />
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const { location, locationLoading } = state.locationData // Reducer Name
    return {
        location,// Reducer variable
        locationLoading,
    }
}

const mapDispatchToProps = {
    getLocation, //Action
}
export default
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(StartFirebaseUI);

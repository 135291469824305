import React, {Component,useEffect, useState} from 'react';
import {Button, Card, List, message, Modal, Radio, Spin} from 'antd';
import {Elements, StripeProvider} from 'react-stripe-elements';
import {Link} from "react-router-dom";
import styles from './subscription.Style';
import {getLocation} from '../../actions/locationAction';
import {connect} from "react-redux";
import moment from "moment";
import * as subscriptionAPI from "../../api/subscriptions";
import CardForm from '../payment/components/CardForm';

const payment = [
    {
        plan: 'Annual Subscription',
        description: '$15/year(Recurring, once per Year)',
        radioChecked: '1'
    },
    {
        plan: 'Monthly Subscription',
        description: '$5/month(Recurring, once per Month)',
        radioChecked: '2'
    }
]

class SubscriptionScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            subsBuy: false,
            subsBuyMonthly: false,
            buttonView: true,
            subsCancelYear: false,
            subsCancelMonth: false,
            visible: true,
            cancelmodal: false,
            cancelSuccess: false,
            subsCancelSuccess: false,
            cancelMonthSuccess: false,
            cancelSubs: false,
            subsPayment: false,
            value: null,
            paymentCard: false,
            paymentInfo: null,
            enableCancelSubscription: false,
            freeTrial: false,
            currentSubscription: false,
            noSubscription: false,
            expiryDate: null,
            price: 0,
            cardHistory: null,
            cardSuccess: true,
            stripe: window.Stripe('pk_live_xhiKDPso7K2SwVib71zBvJ3T00yKMghNJL'),
            chosenValue: null,
            listView: false
        }
    }

    componentDidMount() {
        // window.location.reload();
        // console.log("this.props.location", this.props)
        this.setState({
            currentUserUid: (localStorage.getItem('currentUserUid')),
            cardHistory: this.props
        }, () => {
            this.props.getLocation(this.state.currentUserUid)
        });
      
    }

    componentWillReceiveProps(nextProps) {
        // console.log("nextProps", nextProps, nextProps.location)
        this.setState({
            currentUserUid:(localStorage.getItem('currentUserUid')),
            // currentUser:JSON.parse(localStorage.getItem('currentUserAuth'))
          });
        if (nextProps.location && nextProps.location.hasOwnProperty('paymentInfo') && nextProps.location.paymentInfo !== null) {
            this.setState({
                paymentInfo: nextProps.location.paymentInfo
            }, () => {
                // console.log("paymentInfo", this.state.paymentInfo)
                this.setPaymentInfo(this.state.paymentInfo)
            });
        }
    }

    setPaymentInfo = (paymentInfo) => {
        let subStartDate = paymentInfo.subscriptionStartDate
        let subEndDate = paymentInfo.subscriptionEndDate
        let today = new Date();
        today = parseInt(moment(today).format("X"));
        let enableCancelSubscription = false
        let freeTrial = false
        let currentSubscription = false
        let noSubscription = false
        let expiration = false
        let expiryDate = moment.unix(subEndDate).format("MM/DD/YYYY")
        // console.log('today,end ', today, subEndDate)
        if (today < subEndDate) {
            // console.log('not expired')
            expiration = false;

        } else {
            // console.log('expired')
            expiration = true
        }
        if (paymentInfo.status === 'Activated' && expiration === true) {
            enableCancelSubscription = true
            currentSubscription = true
            freeTrial = false
            noSubscription = false
        } else if (paymentInfo.status === 'Activated' && expiration === false) {
            enableCancelSubscription = true
            currentSubscription = true
            freeTrial = false
            noSubscription = false
        } else if (paymentInfo.status === 'Cancelled') {
            enableCancelSubscription = false
            currentSubscription = true
            freeTrial = false
            noSubscription = true
        } else if (paymentInfo.status === 'Free' && expiration === false) {
            enableCancelSubscription = false
            currentSubscription = false
            freeTrial = true
            noSubscription = true
        } else if (paymentInfo.status === 'Free' && expiration === true) {
            enableCancelSubscription = false
            currentSubscription = false
            freeTrial = true
            noSubscription = true
        }
        this.setState({
            enableCancelSubscription: enableCancelSubscription,
            currentSubscription: currentSubscription,
            freeTrial: freeTrial,
            noSubscription: noSubscription,
            expiryDate: expiryDate
        }, () => {
            // console.log('status', {
            //     enableCancelSubscription: enableCancelSubscription,
            //     currentSubscription: currentSubscription,
            //     freeTrial: freeTrial,
            //     noSubscription: noSubscription,
            //     expiryDate: expiryDate
            // })
        })
    }

    renderSubs = (value) => {
        if (value !== null && value === true) {
            this.setState({
                subsBuy: value,
                buttonView: false
            })
        }
    }


    onChange = (e) => {
        this.setState({
            value: e.target.value
        });
    };

    renderBuySubs = (payment) => {
        this.setState({
            subsCancelYear: false,
            subsBuy: false,
            subsBuyMonthly: false,
            subsCancelMonth: false,
            buttonView: false,
            subsCancelSuccess: false,
            cancelSubs: false,
            subsPayment: payment

        })
    }


    applyOk = (value) => {
        if (value !== null) {
            this.setState({
                paymentCard: true
            })
        }
    };

    renderCancelMonthly = (cancelMonth) => {
        const {visible} = this.state
        if (cancelMonth !== null && cancelMonth === true) {
            this.setState({
                subsCancelMonth: cancelMonth,
                visible: true
            })
        }
    }

    handleOk = (e) => {
        try{
            let cancelInfo = {
                subId: this.state.paymentInfo.subsId,
                userId: this.state.currentUserUid,
                interval: this.state.paymentInfo.interval
            }
            // console.log("cancelInfo", cancelInfo)
            subscriptionAPI.cancelSubscriptionPlan(cancelInfo).then(response => {
                // console.log('response', response)
                message.success(response.message);
                this.setState({
                    visible: false,
                    cancelSuccess: true,
                    cancelmodal: true,
                    enableCancelSubscription: false,
                    currentSubscription: true,
                    freeTrial: false,
                    noSubscription: true
                },()=>{
                    this.props.getLocation(this.state.currentUserUid)
                });

            }).catch((err) => {
                message.error(err);
            })
        }catch (e) {
            message.error(e);
        }

    };

    handleCancel = (e) => {
        this.setState({
            visible: false,
        });
    };

    renderLoader() {
        return (
            <Spin 
            style={{ display: 'flex', justifyContent: 'center'}}
            spinning={this.props.locationLoading}>
            </Spin>
        )
    }

    renderPrice = (value) => {
        // console.log(value,'value price')
        let price
        if (value === '1' && value !== null) {
            price = 15
        } else if (value === '2' && value !== null) {
            price = 5
        }
        this.setState({
            price: price
        })
        return <div>
            Total = {price}
        </div>
    }

    renderAddCard = (value) => {
        const {cardHistory} = this.state
        return <section className="cart-area ptb-60">
        <div className="cart-totals">
            {this.StripeWrapper(value, cardHistory)}
            {/* <StripeWrapper choosenValue={value} history={cardHistory.history}/> */}
        </div>
    </section>
    }

    StripeWrapper = (value, cardHistory) => {
        const {stripe, listView} = this.state
        // for SSR
        if (typeof window == 'undefined') return;
        // for browser
            return (

        <StripeProvider stripe={stripe}>
            <Elements>
                <CardForm choosenValue={value} history={cardHistory.history} value={listView} />

            </Elements>
        </StripeProvider>
    );
    }

    render() {

        const {buttonView, subsPayment, paymentCard, subsCancelMonth, enableCancelSubscription, 
            currentSubscription, freeTrial, noSubscription, expiryDate, paymentInfo, card, chosenValue} = this.state
            return (
            <div style={{padding: '10px',overflowX:'hidden', marginBottom: '50px', width: '100vw', minHeight: '90vh'}}>
                <Card style={styles.card}>
                    <div style={{backgroundColor: 'white', height: 70, paddingTop: 15}}>
                        <div style={styles.header}>My Subscription</div>
                        <div style={styles.subheader}>
                            Change or Cancel your Subscription
                        </div>
                    </div>
                    <div className="centeredSub">
                        {currentSubscription === true && subsPayment !== true &&
                        <div style={styles.subsAction}>
                            Current Subscription Plan
                        </div>
                        }
                        {currentSubscription === true &&  subsPayment !== true &&
                        <Card style={styles.cardStyle}>
                            <div style={styles.subsType}>
                                {paymentInfo.interval === 'month' ?
                                    <p style={styles.pdiv}>Monthly</p> : <p style={styles.pdiv}>Annual</p>
                                }

                                {paymentInfo.interval === 'month' ?
                                    <p style={styles.pdiv}>$5/month</p> :
                                    <p style={styles.pdiv}>$15/year</p>
                                }

                                {paymentInfo.status === 'Cancelled' &&
                                    <p>Expires: {expiryDate}</p>
                                }

                                {paymentInfo.status !== 'Cancelled' &&
                                <div>
                                <div style={styles.subsType}>
                                    <p style={styles.pdiv}>Your subscription will be auto renewed every {paymentInfo.interval}</p>
                                    <p style={styles.pdiv}>Expires: {expiryDate} </p>
                                    </div>
                                    </div>
                                }

                            </div>
                        </Card>
                        }

                        {freeTrial === true && subsPayment !== true &&
                        <Card style={styles.cardStyle}>
                            <div style={styles.subsType}>
                            <div style={styles.subsFree}>
                            Current Subscription Plan
                            </div>
                                <p style={styles.pdiv}>7 Day Free Trial</p>
                                <p style={styles.pdiv}>$0/week</p>
                                <p style={styles.pdiv}>Expires: {expiryDate}</p>
                            </div>
                        </Card>
                        }

                        {subsPayment !== true &&
                        <div style={styles.subsAction}>
                            Available Actions:
                        </div>}
                        

                        {noSubscription === true && subsPayment !== true &&
                        <div style={{display:'flex',justifyContent:'center', paddingTop: 20}}>
                        <Button id="common-button-view" type="primary"
        className="preferance-btn" onClick={() => this.renderBuySubs(true)}
                          style={{display: 'flex', justifyContent: 'center'}}>Buy a Subscription</Button>
                   </div>
                        }

                        {enableCancelSubscription === true &&
                        <div style={{display:'flex',justifyContent:'center', paddingTop: 20}}>
                             <Button id="common-button-view" type="primary"
             className="preferance-btn" onClick={() => this.renderCancelMonthly(true)}
                               style={{display: 'flex', justifyContent: 'center'}}>Cancel Subscription</Button>
                        </div>
                        }

                        {subsPayment === true &&
                        <Card style={styles.locationStyle}>
                            <h2 style={styles.subsTitle}>Subscription Plan</h2>
                            {/* <div style={styles.subsTitle}>Subscription Plan</div> */}
                            <div style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center', width: '100%'}}>
                                <Radio.Group onChange={(e) => this.onChange(e)} value={this.state.value}>
                                    <List
                                        style={{backgroundColor: 'white'}}
                                        itemLayout="horizontal"
                                        dataSource={payment}
                                        renderItem={item => (
                                            <List.Item>
                                                <List.Item.Meta
                                                    title={item.plan}
                                                    description={item.description}
                                                />
                                                <Radio
                                                    style={styles.radioStyle} value={item.radioChecked}>
                                                </Radio>
                                            </List.Item>
                                        )}
                                    />
                                </Radio.Group>
                                {this.state.value !== null && this.state.value === '1' &&
                                <div style={{ fontSize: 15, fontWeight: 500, display: 'flex', justifyContent: 'center' }}>
                                    Total = $15.00
                                </div>
                                }
                                 {this.state.value !== null && this.state.value === '2' &&
                                <div style={{ fontSize: 15, fontWeight: 500, display: 'flex', justifyContent: 'center' }}>
                                    Total = $5.00
                                </div>
                                }
                                 {this.state.value !== null && 
                                <div>
                                     {this.renderAddCard(this.state.value)}
                                </div>
                                }
                            </div>
                        </Card>
                        }
                <div>
                    {this.renderLoader()}
                </div>
                {
                    subsPayment === false &&
                    <div style={{display: 'flex', justifyContent: 'center', paddingTop: 20}}>
                   <Button type="primary"
             className="preferance-btn"
             id="common-button-view"
             style={{width:'150px'}}
                    onClick={()=> this.props.history.push('./changePassword')}
                    >
                     Change Password
                    </Button>
                    </div>
                }
                
                    </div>
                </Card>
                {subsCancelMonth === true &&
                <Modal
                    title="Cancelling Subscription"
                    visible={this.state.visible}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    okText='Confirm'
                    cancelText='Dismiss'
                >
                    <div style={styles.inputFeedback}>
                        You will retain access to your account for the duration of your current subscription.
                        We will not attempt to bill you again.Please leave feedback if we can make Snow Intel a better
                        experience.
                    </div>
                    <a href='./feedback' style={{display: 'flex', justifyContent: 'center'}}>Give Feedback Here</a>
                </Modal>
                }
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const {location, locationLoading} = state.locationData // Reducer Name
    return {
        location,// Reducer variable
        locationLoading,
    }
}

const mapDispatchToProps = {
    getLocation, //Action
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SubscriptionScreen);

import {combineReducers} from 'redux'
import LocationReducer from './locationReducer'
import ForecastReducer from './forecastReducer'

const rootReducer = combineReducers({
    locationData: LocationReducer,
    forecastData: ForecastReducer,
})

export default rootReducer

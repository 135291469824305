import React, { Component } from "react"
import { Route, withRouter } from "react-router-dom";

import { HeaderComponent } from './Header';
import { SideMenu } from './SideMenu';
import { Layout } from 'antd';

const {Sider, Content} = Layout

class PrivateLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: false,
      currentUserUid: null
    }
}

componentDidMount(){
    this.setState({currentUserUid:JSON.parse(localStorage.getItem('currentUserAuth'))});
}

componentWillReceiveProps(nxtprops) {
  this.setState({currentUserUid:JSON.parse(localStorage.getItem('currentUserAuth'))});
}

onCollapse = (collapsed) => {
    this.setState({ collapsed });
}

onMobileCollapse = (collapsed) => {
  if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
    this.setState({ collapsed });
   }
}

renderView = () =>{
  return (
    <Layout>
      <Layout>
        
        <HeaderComponent/>
        <Content>
          <Route {...this.props.screen} />
        </Content>
      </Layout>
      
      <Sider
      breakpoint="lg"
      collapsible collapsed={this.state.collapsed} onCollapse={this.onCollapse}
        // collapsed={this.state.collapsed}
        collapsedWidth="0"
        // onCollapse={(collapsed, type) => {
        // }}
      >
   
       <SideMenu {...this.props} onchange={()=>this.onMobileCollapse}/>
      </Sider>
    </Layout>
)
}

renderPermission = () => {
  return (
    <div>
      No Permission
    </div>
  )
}
  
  render() {
    const {currentUserUid} = this.state
    if (currentUserUid !== null) {
      return this.renderView()
    }
    else {
      return this.renderPermission()
    }
  }
}

export default withRouter(PrivateLayout);

export default {
    header:{
        display: 'flex',
        justifyContent: 'center',
        color: 'black',
        fontSize: 20,
        fontWeight: 500
    },
    card:{
        // width: 1320,
        height: 670,
    },
    locationStyle: {
        display: 'flex',
        // flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        // marginLeft: 70
    },
    listStyle: {
        marginTop: 10,
        // background:'red' 
        // marginRight: 30
    },
    location: {
        display: 'flex',
        justifyContent: 'center',
        color: 'black',
        paddingBottom: 20,
        fontSize: 18,
        fontWeight: 500,
        marginLeft: 5
    },
    picker:{
        display: 'flex',
        marginTop: 10,
        justifyContent: 'center',
        marginBottom: 20
    },
    pickerText:{
        display: 'flex',
        justifyContent: 'center',
        fontSize: 13,
        fontWeight: 600,
        marginTop: 10,
    },
    dropdownStyle: {
        display: 'flex',
        justifyContent: 'center',
        marginBottom: 20
    },
    inputFeedback: {
        display: 'flex',
        justifyContent: 'center',
        marginBottom: 10
    }
    
}
import React, {Component} from 'react';
import {Card, Carousel, Button} from 'antd';
import styles from './landing.Style';
import login from '../../themes/assets/images/login.jpg';

class LandingScreen extends Component {
    constructor(props){
        super(props);
        this.state = {
           
        }
}
    onChange = (a, b, c) => {
                console.log(a, b, c);
        }

 subsNow =(value)=> {
   if(value !== null && value === true){
       this.props.history.push('./home')
    }
}
 subsExists=(subs) => {
    if(subs !== null && subs === true){
        this.props.history.push('./home')
     }
 }
        
 render(){
    return(
  <div class="container">
  <img src={login} alt="Snow" width="100%"/>
  <div class="centered">
      <Card style={styles.subsCard} id="modal-card">
            <Carousel style={{ width: '100%', height: '100%'}} afterChange={() => this.onChange}>
            <div style={styles.subsText}>
                <p style={styles.subsText}>See the Snow Layers:
                Identify Crusts
                Evaluate Snow Quality
                Anticipate Melt-Freeze </p>
                </div>
                <div>
                <p style={styles.subsText}>Recall Locations:
                Add "Off-the-Grid" location to your account quickly.Privately recall later.
                </p>
                </div>
                <div>
                <p style={styles.subsText}>Compare and Conquer:
                Swipe to quickly compare locations and find the best conditions
                </p>
                </div>
           </Carousel>
            <p></p>
            <p style={styles.subsText}> Try 1 Month for Free.Then $15/year or $5/month. </p>
               
            <Button
            id="common-button-view"
            onClick={()=> this.subsNow(true)}>
                Subscribe Now
            </Button>
            <Button
            id="common-button-view"
              onClick={()=> this.subsExists(true)}
            >
                I have a Subscription
            </Button>
      </Card>
  </div>
</div>
            
    )
}
}

export default LandingScreen;
import React, { Component } from "react"
import { Route } from "react-router-dom"

class PublicLayout extends Component {
  render() {
    return (
      <div>
          <Route {...this.props.screen} />
      </div>
    )
  }
}

export default PublicLayout;

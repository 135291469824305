import React from 'react';
import {Routes} from './containers/navigation/Routes';
import {StripeProvider} from 'react-stripe-elements';
import './App.css';
import firebase from './common/firebase';
import reducers from './reducers/index';
import {Provider} from 'react-redux'
import {createStore, applyMiddleware} from 'redux'
import thunk from 'redux-thunk'
import 'mapbox-gl/dist/mapbox-gl.css';

const createStoreWithMiddleware = applyMiddleware(thunk)(createStore)
const key = "pk_live_xhiKDPso7K2SwVib71zBvJ3T00yKMghNJL"
// pk_test_6pRNASCoBOKtIshFeQd4XMUh
 
class App extends React.Component{

  render(){
      return (
        <div>
          <Provider store={createStoreWithMiddleware(reducers)}>
            <StripeProvider apiKey={key}>
              <Routes/>
            </StripeProvider>
          </Provider>
        </div>
      );
  }
}
export default App;

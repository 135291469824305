import { Subject } from 'rxjs';

const subject = new Subject();
const verify = new Subject();

export const AuthService ={
    setCurrentUser: user => subject.next({ currentUser: user }),
    clearUser: () => subject.next(),
    getCurrentUser: () => subject.asObservable()
};

export const TemplateService ={
    setVerify: email => verify.next({ verifiedEmail: email }),
    clearVerify: () => verify.next(),
    getVerify: () => verify.asObservable()
};

import firebase from '../common/firebase';
import {collectionConfig} from './collectionName';

const db= firebase.firestore();

export function updateUserFeedback(userId,prefObject,objectName) {

    // let document = {[objectName]:prefObject}
    //
    // console.log("document",document,userId)

    try {
        // for storing with userid
        //  db.collection(collectionConfig.FEEDBACK).doc(userId).set(document, { merge: true });
        db.collection(collectionConfig.FEEDBACK).add(prefObject);
    }catch (err) {
        console.log("updateUserFeedback"+ err);
    }
}

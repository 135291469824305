import React, {Component} from "react";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import n from "./Route.Names";
import UsersHomeScreen from "../dashboard/UsersHome.Screen";
import LocationScreen from '../location/location.Screen';
import PreferencesScreen from '../preferences/preferences.Screen';
import HomeScreen from '../home/home.Screen';
import FeedbackScreen from '../feedback/feedback.Screen';
import SubscriptionScreen from '../subscription/subscription.Screen';
import PaymentsScreen from '../payment/payment.Screen';
import AddCard from '../payment/components/AddCard';
import PrivateLayout from '../layouts/privateLayout';
import PublicLayout from '../layouts/publicLayout';
import Login from '../auth/login';
import LandingScreen from '../auth/landing.Screen';
import {AuthService} from '../auth/auth.service';
// import VerifyEmailModal from '../../common/verifyEmailModal';
import firebase from "../../common/firebase";
import ChangePasswordScreen from '../change-password/ChangePassword.Screen';


function PublicView (props) {
  return (
    <PublicLayout screen={props}/>
  )
}



 function PrivateView (props) {
  return (
      (props.currentUser && (props.currentUser.emailVerified != null) && props.currentUser.emailVerified == true) &&
    <PrivateLayout screen={props}/>
 );
}


export class Routes extends Component  {

    constructor(props){
        super(props);
        this.state = {
         currentUser:null
        }
    }

    componentDidMount(){
        AuthService.getCurrentUser().subscribe((user)=>{
            // console.log("PrivateView onAuthStateChanged", user)
            if (user&& user.currentUser){
                this.setState({currentUser:user.currentUser});
            }
        })
    }

    componentWillReceiveProps(newsprops){
        AuthService.getCurrentUser().subscribe((user)=>{
            console.log("componentWillReceiveProps onAuthStateChanged", user)
            if (user&& user.currentUser){
                this.setState({currentUser:user.currentUser});
            }
        })
    }

    renderView = () => {
        return (
            <Router>
              <Switch>
                <PublicView exact path={n.START} component={Login} />
                <PublicView exact path={n.LANDING} component={LandingScreen} />
                <PrivateView exact path={n.HOME} component={UsersHomeScreen} currentUser={this.state.currentUser}/>
                <PrivateView exact path={n.CHANGE_PASSWORD} component={ChangePasswordScreen} currentUser={this.state.currentUser}/>
                {/* <PrivateView exact path={n.SEARCH_LOCATION} component={HomeScreen}/> */}
                <PrivateView exact path={n.LOCATION} component={LocationScreen} currentUser={this.state.currentUser}/>
                <PrivateView exact path={n.PREFERENCE} component={PreferencesScreen} currentUser={this.state.currentUser}/>
                <PrivateView exact path={n.USERSHOME} component={HomeScreen} currentUser={this.state.currentUser}/>
                <PrivateView exact path={n.SUBSCRIPTION} component={SubscriptionScreen} currentUser={this.state.currentUser}/>
                <PrivateView exact path={n.PAYMENT} component={PaymentsScreen} currentUser={this.state.currentUser}/>
                <PrivateView exact path={n.ADD_CARD} component={AddCard} currentUser={this.state.currentUser}/>
                <PrivateView exact path={n.FEEDBACK} component={FeedbackScreen} currentUser={this.state.currentUser}/>
                {/* <PrivateView exact path={n.FEEDBACKLOCATION} component={UsersHomeScreen} currentUser={this.state.currentUser}/> */}
                </Switch>
                </Router>
        )
    }

  render () {
      return (
          this.renderView()
      )
  }
}

export default Routes

import React, { Component } from 'react';
import moment from 'moment'
import { Button, Card, DatePicker,Alert} from 'antd';
import styles from './ChangePassword.Style';
import ChangePasswordModal from '../../common/ChangePasswordModal';


class ChangePasswordScreen extends Component {
    constructor(props){
        super(props);
        this.state = {
            name: null,
            showPassAlert:false
        }
    }

    componentDidMount(){

    }



    renderPasswordAlert(){
        return (
            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <Alert
                    message="Password Changed"
                    type="success"
                    showIcon
                />
            </div>
        )

    }

    handleClick(value,alertValue){
        console.log("handleClickenableForgotWindow",value,alertValue);
        this.setState({showPassAlert:alertValue});
    }


    render(){
        const {showPassAlert} = this.state;

        return (
            <div className="card-modal" style={{padding: '10px' }}>
                <Card style={styles.card}>
                    <h3 style={{display:'flex',justifyContent:'center',fontWeight:'bold'}}>Change Your Password</h3>
                    {showPassAlert&&
                    this.renderPasswordAlert()
                    }
                   <ChangePasswordModal screen="changePassword" onChangePassCallBack={(val,alertValue)=>this.handleClick(val,alertValue)}/>
                </Card>
            </div>
        )
    }
}

export default ChangePasswordScreen;

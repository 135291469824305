export default {
    cardPosition: {
        marginTop: 20,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    logoPosition: {
        display: 'flex',
        justifyContent: 'center',
        // marginLeft: 45
    },
    signupStyle: {
        textTransform: 'uppercase',
        fontSize: 16,
        display: 'flex',
        justifyContent: 'center',
        marginLeft: 45
    },
    emailDivStyle: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'center',
        marginBottom: 10
    },
    pwdDivStyle: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'center',
    },

    emailTextStyle: {
        textTransform: 'uppercase',
        marginBottom: 5
    },

    passwordTextStyle: {
        textTransform: 'uppercase',
        marginBottom: 5
    },
    passworBoxStyle: {
        cursor: 'pointer',
        width: '150%', 
        height: '50%'
    },
    btnStyle: {
        margin: 10,
        textTransform: 'uppercase',
        display: 'flex',
        justifyContent: 'center',
        // alignItems:'center'
        marginLeft: 105
    },
    card:{
        // width: 1320,
        height: 670,
    },
    confirmStyle: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: 40,
        width: 267,
        left: 500,
        marginTop: 20
        // top: 51,
        // position: 'absolute',
        // left: 8,
      }

}
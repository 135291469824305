import React, { Component } from 'react';
import { Icon, Popconfirm } from 'antd';
import { withRouter } from "react-router-dom";
import n from '../navigation/Route.Names';
import Style from './styles';
import firebase from 'firebase';

export class Logout extends Component {


   async clickLogout() {
       localStorage.clear();
       await  firebase.auth().signOut()
       await this.props.history.push({
            pathname: n.START
        })
    }

    render() {
        return (
            <div>
                <Popconfirm title="Are you sure you want to logout" onConfirm={() => this.clickLogout()}
                    okText="Yes" cancelText="No">
                    <div style={Style.iconStyle}>
                        <Icon style={Style.logouticon} type="poweroff" />
                        <span style={Style.textStyle}>Logout</span>
                    </div>
                </Popconfirm>
            </div>
        );
    }
}

export default withRouter(Logout);

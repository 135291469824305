export default {
    container: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#F5FCFF',
        flexDirection: 'column',
   },
      card:{
        // width: 1320,
        height: 670,
    },
    sectionStyle: {
        width: "100%",
        height: "400px",
        backgroundColor: 'red'
        // backgroundImage: "url(" + { login } + ")"
      },
    subsCard: {
        backgroundColor: 'black',
        height: 350,
        width: 500
    },
    subsText: {
        color: 'white'
    }
    
}
import React, { Component } from 'react';
import moment from 'moment'
import { Button, Card, DatePicker, Spin, Icon, Slider, Carousel  } from 'antd';
import { fromJS } from 'immutable';
import { json as requestJson } from 'd3-request';
import _ from 'lodash';
import Iframe from 'react-iframe'
import { getLocation } from '../../actions/locationAction';
import { connect } from 'react-redux';
import grass from '../../themes/assets/images/grass.png';
import skating from '../../themes/assets/images/ski_w.png';
import trekking from '../../themes/assets/images/trekking.png';
import skateboarding from '../../themes/assets/images/skateboarding.png';
import angle from '../../themes/assets/images/angle.png';
import compass from '../../themes/assets/images/compass.png';
import height from '../../themes/assets/images/height.png';
import tree from '../../themes/assets/images/tree.png';
import styles from './location.Style';
import CarouselSlider from 'react-slick';
import * as angles from 'angles';
import { async } from 'q';
import {hourlyUrlData} from '../../services/snowIntelAPIs';

const { RangePicker } = DatePicker;

const settings = {
    //   dots: true,
    fade: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
};
const timeSettings = {
    //   dots: true,
    //   infinite: true,
    //   speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
}

const marks = {
    0: '0',
    24: '+1d',
    48: '+2d',
    72: '+3d',
    96: '+4d',
    120: '+5d'
};


const snowMarks = {
    0: '0',
    24: '+24h',
    48: '+48h'
};


const sampleHardness = [
  1, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5,  5, 5, 5, 5, 5, 5, 5, 5, 5
]

// const sampleHardness = [
//     1, 5, 5, 5, 5, 5, 5, 5, 5, 5,
//   ]
  

// const sampleHardness = [
//   1, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5,  5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5,  5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5,  5, 5, 5, 5, 5, 5, 5, 5, 5 
// ]

const iframe = '<iframe https://avy-forecast-zone-finder.glitch.me/get_hourly/39.48156000000006/-106.06666999999999 id="iframe" onload="access()"></iframe>'

class LocationScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: null,
            latitude: null,
            longitude: null,
            index: null,
            newDate: [],
            jsonResponse: null,
            // snowScale: false,
            scaleComp: null,
            snowStake: null,
            density: null,
            quality: null,
            hardness: null,
            temperature: null,
            preference: null,
            tabPreference: null,
            thickness: null,
            displaySnow: false,
            warningMsg: false,
            verticalInch: 11,
            sdDepth: null,
            btdDepth: null,
            skdDepth: null,
            dateValue: '',
            nextDate: '',
            prevDate: '',
            timesArray: [],
            currentUserUid: null,
            locationArr: [],
            dateFormat: '',
            dateChange: '',
            loading: false,
            currentDate: '',
            currentTimeOnly: '',
            dayFormat: '',
            timeFormat: '',
            weatherView: false,
            dateTimeView: true,
            hourView: true,
            hourData: '',
            snowView: false,
            grassView: false,
            angleData: '',
            elevationData: '',
            aspectData: '',
            canopyData: '',
            avalancheView: false,
            avalancheData: '',
            avalancheName: '',
            avalancheCarousel: [],
            tabKey: '3',
            responseStatus: '',
            avalancheIndex: '',
            disableRight: true,
            disableLeft: true,
            hourlyDays: 0,
            nextMonth: null,
            nextDay: null,
            nextDate: null,
            snowHour: 0,
            sildeValue: 0
        }
    }

    componentDidMount() {
        const { jsonResponse } = this.state
        this.setState({ preference: null, tabPreference: null })
        let zoneNames = [
            'Northern CO',
            'Northern CO',
            'Northern CO',
            'Central CO',
            'Central CO',
            'Central CO',
            'Central CO',
            'Southern CO',
            'Southern CO',
            'Southern CO',
            'Logan',
            'Ogden',
            'Salt Lake',
            'Uintas',
            'Provo',
            'Skyline',
            'Southwest',
            'Abajos',
            'Moab',
            'Grey’s River',
            'Teton',
            'Togwotee'
        ]
        this.setState({
            avalancheCarousel: zoneNames,
            currentUserUid: (localStorage.getItem('currentUserUid'))
        }, () => {
            this.props.getLocation(this.state.currentUserUid);
        });
        if (this.props && this.props.history && this.props.history.location && this.props.history.location.state) {
            // console.log(this.props,"444444888888")
            this.setState({
                // preference: this.props.location.state.preference,
                name: this.props.history.location.state.detail,
                latitude: this.props.history.location.state.lat,
                longitude: this.props.history.location.state.long,
                index: this.props.history.location.state.index
            }, () => {
                // console.log(this.state.latitude,"44444777",this.state.longitude,"44444")
                let arr = []
                let convertArr = []
                let dateArr = []
                let timeArr = []
                let convertDate
                //var today = new Date();
				var today = new Date('23 Mar 2021 00:00:00 GMT');
                let minutes = today.getMinutes();
                let initialDate = moment(today).format('hh:mm');
                if (minutes >= 0 && minutes <= 30) {
                    minutes = 0 + '0'
                } else if (minutes >= 30 && minutes <= 59) {
                    minutes = 30
                }
                initialDate = parseInt(initialDate);

                for (let i = 0; i <= 48; i++) {
                    let nextButton = moment(today).add(i, 'hour')
                    let betweenDates = moment(nextButton).format('dddd hh A\xa0\xa0(MM/DD)') // 'dddd MM/DD\xa0\xa0\xa0\xa0hh A'
                    let onlyDate = moment(nextButton).format('dddd MM/DD')
                    let onlyTime = moment(nextButton).format('hh A')
                    convertDate = moment.utc(nextButton).format('MM-DD[T]HH')
                    arr.push(betweenDates)
                    convertArr.push(convertDate)
                    dateArr.push(onlyDate)
                    timeArr.push(onlyTime)
                    // console.log('nextButton', nextButton, betweenDates)
                    this.setState({
                        timesArray: arr,
                        newDate: convertArr,
                        currentDate: dateArr,
                        currentTimeOnly: timeArr

                    }, () => {
                        if (i === 0) {
                            // console.log('vvvvvvvvvvvvvvvvvv')
                            this.jsonFunction(0)
                        }
                    })
                }
                this.setState({
                    dateValue: initialDate,
                    newDate: convertArr,
                    dateChange: convertArr[0]
                })

            })

        }
        if (jsonResponse !== null && jsonResponse !== undefined) {
            var result = Object.keys(jsonResponse).map(function (key) {
                return result = [key, jsonResponse[key]]
            });
        }
    }

    async componentWillReceiveProps(nextProps) {
        this.setState({ currentUserUid: (localStorage.getItem('currentUserUid')) });
        if (nextProps && nextProps.history && nextProps.history.location && nextProps.history.location.state) {
            this.setState({
                latitude: nextProps.history.location.state.lat,
                longitude: nextProps.history.location.state.long,
                index: nextProps.history.location.state.index
            })
        }
        if (nextProps && nextProps.location && nextProps.location.preference) {
            this.setState({
                preference: nextProps.location.preference.stake,
                tabPreference: nextProps.location.preference.detail,
                snowView: nextProps.location.preference.detail === 'St' ? true :  false,
                hourView: nextProps.location.preference.detail === 'St' ? false :  true,
                tabKey:  nextProps.location.preference.detail === 'St' ? '1' :  '3',
            }, () => { 
                setTimeout(() => {
                    this.getHourlyUrl()
                }, 500)
          
            })
        }
        if (nextProps && nextProps.location && nextProps.location.location) {
            this.setState({
                locationArr: nextProps.location.location
            })
        }
        let headers = new Headers();
        headers.append('Authorization', 'Basic ' + btoa('client' + ":" + 'PowwyWowwy'));
        let options = {
            mode: 'cors',  //tried both with and without
            method: 'GET',
            cache: 'no-cache',
            headers: headers
        };
        fetch(`https://api.snow-intel.com:8080/forecast/${this.state.latitude}/${this.state.longitude}/${this.state.dateChange}`, options).then((response) => response.json())
            .then((response) => {
                // console.log(this.state.latitude,"this.state.latitude", this.state.longitude, "this.state.longitude", this.state.dateChange)
                this.setState({
                    jsonResponse: response.data
                }, () => {
                    let arr = []
                    let convertArr = []
                    let dateArr = []
                    let timeArr = [] 
                    let convertDate
                   	//var today = new Date();
                var today = new Date('23 Mar 2021 00:00:00 GMT');
					let minutes = today.getMinutes();
                    let initialDate = moment(today).format('hh:mm');
                    if (minutes >= 0 && minutes <= 30) {
                        minutes = 0 + '0'
                    } else if (minutes >= 30 && minutes <= 59) {
                        minutes = 30
                    }
                    initialDate = parseInt(initialDate);

                    for (let i = 0; i <= 48; i++) {
                        let nextButton = moment(today).add(i, 'hour')
                        let betweenDates = moment(nextButton).format('dddd hh A\xa0\xa0(MM/DD)') // 'dddd MM/DD\xa0\xa0\xa0\xa0hh A'
                        // console.log(betweenDates,"betweenDates")
                        let onlyDate = moment(nextButton).format('dddd MM/DD')
                        // console.log(onlyDate,"onlyDate")
                        let onlyTime = moment(nextButton).format('hh A')
                        // console.log(onlyTime,"onlyTime")
                        // console.log(onlyTime,"onlyTime", onlyDate,"onlyDate")
                        convertDate = moment.utc(nextButton).format('MM-DD[T]HH')
                        arr.push(betweenDates)
                        dateArr.push(onlyDate)
                        timeArr.push(onlyTime)
                        convertArr.push(convertDate)
                        this.setState({
                            timesArray: arr,
                            newDate: convertArr,
                            currentDate: dateArr,
                            currentTimeOnly: timeArr
                        }, () => {
                            if (i === 0) {
                                this.jsonFunction(0)
                            }
                        })
                        // this.jsonFunction()
                    }
                    this.setState({
                        dateValue: initialDate,
                        newDate: convertArr,
                        // nextDate: nextArrow
                    })
                })
            }).catch(err => {
                console.log('forecast api error', err, err.response);
            })
    }

    jsonFunction = (value) => {
        const { jsonResponse, snowScale, scaleComp, latitude, longitude, index } = this.state
        // console.log(jsonResponse,"jsonResponse")
        if (jsonResponse !== null && jsonResponse !== undefined) {
            this.setState({
                scaleComp: jsonResponse
            })
            if (value === 0) {
                this.onOk(0)
                this.locationClick(index)
            }
            if (scaleComp !== null) {
                let densityValue = scaleComp.De
                let reverseDensity = _.reverse(densityValue)
                let qualityValue = scaleComp.Ql
                let reverseQuality = _.reverse(qualityValue)
                let hardnessValue = scaleComp.Hd
                let reverseHardness = _.reverse(hardnessValue)
                let temperatureValue = scaleComp.Te
                let reverseTemperature = _.reverse(temperatureValue)
                let snowStakeValue = scaleComp.HS
                let thicknessValue = scaleComp.Th
                let reverseThickness = _.reverse(thicknessValue)
                let depthBtd = scaleComp.BtD
                let depthSD = scaleComp.SD
                let depthSkd = scaleComp.SkD
				
				//console.log("BGG:", thicknessValue)
				
                // let newLat = this.state.jsonResponse.Sph[1]
                // let newLong = this.state.jsonResponse.Sph[0]
                this.setState({
                    density: reverseDensity,
                    quality: reverseQuality,
                    hardness: reverseHardness,
                    temperature: reverseTemperature,
                    snowStake: snowStakeValue,
                    thickness: reverseThickness,
                    sdDepth: depthSD,
                    btdDepth: depthBtd,
                    skdDepth: depthSkd,
                    // latCoordinates: newLat,
                    // longCoordinates: newLong

                }, () => {
                    if (this.state.latitude !== null && this.state.longitude !== null) {
                        this.setState({
                            displaySnow: true,
                            snowScale: true,
                            loading: false
                        })
                    }
                    else {
                        this.setState({
                            displaySnow: false,
                            snowScale: false,
                            loading: false,
                            grassView: true
                        })
                    }
                })
            }
        }
        // });
    }

    onChange = (value, dateString) => {
        // console.log(dateString, 'dateString',value,'value of time')
    }

    locationClick = async (data) => {
        const { tabKey } = this.state
        let avalanche
        this.setState({
            loading: true
        }, async () => {
            const { locationArr, snowHour, dateChange, tabKey } = this.state
            await locationArr.map(async (location, index) => {
                if (data === index) {
                    this.setState({
                        name: location.name,
                        latitude: location.coords[0].lat,
                        longitude: location.coords[1].lan,
                        // avalancheView: true

                    }, async () => {
                        let headers = new Headers();
                        headers.append('Authorization', 'Basic ' + btoa('client' + ":" + 'PowwyWowwy'));
                        let options = {
                            mode: 'cors',  //tried both with and without
                            method: 'GET',
                            cache: 'no-cache',
                            headers: headers
                        };
                        await fetch(`https://api.snow-intel.com:8080/forecast/${this.state.latitude}/${this.state.longitude}/${this.state.dateChange}`, options).then((response) => response.json())
                            .then((response) => {
                                  console.log(response, 'response11111',this.state.latitude,this.state.longitude)
                                this.setState({
                                    jsonResponse: response.data,
                                    responseStatus: response.status

                                }, () => {
                                    this.onOk(snowHour)
                                    if (this.state.hourData && this.state.hourView === true && tabKey === '3') {
                                        this.setState({
                                            hourView: true,
                                            weatherView: false,
                                            displaySnow: false,
                                            snowScale: false,
                                            dateTimeView: false,
                                            snowView: false,
                                            grassView: false,
                                            avalancheView: false
                                        }, () => {
                                            this.getHourlyUrl()
                                        })
                                    }
                                    if (this.state.avalancheName && this.state.avalancheView === true && tabKey === '4') {
                                        this.setState({
                                            hourView: false,
                                            weatherView: false,
                                            displaySnow: false,
                                            snowScale: false,
                                            dateTimeView: false,
                                            snowView: false,
                                            grassView: false,
                                            // tabKey: '4'
                                        }, () => {
                                            fetch(`https://avy-forecast-zone-finder.glitch.me/get_zone/${this.state.latitude}/${this.state.longitude}`, {
                                            }).then((response) => response.json())
                                                .then((response) => {
                                                    this.setState({
                                                        avalancheData: parseInt(response.ID),
                                                        // avalancheCarousel: tempArr,
                                                        nameofAva: response.Name,
                                                        avalancheIndex: response.ID,
                                                        avalancheName: `https://avy-forecast-zone-finder.glitch.me/?zone=${response.ID}`,
                                                        disableRight: true,
                                                        disableLeft: true
                                                    },()=>{
                                                        this.setState({
                                                            avalancheView: true
                                                        })
                                                    })
                                                }).catch(err => {
                                                    console.log('forecast api error', err, err.response);
                                                })
                                        })
                                    }

                                    // this.jsonFunction()
                                })
                            }).catch(err => {
                                console.log('forecast api error', err, err.response);
                            })

                    })
                }
                else {
                    this.setState({
                        snowScale: false,
                        displaySnow: false,
                        loading: true,
                        grassView: true
                    })
                }
            })
        })
    }

    onOk = (value, type) => {
        const { snowHour, sildeValue, timesArray } = this.state;
        let canopyView, newAspect, sliderSteps = value;
        this.setState({ 
            loading: true,
        }, () => {
            if (sliderSteps !== -1) {
                if (snowHour === 0 && sildeValue === 0 && value === timesArray.length - 1) {
                    sliderSteps = timesArray.length - 1
                } else if(snowHour === timesArray.length - 1 && sildeValue === timesArray.length - 1 && value === 0) {
                    sliderSteps = 0
                } else if (snowHour !== sliderSteps) {
                    if (sildeValue <= sliderSteps) {
                        sliderSteps = snowHour + 1
                    } else if (sildeValue > sliderSteps) {
                        sliderSteps = snowHour - 1
                    }
                    if (sliderSteps < 0) {
                        sliderSteps = timesArray.length - 1
                    } else if(sliderSteps > timesArray.length - 1) {
                        sliderSteps = 0
                    }
                }
                // sliderSteps = snowHour === sliderSteps ? sliderSteps : snowHour === sliderSteps snowHour + 1
                if(type === 'SnowHour') {
                    this.setState({
                        snowHour: sliderSteps
                    });
                } else {
                this.setState({
                    snowHour: sliderSteps,
                    sildeValue: value
                }, () => {
                    // console.log(this.state.snowHour,"snowHour")
                })
            }
            }

            let currentTime = this.state.timesArray[sliderSteps];
            // console.log(currentTime,"currentTime")
            let halfDate = this.state.currentDate[sliderSteps];
            let halfTime = this.state.currentTimeOnly[sliderSteps];
            // console.log(halfDate,"halfDate",halfTime,"halfTime")
            let currentFormat = this.state.newDate[sliderSteps];
            // console.log(currentFormat, "currentFormat")
            this.setState({
                dateFormat: currentTime,
                dateChange: currentFormat,
                dayFormat: halfDate,
                timeFormat: halfTime
            })
            // let utcTime = moment.utc(value).format()
            // let dateFormat = moment(value).format('MMM ddd h a');
            const { jsonResponse, snowScale, newDate } = this.state
            if (jsonResponse !== null) {
                this.setState({
                    snowScale: true,
                    dateChange: currentFormat
                }, () => {
                    let headers = new Headers();
                    headers.append('Authorization', 'Basic ' + btoa('client' + ":" + 'PowwyWowwy'));
                    let options = {
                        mode: 'cors',  //tried both with and without
                        method: 'GET',
                        cache: 'no-cache',
                        headers: headers
                    };
                    fetch(`https://api.snow-intel.com:8080/forecast/${this.state.latitude}/${this.state.longitude}/${this.state.dateChange}`, options, {
                        headers: {
                            'Accept': 'application/json',
                            'Authorization': 'Basic Y2xpZW50OlBvd3d5V293d3k='
                        },
                    }).then((response) => response.json())
                        .then((response) => {
                            this.setState({
                                jsonResponse: response.data,
                                scaleComp: response.data,
                            }, () => {
                                if (response.status !== "Error" && response.data !== undefined) {
                                    this.jsonFunction()
                                }
                                else {
                                    this.setState({
                                        displaySnow: false,
                                        snowScale: false,
                                        loading: false,
                                        grassView: true

                                    })
                                }
                            })
                        }).catch(err => {
                            console.log('forecast api error', err, err.response);
                        })
                })
            } else {
                this.setState({
                    warningMsg: false,
                    snowScale: false,
                    displaySnow: false,
                    loading: false,
                    grassView: true

                })
            }
        })
        let headers = new Headers();
        headers.append('Authorization', 'Basic ' + btoa('client' + ":" + 'PowwyWowwy'));
        let options = {
            mode: 'cors',  //tried both with and without
            method: 'GET',
            cache: 'no-cache',
            headers: headers
        };
        fetch(`https://api.snow-intel.com:8080/terrain/${this.state.latitude}/${this.state.longitude}`, options, {
            headers: {
                'Accept': 'application/json',
                'Authorization': 'Basic Y2xpZW50OlBvd3d5V293d3k='
            },
        }).then((response) => response.json())
            .then((response) => {
                if (response && response.data.Alt) {
                    let feetData = response.data.Alt * 3.28084
                    let angleFeet = Math.round(feetData)
                    // console.log(roundedValue,"roundedValue")
                    // let angleFeet = feetData.toFixed(2)
                    this.setState({
                        angleData: response.data.Slo,
                        elevationData: angleFeet,
                        aspectData: response.data.Asp,
                        canopyData: response.data.LAI
                    }, () => {
                        if (this.state.canopyData < .25) {
                            canopyView = 'None'
                        } else if (this.state.canopyData > .25 && this.state.canopyData < 1) {
                            canopyView = 'Some'
                        }
                        else {
                            canopyView = 'Dense'
                        }
                        if (this.state.aspectData) {
                            newAspect = angles.compass(this.state.aspectData)
                            // console.log(newAspect, "newAspect")
                        }
                        this.setState({
                            canopyData: canopyView,
                            aspectData: newAspect
                        })
                    })
                }
            }).catch(err => {
                console.log('forecast api error', err, err.response);
            })


    }

    densityView = () => {
        const { density, thickness, verticalInch, sdDepth, btdDepth, skdDepth, snowStake } = this.state
        // let reverseData = density.reverse()
        let skiingValue = sdDepth
        let hikerValue = Math.round(btdDepth)
        let densityLength = density.length
        let snowSpacing
        let height, snowDegree
        let Yaxis = verticalInch * 6
        let axisValue
        let skiingImage, walkingImage
		
		let stakeLegendValue = []
		let stakeLegendPosition = []
		var maxDepth = thickness.reduce((a, b) => a + b, 0);
		maxDepth = Math.round(maxDepth)
		
		const screen_height = window.innerHeight|| document.documentElement.clientHeight|| document.body.clientHeight;
		snowSpacing = Math.abs(screen_height - 450) / 20
		
		for (var i = 0; i < maxDepth; i++) {
			if ((i % 6) === 0){
		stakeLegendValue.push(i);
		stakeLegendPosition.push(i*snowSpacing);
			}
		}
		
		console.log(stakeLegendValue)
		console.log(stakeLegendPosition)
		
		
        if (density !== null && thickness !== null) {
										
						//console.log("MD", maxDepth)
			
            return (

                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: 'calc(250px - 47px)', border: '1px solid #333333' }}>
							
				 <div>
	 {stakeLegendValue.map((dataLV, index) => {
							 return(
                               <div key={index} style={{ flex: 1 }}>
                                   <div key={index} style={{ height: 0, marginLeft: '-120px', top: `${stakeLegendPosition[index] + 'px'}`, position: 'relative'}}>
									{dataLV}"
						 </div>
						 </div>
					)})}
					
					</div>		
				
                    {
									
                        density !== null && density.map((data, index) => {
                            let densityData = data
                            snowSpacing = Math.abs(screen_height - 450) / 20
                            snowDegree = null
                            skiingImage = ''
                            walkingImage = ''
                            
						var last_depth_array = []
						var last_depth = 0
						var current_depth = 0
						var current_depth_array = []
						
						//if first element, leave last depth set to 0
						if (index > 0) {
							last_depth_array = thickness.slice(0, index)
							
							last_depth = last_depth_array.reduce((a, b) => a + b, 0)
						}
						
						current_depth_array = thickness.slice(0, index+1)

						current_depth = current_depth_array.reduce((a, b) => a + b, 0)
							
						//if last element in array, set to length +1 to capture end point 
						
						if (skiingValue > maxDepth) {
							skiingValue = maxDepth - .05
						}
						//console.log("BFF", skiingValue)
						//console.log("MinL", last_depth)
						//console.log("MaxL", current_depth)

						
                        if ((current_depth > skiingValue && last_depth <= skiingValue)) {
							
						var percentLayer = (skiingValue - last_depth) / (current_depth - last_depth)
						//console.log("PL", percentLayer)
						
						var verticalAdjuster = 35 * (1 - percentLayer)
						verticalAdjuster = "-" + verticalAdjuster + "px"
							
                            skiingImage = <div style={{ marginRight: '-55px', marginTop: verticalAdjuster }}><img src={skating} alt="Skating" align="left" height='45' /></div>
                        }
							
							
                     
                            let densityThickness = thickness[index]
                            height = verticalInch * densityThickness
                            axisValue = 350 / Yaxis

                            let colors

                            if (densityData > 300) {
                                colors = "#333333"
                                // walkingImage =  <div><img src={man} alt="Man" align="left" width='100' height='24' /></div>
                            }
                            else if (densityData > 250 && densityData < 300) {
                                colors = "#666666"
                            }
                            else if (densityData > 200 && densityData < 250) {
                                colors = "#999999"
                            }
                            else if (densityData > 150 && densityData < 200) {
                                colors = "#cccccc"
                            }
                            else if (densityData < 150) {
                                colors = "#ffffff"
                            }
                            if (data !== null) {
                                return <div style={{ flex: 1 }}>

                                    <p style={{ backgroundColor: colors, width: 200, height: `${snowSpacing + 'px'}`, margin: 0 }}>

                                    </p>
                                    {/* <div style={{ height: 0, display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                                        {walkingImage}
                                    </div>*/
                                    <div style={{ height: 0, display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                                        {skiingImage}
                                    </div> }
                                </div>



                            }

                        })

                    }
                    <div>
                        {this.renderLoader()}
                    </div>
                    <div style={{ flex: 1 }}>
                        {maxDepth > 0 && snowStake !== null &&
                    <div style={{ height: 0, marginLeft: '-50px',position:'relative',top:30,fontSize:20,fontWeight:'bold',color:'#000' }}>
                                       {maxDepth}"
                                    </div>
                        }
                        <img src={grass} alt="Grass" align="left" width='200' height='64'
                    /></div>

                </div>

            )
        }
    }

    qualityValue = () => {
        const { quality, thickness, verticalInch, sdDepth, btdDepth, skdDepth, snowStake } = this.state
        let skiingValue = Math.round(sdDepth)
        let hikerValue = Math.round(btdDepth)
        let qualityLength = quality.length
        let snowSpacing
        let height, snowDegree, walkingImage, skiingImage
		const screen_height = window.innerHeight|| document.documentElement.clientHeight|| document.body.clientHeight
        return (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: 'calc(250px - 47px)', border: '1px solid #333333' }}>
                {
                    quality !== null && thickness !== null && quality.map((data, index) => {
                        let qualityData = data
                        snowSpacing = Math.abs(screen_height - 450) / 20
                        let colors
                        snowDegree = null
                        walkingImage = ''
                        skiingImage = ''
                        if (index === skiingValue) {
                            skiingImage = <div style={{ marginRight: '-45px' }}><img src={skating} alt="Skating" align="left" width='50' height='45' /></div>
                        }
                        if (index === hikerValue) {
                            walkingImage = <div style={{ marginRight: '-75px' }}><img src={trekking} alt="Trekking" align="left" width='50' height='45' /></div>
                        }
                        if (index === 0) {
                            snowDegree = 0
                        }
                        var eq = '';
                        if ((index % 6) === 0) {

                            eq = index;
                        }
                        if (index === eq) {
                            snowDegree = `${eq}"`
                        }

                        let qualityThickness = thickness[index]
                        height = verticalInch * qualityThickness

                        if (qualityData > 34) {
                            colors = "#333333"
                        }
                        else if (qualityData > 32 && qualityData < 34) {
                            colors = "#666666"
                        }
                        else if (qualityData > 30 && qualityData < 32) {
                            colors = "#999999"
                        }
                        else if (qualityData > 28 && qualityData < 30) {
                            colors = "cccccc"
                        }
                        else if (qualityData < 28) {
                            colors = "#ffffff"
                        }

                        if (data !== null) {
                            return <div style={{ flex: 1 }}>
                                <div style={{ height: 0, marginLeft: '-30px' }}>
                                    {snowDegree}
                                </div>
                                <p style={{ backgroundColor: colors, width: 200, height: `${snowSpacing + 'px'}`, margin: 0 }}>
                                    {/* {qualityData} */}
                                </p>
                                {/* <div style={{ height: 0, display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                                    {walkingImage}
                                </div>*/
                                <div style={{ height: 0, display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                                    {skiingImage}
                                </div>}
                            </div>

                        }

                    })
                }
                <div>
                    {this.renderLoader()}
                </div>
                <div style={{ flex: 1 }}>
                {snowStake > 0 && snowStake !== null &&
                    <div style={{ height: 0, marginLeft: '-40px',position:'relative',top:30,fontSize:20,fontWeight:'bold',color:'#000' }}>
                                        {snowStake}"
                                    </div>
                }
                    <img src={grass} alt="Grass" align="left" width='200' height='64'
                /></div>

            </div>

        )
    }

    hardnessValue = () => {
        const { hardness, thickness, verticalInch, sdDepth, btdDepth, skdDepth, snowStake } = this.state
        let height
        let Yaxis = verticalInch * 6
        let axisValue, skiingImage, walkingImage
        let hardnessLength = hardness.length
        let snowSpacing
        let snowDegree = ''
        let skiingValue = Math.round(sdDepth)
        let hikerValue = Math.round(btdDepth)
		
		let stakeLegendValue = []
		let stakeLegendPosition = []
		var maxDepth = thickness.reduce((a, b) => a + b, 0);
		maxDepth = Math.round(maxDepth)
		
		const screen_height = window.innerHeight|| document.documentElement.clientHeight|| document.body.clientHeight;
		snowSpacing = Math.abs(screen_height - 450) / 20
		
		for (var i = 0; i < maxDepth; i++) {
			if ((i % 6) === 0){
		stakeLegendValue.push(i);
		stakeLegendPosition.push(i*snowSpacing);
			}
		}
		
		console.log(stakeLegendValue)
		console.log(stakeLegendPosition)
		
        return (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: 'calc(250px - 47px)', border: '1px solid #333333' }}>
			
				 <div>
	 {stakeLegendValue.map((dataLV, index) => {
							 return(
                               <div key={index} style={{ flex: 1 }}>
                                   <div key={index} style={{ height: 0, marginLeft: '-120px', top: `${stakeLegendPosition[index] + 'px'}`, position: 'relative'}}>
									{dataLV}"
						 </div>
						 </div>
					)})}
					</div>
			
                {
					
                    hardness !== null && thickness !== null && hardness.map((data, index) => {
                        snowDegree = null
                        walkingImage = ''
                        skiingImage = ''

						
						var last_depth_array = []
						var last_depth = 0
						var current_depth = 0
						var current_depth_array = []
						
						//if first element, leave last depth set to 0
						if (index > 0) {
							last_depth_array = thickness.slice(0, index)
							
							last_depth = last_depth_array.reduce((a, b) => a + b, 0)
						}
						
						current_depth_array = thickness.slice(0, index+1)

						current_depth = current_depth_array.reduce((a, b) => a + b, 0)
							
						//if last element in array, set to length +1 to capture end point 
						
						if (skiingValue > maxDepth) {
							skiingValue = maxDepth - .05
						}
						//console.log("BFF", skiingValue)
						//console.log("MinL", last_depth)
						//console.log("MaxL", current_depth)

						
                        if ((current_depth > skiingValue && last_depth <= skiingValue)) {
							
						var percentLayer = (skiingValue - last_depth) / (current_depth - last_depth)
						//console.log("PL", percentLayer)
						
						var verticalAdjuster = 35 * (1 - percentLayer)
						verticalAdjuster = "-" + verticalAdjuster + "px"
							
                            skiingImage = <div style={{ marginRight: '-55px', marginTop: verticalAdjuster }}><img src={skating} alt="Skating" align="left" height='45' /></div>
                        }
					  
					  
					         if ((current_depth > skiingValue && last_depth <= skiingValue)) {
							
						var percentLayer = (skiingValue - last_depth) / (current_depth - last_depth)
						//console.log("PL", percentLayer)
						
						var verticalAdjuster = 35 * (1 - percentLayer)
						verticalAdjuster = "-" + verticalAdjuster + "px"
							
                            skiingImage = <div style={{ marginRight: '-55px', marginTop: verticalAdjuster }}><img src={skating} alt="Skating" align="left" height='45' /></div>
                        }
					  
					  
					  

                        let hardnessData = data
                        let hardnessThickness = thickness[index]
                        height = verticalInch * hardnessThickness
                        axisValue = 350 / Yaxis
                        let colors

                        if (hardnessData === 5) {
                            colors = "#333333"
                        }
                        else if (hardnessData === 4) {
                            colors = "#666666"
                        }
                        else if (hardnessData === 3) {
                            colors = "#999999"
                        }
                        else if (hardnessData === 2) {
                            colors = "#cccccc"
                        }
                        else if (hardnessData <= 1) {
                            colors = "#ffffff"
                        }

                        if (data !== null) {
                            return <div style={{ flex: 1 }}>
                                <p style={{ backgroundColor: colors, width: 200, height: `${snowSpacing + 'px'}`, margin: 0 }}>

                                    {/* {densityData} */}
                                </p>
                                {/* <div style={{ height: 0, display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                                    {walkingImage}
                                </div>*/
                                <div style={{ height: 0, display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                                    {skiingImage}
                                </div> }
                            </div>

                        }

                    })
                }
                <div>
                    {this.renderLoader()}
                </div>
                <div style={{ flex: 1 }}>
                {maxDepth > 0 && snowStake !== null &&
                    <div style={{ height: 0, marginLeft: '-40px',position:'relative',top:30,fontSize:20,fontWeight:'bold',color:'#000' }}>
                                       {maxDepth}"
                                    </div>
                }
                    <img src={grass} alt="Grass" align="left" width='200' height='64'
                /></div>

            </div>

        )
    }

    temperatureValue = () => {
        const { temperature, thickness, verticalInch, sdDepth, btdDepth, skdDepth, snowStake } = this.state
        let skiingValue = Math.round(sdDepth)
        let hikerValue = Math.round(btdDepth)
        let temperatureLength = temperature.length
        let snowSpacing
        let height, snowDegree, skiingImage, walkingImage
		const screen_height = window.innerHeight|| document.documentElement.clientHeight|| document.body.clientHeight;
		
		let stakeLegendValue = []
		let stakeLegendPosition = []
		var maxDepth = thickness.reduce((a, b) => a + b, 0);
		maxDepth = Math.round(maxDepth)
		
		snowSpacing = Math.abs(screen_height - 450) / 20
		
		for (var i = 0; i < maxDepth; i++) {
			if ((i % 6) === 0){
		stakeLegendValue.push(i);
		stakeLegendPosition.push(i*snowSpacing);
			}
		}
		
        return (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: 'calc(250px - 47px)', border: '1px solid #333333' }}>
			
			 <div>
	 {stakeLegendValue.map((dataLV, index) => {
							 return(
                               <div key={index} style={{ flex: 1 }}>
                                   <div key={index} style={{ height: 0, marginLeft: '-120px', top: `${stakeLegendPosition[index] + 'px'}`, position: 'relative'}}>
									{dataLV}"
						 </div>
						 </div>
					)})}
					</div>
			
                {					
                    temperature !== null && thickness !== null && temperature.map((data, index) => {
                        let temperatureData = data
                        snowDegree = null
                        skiingImage = ''
                        walkingImage = ''
                        
						var last_depth_array = []
						var last_depth = 0
						var current_depth = 0
						var current_depth_array = []
						
						//if first element, leave last depth set to 0
						if (index > 0) {
							last_depth_array = thickness.slice(0, index)
							
							last_depth = last_depth_array.reduce((a, b) => a + b, 0)
						}
						
						current_depth_array = thickness.slice(0, index+1)

						current_depth = current_depth_array.reduce((a, b) => a + b, 0)
							
						//if last element in array, set to length +1 to capture end point 
						
						if (skiingValue > maxDepth) {
							skiingValue = maxDepth - .05
						}
						//console.log("BFF", skiingValue)
						//console.log("MinL", last_depth)
						//console.log("MaxL", current_depth)

						
                        if ((current_depth > skiingValue && last_depth <= skiingValue)) {
							
						var percentLayer = (skiingValue - last_depth) / (current_depth - last_depth)
						//console.log("PL", percentLayer)
						
						var verticalAdjuster = 35 * (1 - percentLayer)
						verticalAdjuster = "-" + verticalAdjuster + "px"
							
                            skiingImage = <div style={{ marginRight: '-55px', marginTop: verticalAdjuster }}><img src={skating} alt="Skating" align="left" height='45' /></div>
                        }
							

                        let tempThickness = thickness[index]
                        height = verticalInch * tempThickness
                        let colors

                        if (temperatureData > 30) {
                            colors = "#333333"
                        }
                        else if (temperatureData > 20 && temperatureData < 30) {
                            colors = "#666666"
                        }
                        else if (temperatureData > 10 && temperatureData < 20) {
                            colors = "#999999"
                        }
                        else if (temperatureData > 0 && temperatureData < 10) {
                            colors = "#cccccc"
                        }
                        else if (temperatureData <= 0) {
                            colors = "#ffffff"
                        }
                        if (data !== null) {
                            return <div style={{ flex: 1 }}>
                                <p style={{ backgroundColor: colors, width: 200, height: `${snowSpacing + 'px'}`, margin: 0 }}>
                                </p>
                                {/* <div style={{ height: 0, display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                                    {walkingImage}
                                </div>*/
                                <div style={{ height: 0, display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                                    {skiingImage}
                                </div> }
                                {/* <p style={{ backgroundColor: 'black', width: 200, height: 30,  margin: 0 }}>
            </p> */}
                            </div>

                        }

                    })
                }
                <div>
                    {this.renderLoader()}
                </div>
                <div style={{ flex: 1 }}>
                {maxDepth > 0 && snowStake !== null &&
                    <div style={{ height: 0, marginLeft: '-40px',position:'relative',top:30,fontSize:20,fontWeight:'bold',color:'#000' }}>
                                        {maxDepth}"
                                    </div>
                }
                    <img src={grass} alt="Grass" align="left" width='200' height='64'
                /></div>
            </div>

        )
    }

    nameView = () => {
        const { name } = this.state
        let locationName = name ? name : 'Location Name'
        return (
            <div>
                {locationName}
            </div>
        )
    }

    densityLegendView = () => {
        return (
<div className="scale-value-view" style={{ position: 'relative', bottom: '0px', width: '500px' }}>                
<div class="row">
                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '2%' }}>
                        <span class="colorbar" style={{ backgroundColor: '#333333', }}>
                            <div style={{ marginTop: '10px', display: 'flex', justifyContent: 'center' }}>2:1</div>
                        </span>
                        <span class="colorbar" style={{ backgroundColor: '#666666' }}>
                            <div style={{ marginTop: '10px', display: 'flex', justifyContent: 'center' }}>3:1</div>
                        </span>
                        <span class="colorbar" style={{ backgroundColor: '#999999' }}>
                            <div style={{ marginTop: '10px', display: 'flex', justifyContent: 'center' }}>5:1</div>
                        </span>
                        <span class="colorbar" style={{ backgroundColor: '#cccccc' }}>
                            <div style={{ marginTop: '10px', display: 'flex', justifyContent: 'center' }}>10:1</div>
                        </span>
                        <span class="colorbar" style={{ backgroundColor: '#ffffff' }}>
                            <div style={{ marginTop: '10px', display: 'flex', justifyContent: 'center' }}>20:1</div>
                        </span>
                    </div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'center', paddingTop: 20 }}>
                    (Water : Snow Density)
                            </div>
            </div>
        )
    }

    qualityLegendView = () => {
        return (
<div className="scale-value-view" style={{ position: 'relative', bottom: '0px', width: '500px' }}>      
          <div class="row">
                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '2%' }}>
                        <span class="colorbar" style={{ backgroundColor: '#333333', }}>
                            <div style={{ marginTop: '10px', display: 'flex', justifyContent: 'center' }}>Ice</div>
                        </span>
                        <span class="colorbar" style={{ backgroundColor: '#666666' }}>
                            <div style={{ marginTop: '10px', display: 'flex', justifyContent: 'center' }}>Very Firm</div>
                        </span>
                        <span class="colorbar" style={{ backgroundColor: '#999999' }}>
                            <div style={{ marginTop: '10px', display: 'flex', justifyContent: 'center' }}>Firm</div>
                        </span>
                        <span class="colorbar" style={{ backgroundColor: '#cccccc' }}>
                            <div style={{ marginTop: '10px', display: 'flex', justifyContent: 'center' }}>Soft</div>
                        </span>
                        <span class="colorbar" style={{ backgroundColor: '#ffffff' }}>
                            <div style={{ marginTop: '10px', display: 'flex', justifyContent: 'center' }}>Fluffy</div>
                        </span>
                    </div>
                </div>
            </div>
        )
    }

    hardnessLegendView = () => {
        return (
            <div>
<div className="scale-value-view" style={{ position: 'relative', bottom: '0px', width: '500px' }}>      
                    <div class="row">
                        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '2%' }}>
                            <span class="colorbar" style={{ backgroundColor: '#333333', }}>
                                <div style={{ marginTop: '10px', display: 'flex', justifyContent: 'center' }}>Knife</div>
                            </span>
                            <span class="colorbar" style={{ backgroundColor: '#666666' }}>
                                <div style={{ marginTop: '10px', display: 'flex', justifyContent: 'center' }}>Pencil</div>
                            </span>
                            <span class="colorbar" style={{ backgroundColor: '#999999' }}>
                                <div style={{ marginTop: '10px', display: 'flex', justifyContent: 'center' }}>1 Finger</div>
                            </span>
                            <span class="colorbar" style={{ backgroundColor: '#cccccc' }}>
                                <div style={{ marginTop: '10px', display: 'flex', justifyContent: 'center' }}>5 Finger</div>
                            </span>
                            <span class="colorbar" style={{ backgroundColor: '#ffffff' }}>
                                <div style={{ marginTop: '10px', display: 'flex', justifyContent: 'center' }}>Fist</div>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    temperatureLegendView = () => {
        return (
<div className="scale-value-view" style={{ position: 'relative', bottom: '0px', width: '500px' }}>      
                <div class="row">
                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '2%' }}>
                        <span class="colorbar" style={{ backgroundColor: '#333333', }}>
                            <div style={{ marginTop: '10px', display: 'flex', justifyContent: 'center' }}>>30</div>
                        </span>
                        <span class="colorbar" style={{ backgroundColor: '#666666' }}>
                            <div style={{ marginTop: '10px', display: 'flex', justifyContent: 'center' }}>20-30</div>
                        </span>
                        <span class="colorbar" style={{ backgroundColor: '#999999' }}>
                            <div style={{ marginTop: '10px', display: 'flex', justifyContent: 'center' }}>10-20</div>
                        </span>
                        <span class="colorbar" style={{ backgroundColor: '#cccccc' }}>
                            <div style={{ marginTop: '10px', display: 'flex', justifyContent: 'center' }}>0-10</div>
                        </span>
                        <span class="colorbar" style={{ backgroundColor: '#ffffff' }}>
                            <div style={{ marginTop: '10px', display: 'flex', justifyContent: 'center' }}>{'<0'}</div>
                        </span>
                    </div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'center', paddingTop: 20 }}>
                    (F)
                            </div>
            </div>
        )
    }

    renderSpin() {
        return (
            <Spin
                style={{ display: 'flex', justifyContent: 'center' }}
                spinning={this.state.loading}>
            </Spin>
        )
    }

    renderLoader() {
        return (
            <Spin
                style={{ display: 'flex', justifyContent: 'center' }}
                spinning={this.props.locationLoading}>
            </Spin>
        )
    }

    iframe() {
        return {
            __html: iframe
        }
    }

    renderSnow() {
        const { jsonResponse } = this.state
        if (jsonResponse !== null && jsonResponse !== undefined && Object.keys(jsonResponse).length !== 0) { // check with undefined condition for only two tabs 
            this.setState({
                snowView: true,
                weatherView: false,
                hourView: false,
                displaySnow: true,
                snowScale: true,
                dateTimeView: true,
                avalancheView: false,
                tabKey: '1'
            })
        } else {
            this.setState({
                weatherView: false,
                hourView: false,
                displaySnow: false,
                snowScale: false,
                dateTimeView: true,
                grassView: true,
                avalancheView: false,
                tabKey: '1' // set tab key for only two tabs 
            })
        }
    }

    renderWeather() {
        this.setState({
            weatherView: true,
            displaySnow: false,
            snowScale: false,
            dateTimeView: false,
            snowView: false,
            grassView: false,
            avalancheView: false,
            hourView: false,
            tabKey: '2'
        })
    }

    renderHour() {
        this.setState({
            hourView: true,
            weatherView: false,
            displaySnow: false,
            snowScale: false,
            dateTimeView: false,
            snowView: false,
            grassView: false,
            avalancheView: false,
            tabKey: '3'
        },()=>{
            this.getHourlyUrl()
        })
    }

    getHourlyUrl = () => {
        // console.log("hourlyDays", this.state.hourlyDays)
        hourlyUrlData(`https://api.weather.gov/points/${this.state.latitude},${this.state.longitude}`, this.state.latitude, this.state.longitude, 0, this.state.hourlyDays).then((response) => {
            this.setState({
                hourData: response
            })
        }).catch(err => {
            console.log('forecast api error', err, err.response);
        })
    }

    renderAvalanche() {
        const { avalancheCarousel } = this.state
        let avalanche
        this.setState({
            hourView: false,
            weatherView: false,
            displaySnow: false,
            snowScale: false,
            dateTimeView: false,
            snowView: false,
            grassView: false,
            tabKey: '4'
        }, () => {
            fetch(`https://avy-forecast-zone-finder.glitch.me/get_zone/${this.state.latitude}/${this.state.longitude}`, {
            }).then((response) => response.json())
                .then((response) => {
                    this.setState({
                        avalancheData: parseInt(response.ID),
                        // avalancheCarousel: tempArr,
                        nameofAva: response.Name,
                        avalancheIndex: 0,
                        avalancheName: `https://avy-forecast-zone-finder.glitch.me/?zone=${response.ID}`
                    },()=>{
                        this.setState({
                            avalancheView: true
                        })
                    })
                }).catch(err => {
                    console.log('forecast api error', err, err.response);
                })
        })

    }

    changeAvalanche = () => {
        const { avalancheData, avalancheCarousel } = this.state
        let addData
        if(avalancheData < 21){
         addData = avalancheData + 1
        avalancheCarousel.map((name, index) => {
            if (addData === index) {
                this.setState({
                    nameofAva: name,
                    avalancheData: addData,
                    disableRight: true,
                    disableLeft: true
                })
            }
        })
        fetch(`https://avy-forecast-zone-finder.glitch.me/get_name/${addData}`, {
        }).then((response) => response.json())
            .then((response) => {
                this.setState({
                    avalancheName: response
                })
            }).catch(err => {
                console.log('forecast api error', err, err.response);
            })
        }else{
            this.setState({
                disableRight: false
            })
        }
    }

    changeBefore = () => {
        const { avalancheData, avalancheCarousel } = this.state
        if(avalancheData > 0){
        let minusData = avalancheData - 1
        avalancheCarousel.map((name, index) => {
            if (minusData === index) {
                this.setState({
                    nameofAva: name,
                    avalancheData: minusData,
                    disableLeft: true,
                    disableRight: true
                })
            }
        })
        fetch(`https://avy-forecast-zone-finder.glitch.me/get_name/${minusData}`, {
        }).then((response) => response.json())
            .then((response) => {
                this.setState({
                    avalancheName: response
                })
            }).catch(err => {
                console.log('forecast api error', err, err.response);
            })
        }else{
            this.setState({
                disableLeft: false
            })
        }
    }

    handleDays = (value ) => {
        // console.log('handleDays', value)
        this.setState({
            hourlyDays: value
        }, () => {
            if(value > 0) {
                this.getNextDays(this.state.hourlyDays)
                this.getHourlyUrl()
            }else {
                this.getHourlyUrl()
            }
        })
    }

    onChangeSnowHour =(value) => {
        this.setState({
            snowHour: value
        }, () => {
            // console.log('onChangeSnowHour', value)
            this.onOk(value, 'SnowHour')
        })
    }

    getNextDays = (hourlyDays) => {
        const value = hourlyDays / 24
        const nextDay = moment().add(value, 'days')
        const month = nextDay.format('M');
        const date  = nextDay.format('D');
        const day = nextDay.format('dddd')
        this.setState({
            nextMonth: month,
            nextDate: date,
            nextDay: day
        })
    }
    render() {
        const { preference, snowStake, loading, snowScale, displaySnow, weatherView, dateTimeView, index, timesArray,
            locationArr, name,nameofAva, dateFormat, hourView, hourlyDays, snowView, grassView, avalancheView, tabKey, snowHour,
            disableRight, disableLeft, nextMonth, nextDate, nextDay } = this.state
        const currentDate = moment().format('YYYY/MM/DD')
        const value = moment(currentDate, 'YYYY/MM/DD');
        const month = value.format('M');
        const date  = value.format('D');
        const day = moment().format('dddd')

        const today =  `${day} (${month}/${date})`
        const nextDayValue = `${nextDay} (${nextMonth}/${nextDate})`

        console.log('timesArray', timesArray, snowStake, "snowStake")
        console.log(snowStake,"snowStake")

        return (
            <div className="card-modal" style={{ padding: '10px', overflowY: 'hidden' }}>
                <Card style={styles.card} id="modal-card-container">
                    <div style={{ height: 15 }}>
                    </div>
                    {this.state.locationArr !== null && this.state.locationArr.length > 0 &&

                        <div style={styles.pickerText} id="slider-view" >
                            <CarouselSlider {...settings} vertical={true} afterChange={this.locationClick} initialSlide={index} >
                                {locationArr.map(option => {
                                    return <div style={{ display: 'flex', justifyContent: 'center', width: '100%',alignItems:'center' }}>{name}</div>
                                })}
                            </CarouselSlider>
                        </div>
                    }
                    {/* {this.renderDatas()} */}
                    <div style={{ display: 'flex', justifyContent: 'center', paddingBottom: 15 }}>
                        <div className="value-based-images" style={{ width: '36%', display: 'flex', justifyContent: 'space-between' }}>
                            <div>
                                <img src={angle} alt="Angle" align="left" width='50' height='34' style={{ width: 25, height: 25 }} />
                                <span className="location-value-view" id="degree-view">
                                    {this.state.angleData}
                                </span>
                            </div>
                            <div>
                                <img src={height} alt="Height" align="left" width='50' height='34' style={{ width: 25, height: 25 }} />
                                <span className="location-value-view">
                                    {this.state.elevationData}
                                </span>
                            </div>
                            <div>
                                <img src={compass} alt="Compass" align="left" width='50' height='34' style={{ width: 25, height: 25 }} />
                                <span className="location-value-view">
                                    {this.state.aspectData}
                                </span>
                            </div>
                            <div>
                                <img src={tree} alt="Tree" align="left" width='50' height='34' style={{ width: 25, height: 25 }} />
                                <span className="location-value-view">
                                    {this.state.canopyData}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '-5px', marginBottom: '20px' }}>
                        <div style={{ width: '500px' }}>
                            <div class="row">
                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                    <span>
                                        <Button className="snow-button" style={tabKey === '1' ? styles.tabNewStyle : styles.tabStyle}
                                            onClick={() => this.renderSnow()}>Snow</Button>
                                    </span>
                                    {/* <span>
                                        <Button className="snow-button" style={tabKey === '2' ? styles.tabNewStyle : styles.tabStyle}
                                            onClick={() => this.renderWeather()}>
                                            Weather
                                </Button>
                                    </span> */}
                                    <span>
                                        <Button className="snow-button" style={tabKey === '3' ? styles.tabNewStyle : styles.tabStyle}
                                            onClick={() => this.renderHour()}>Weather</Button>
                                    </span>
                                    {/* <span>
                                        <Button className="snow-button" style={tabKey === '4' ? styles.tabNewStyle : styles.tabStyle}
                                            onClick={() => this.renderAvalanche()}>Avalanche</Button>
                                    </span> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div style={{ display: 'flex', justifyContent: 'center'}}>
                        <Button
                        onClick={() => this.renderWeather()}
                        >
                            Weather
                        </Button>
                    </div> */}
                    {weatherView === true && tabKey === '2' &&
                        <Iframe
                            url={`https://forecast.weather.gov/MapClick.php?lat=${this.state.latitude}&lon=${this.state.longitude}#seven-day-forecast`}
                            width="100%"
                            height="450px"
                            allowFullScreen
                        />
                    }
                    {hourView === true && tabKey === '3' &&
                         <div className="hourly-container" style={{width: "100%", maxWidth: "810px", margin: "auto", maxHeight: "calc(100vh - 250px)",  height: "calc(100vh - 250px)"}}>
							<div style={{position: 'relative', height: "inherit", width: "inherit", overflow: "scroll"}} ref={this.resultsDiv}>
							<Iframe
                               // className="hourly-image"
                                url={`${this.state.hourData}`}
								position = "absolute"
                                width="851px"
                                height= "901px"                            /></div>
                            {/* <div id="hourly-slider-view">
                            <div style={{color: 'white', marginTop: 10}}>{today} {hourlyDays > 0 ? 'to' : null} {hourlyDays > 0 ? nextDayValue : null}</div>
                            <div id="hourly-slider" style={{width: '60%'}}>
                                <Slider 
                                marks={marks} 
                                step={24} 
                                value={hourlyDays} 
                                onChange={this.handleDays}
                                max={(Object.keys(marks).length - 1) * 24}
                                min={0}
                                tooltipVisible={false}
                                />
                            </div>
                            </div> */}
                        </div>
                    }
                    {avalancheView === true &&
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '15px'}}>
                    <Icon type="left"  style={disableLeft === true ? styles.beforeIconStyle : styles.beforeIcondisable} onClick={() => disableLeft === true ? this.changeBefore() : null}></Icon>
                    <div style={{ fontSize: '15px', fontWeight: '600' }}>{nameofAva}</div>
                    <Icon type="right"  style={disableRight === true ? styles.afterIconStyle : styles.afterIcondisable} onClick={() => disableRight === true ? this.changeAvalanche() : null}></Icon>
                    </div>
                    }
                    {avalancheView === true &&
                        <Iframe
                            src={`${this.state.avalancheName}`}
                            width="100%"
                            height="450px"
                            allowFullScreen
                        />
                    }
                    {/* <div style={styles.pickerText}>
                        Time
                    </div> */}
                   

                    {loading === true &&
                        <div>
                            {this.renderSpin()}
                        </div>
                    }
                   <div>
                        {snowStake <= 0 && snowStake !== null && tabKey === '1' &&
                        <div id="no-consistent-content" style={snowStake <= 0 && snowStake !== null && tabKey === '1' ? styles.scaleView : styles.hideView}>
						No consistent snow cover detected.</div>
                        }
                    </div>
                    <div style={styles.scaleView}>
                        {
                            snowStake > 0 &&
                            <div className="scale-modal-head">
                                {
                                    preference !== null && preference === 'Ql' && displaySnow === true && snowScale === true && snowView === true &&
                                    <div className="content-scale-view">
                                        <div>
                                            <p style={styles.menuText}> Snow Quality </p>
                                        </div>
                                        <div className="scale-container-view">
                                            <div className="snow-value-modal">
                                                {this.qualityValue()}
                                                {/* <div><img src={grass} alt="Grass" align="left" width='200' height='64' marginBottom='30'
                                            />
                                                {this.legendView()}
                                            </div> */}
                                            </div>
                                        </div>
                                    </div>
                                }
                                {
                                    preference !== null && preference === 'Ql' && displaySnow === true && snowScale === true && snowView === true &&
                                    this.qualityLegendView()}
                                {
                                    preference !== null && preference === 'De' && displaySnow === true && snowScale === true && snowView === true &&
                                    <div className="content-scale-view">
                                        <div>
                                            <p style={styles.menuText}>Snow Density </p>
                                        </div>
                                        {/* <div> */}
                                        <div className="scale-container-view">
                                            <div className="snow-value-modal">
                                                {this.densityView()}
                                                {/* <div ><img src={grass} alt="Grass" align="left" width='200' height='64' marginBottom='30'
                                            />{this.legendView()}</div> */}
                                                {/* </div> */}
                                            </div>
                                        </div>

                                    </div>

                                }
                                {preference !== null && preference === 'De' && displaySnow === true && snowScale === true && snowView === true &&
                                    this.densityLegendView()}

                                {
                                    preference !== null && preference === 'Hd' && displaySnow === true && snowScale === true && snowView === true &&
                                    <div className="content-scale-view">
                                        <div>
                                            <p style={styles.menuText}> Snow Hardness
                                   </p>
                                        </div>
                                        <div className="scale-container-view">
                                            <div className="snow-value-modal">
                                                {this.hardnessValue()}
                                            </div>

                                            {/* <div ><img src={grass} alt="Grass" align="left" width='200' height='64' marginBottom='30'
                                            />{this.legendView()}</div> */}
                                        </div>
                                    </div>
                                }
                                {preference !== null && preference === 'Hd' && displaySnow === true && snowScale === true && snowView === true &&
                                    this.hardnessLegendView()}
                                {
                                    preference !== null && preference === 'Te' && displaySnow === true && snowScale === true && snowView === true &&
                                    <div className="content-scale-view">
                                        <div>
                                            <p style={styles.menuText}>Snow Temperature
                            </p>
                                        </div>
                                        <div className="scale-container-view">
                                            <div className="snow-value-modal">
                                                {this.temperatureValue()}


                                            </div>

                                            {/* <div>
                                            {this.legendView()}
                                            </div> */}

                                        </div>
                                    </div>
                                }
                            </div>
                        }
                        {preference !== null && preference === 'Te' && displaySnow === true && snowScale === true && snowView === true &&
                            this.temperatureLegendView()}
                        <div>
                            {this.renderLoader()}
                        </div>
						{preference !== null && displaySnow === true && snowScale === true && snowView === true &&
						<div style={{height: '120px'}}>
						</div>}
                    </div>
                    {this.state.dateValue !== null && dateTimeView === true && tabKey === '1' &&
                    <div id="location-carousel-view">
                    <div style={styles.pickerView} id="snow-slider-fullView">
                        <CarouselSlider {...timeSettings} vertical={true} afterChange={this.onOk}>
                            {timesArray.map(option => {
                                return <div style={{ display: 'flex', justifyContent: 'center', width: '100%', color: 'white' }}>{dateFormat}</div>
                            })}
                        </CarouselSlider>
                        <div id="snow-slider-view">
                        <div id="snow-slider" style={{width: '100%'}}>
                        <Slider 
                            marks={snowMarks} 
                            // step={24} 
                            value={snowHour} 
                            onChange={this.onChangeSnowHour}
                            max={48}
                            min={0}
                            tooltipVisible={false}
                            tipFormatter={null}
                            overlayStyle={{backgroundColor: 'white'}}
                            />
                            </div>
                        </div>
                    </div>
                    </div>
                    }
                </Card>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const { location, locationLoading } = state.locationData // Reducer Name
    return {
        location,// Reducer variable
        locationLoading,
    }
}

const mapDispatchToProps = {
    getLocation, //Action
}
export default
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(LocationScreen);
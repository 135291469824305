export default {
    header:{
        display: 'flex',
        justifyContent: 'center',
        color: 'black',
        fontSize: 18,
        fontWeight: 400
    },
    card:{
        // width: 1320,
        height: 'calc(100vh - 86px)',
        display:'flex',
        flexDirection:'row',
        justifyContent: 'center',
        alignItems:'center'
    },
    picker:{
        display: 'flex',
        marginTop: 20,
        justifyContent: 'center',
        marginBottom: 50
    },
    pickerText:{
        display: 'flex',
        justifyContent: 'center',
        fontSize: 15,
        fontWeight: 600,
        marginTop: 10,
    },
    scaleView:{
        display: 'flex',
        justifyContent: 'center',
        flexDirection : 'column',
        alignItems: 'center',
        maxHeight: 350,
        marginTop:'3%'
        // fontSize: 13,
        // fontWeight: 600,
        // marginTop: 10,
        // height: 385,
    },
    menuItem: {
        borderRight: '5px solid',
        borderRightColor: 'black',
        borderLeft: '5px solid',
        borderLeftColor: 'black'
    },
    menuText: {
        marginTop: 10,
        fontSize: 15,
        fontWeight: 700,
        display: 'flex',
        justifyContent:'center'
    },
    grassImage: {
        marginTop: 10,
        fontSize: 15,
        fontWeight: 700,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    warningText: {
        display: 'flex',
        justifyContent: 'center'
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: 20,
        marginRight:10,
        width: 200,
    },
    
    // scaleHeight: {
    //     height: this.scaleHeight()
    // }
}

import firebase from '../common/firebase';
import { LOCATION, LOCATION_SUCCESS, LOCATION_FAIL } from './types'

const db = firebase.firestore();

export const getLocation = (uid) => (dispatch) => {

  dispatch({ type: LOCATION });
  try {
    const location = [];
    db.collection('users')
      .doc(uid)
      .get().then((snapshot) => {
        // console.log(snapshot.data(), 'snapshot1111')
        return dispatch({ type: LOCATION_SUCCESS, payload: snapshot.data() })
        // if (snapshot.size > 0) {
        // snapshot.forEach(doc => {
        //   console.log(doc, 'docs')
        //   let items = doc.data();
        //   console.log('items', items)
        //   if (items.location !== null && items.location !== undefined) {
        //     const temp = {
        //       Name: items.location.Name,
        //     }
        //     location.push(temp);
        //   }
        //   console.log('length', snapshot.size, location.length)
        //   if (snapshot.size === location.length) {
        //     return dispatch({ type: LOCATION_SUCCESS, payload: location })
        //   }
        // });
        // } else {
        //   return dispatch({ type: LOCATION_FAIL, payload: [] })
        // }

      }).catch(err => {
        return dispatch({ type: LOCATION_FAIL, payload: [] })
      })


  }
  catch (err) {
    return dispatch({ type: LOCATION_FAIL, payload: err.message })
  }
}

export const dataLayer = {
  id: 'data',
  source: 'incomeByState',
  type: 'fill',
  interactive: true,
  'paint': {
    'fill-color': '#088',
    'fill-opacity': 0.0
    }
}

export const colorLayer = {
  id: 'data',
  source: 'incomeByState',
  type: 'fill',
  interactive: true,
  'paint': {
    'fill-color': '#088',
    'fill-opacity': 0.5
    }
}

export const initialLayer = {
  id: 'data',
  source: 'incomeByState',
  type: 'fill',
  interactive: true,
  'paint': {
    'fill-color': '#088',
    'fill-opacity': 0.5
    }
}

export const topoShadeLayer = {
  id: 'hillshading',
  type: 'hillshade',
  source: 'raster-dem',
  // 'source-layer': 'raster-dem',
}

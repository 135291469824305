export default {
    START: '/',
    LANDING: '/landing',
    HOME: '/home',
    LOCATION: '/location',
    PREFERENCE: '/preferences',
    USERSHOME: '/users',
    SUBSCRIPTION: '/subscription',
    PAYMENT: '/payment',
    FEEDBACK: '/feedback',
    ADD_CARD: '/addcard/:id',
    SEARCH_LOCATION: '/search',
    CHANGE_PASSWORD:'/changePassword',
    FEEDBACKLOCATION: '/users/feedback'
}


import React, { Component } from 'react';
import { Button, Card, List, Skeleton, Icon, Modal, Input, AutoComplete, Spin, message } from 'antd';
import _ from 'lodash';
import firebase from '../../common/firebase';
import { connect } from 'react-redux';
import * as userApi from "../../api/users";
import { getLocation } from '../../actions/locationAction';
import {userFields} from "../../api/collectionName";
import styles from './home.Style';
import { async } from 'q';

const { Search } = Input;


const TOKEN = 'pk.eyJ1IjoicGF2aXRocmEtbSIsImEiOiJjazA5ZTljdDEwN3dyM2JwbGh5OXY1N2xoIn0.opM-LgYh959FB_84PdGb7w'; // Set your mapbox token here

class HomeScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      detail: null,
      lat: null,
      long: null,
      deleteIcon: false,
      visible: false,
      subsVisible: true,
      locationName: null,
      locations: [],
      allLocation: [],
      search: false,
      preferenceMenu: null,
      newLocation: null,
      newLatitude: null,
      newLongitude: null,
      latitude: null,
      longitude: null,
      searchFocus: false,
      initLoading: false,
      loading: false,
      dataSource: [],
      searchText: null,
      name: {},
      currentUserUid:null,
      currentUser: null,
      expiryDate: null,
      paymentStatus: null,
      lastLogin: null,
      paymentSubs: false,
      clicked: false,
      arrClicked: true,
      indexValue: null,
      searchValue: '',
    }
  }

  componentDidMount() {
    this.setState({
      currentUserUid:(localStorage.getItem('currentUserUid'))
    },()=>{
      this.props.getLocation(this.state.currentUserUid)
    });

    const { newLocation, newLatitude, newLongitude } = this.state
    if (this.props && this.props.history && this.props.history.location.state) {
      this.setState({
        newLocation: this.props.history.location.state.newPlace,
        newLatitude: this.props.history.location.state.newLatitude,
        newLongitude: this.props.history.location.state.newLongitude,
        initLoading: false,

      })
    }
  }

  componentWillReceiveProps(nxtprops) {
    const {lastLogin,expiryDate} = this.state
    this.setState({
      currentUserUid:(localStorage.getItem('currentUserUid')),
      currentUser:JSON.parse(localStorage.getItem('currentUserAuth'))
    });
    if(nxtprops && nxtprops.location){
      this.setState({
        lastLogin: this.state.currentUser.lastLoginAt
      })
    }

    if (nxtprops && nxtprops.location && nxtprops.location.location) {
      this.setState({
        locations: nxtprops.location.location,
        allLocation: nxtprops.location.location,
      },()=> {
      })
    }
    if(nxtprops && nxtprops.location && nxtprops.location.preference){
      this.setState({
        preferenceMenu: nxtprops.location.preference.stake
      })
    }
    if(nxtprops && nxtprops.location && nxtprops.location.paymentInfo){
      this.setState({
        expiryDate: nxtprops.location.paymentInfo.subscriptionEndDate,
        paymentStatus: nxtprops.location.paymentInfo.status
      })
    }
    if(lastLogin === expiryDate && lastLogin <= expiryDate){
       this.setState({
        // visible : true,
        paymentSubs: true

       })
    }
  }

  renderLoader() {
    return (
        <Spin 
        style={{ display: 'flex', justifyContent: 'center'}}
        spinning={this.props.locationLoading}>
        </Spin>
    )
}

  showModal = (loc) => {
    let showDelete = false
    loc.map(click =>{
      if(click.clicked === true){
        showDelete = click.clicked
      }
    })
    if(loc !== null && loc.length > 0 && showDelete === true){
      this.setState({
        visible: true,
      });
    } else{
      message.warning("Please select location to delete")
    }
  };

  handleOk = async(data, value) => {
    let tempArr = []
    if (data !== null) {
      data.map((location, index) => {
        if (location.clicked !== true) {
          tempArr.push(location)
        }
      })
      this.setState({
        locations: tempArr,
        deleteIcon: false,
        allLocation: tempArr
      },() =>{
      })
    }
    this.setState({
      visible: false,
    });
    await  userApi.updateUserLocation(this.state.currentUserUid,tempArr,userFields.LOCATION);
  };

  handleCancel = e => {
    this.setState({
      visible: false,
    });
  };

  locationClick = (data, latitude, longitude, index) => {
    let locationName = data
    const { preferenceMenu, locations } = this.state
    if(locations !== null){
    
     locations !== null && locations.map((data, index) =>{
       if(data.name === locationName){
       this.setState({
        detail: data.name,
        lat: data.coords[0].lat,
        long: data.coords[0].lan,
        indexValue: index
       },()=>{
        this.props.history.push({
          pathname: '/location',
          state: { detail: data.name, lat: data.coords[0].lat, long: data.coords[0].lan, preference: preferenceMenu, index: index }
        })
       })
      
    }
     })
    }
  }

  deleteView = (value) => {
    const { deleteIcon, locations,arrClicked } = this.state
    let tempData = []
    tempData = this.state.locations
    tempData.map(data => {
      if(data.clicked === true){
        data.clicked = false
        this.setState({
          arrClicked: data.clicked
        },()=>{
        })
      }
      
    })
    this.setState({
      deleteIcon: !this.state.deleteIcon,
      clicked: !this.state.clicked,
      locations: tempData,
      // arrClicked: false
    }, () => {
    })
  }

  deleteLocation = (name, key, value) => {
    if (name !== undefined && name !== null) {
      let temp = []
      temp = this.state.locations
      temp[key].clicked = !temp[key].clicked
      this.setState({
        locations: temp,
        locationName: name,
        arrClicked: temp[key].clicked,
        deleteIcon: true
      },()=> {
      })
    }

  }

  searchLocation = () => {
    this.setState({
      search: !this.state.search
    })
  }

  onChangeSearch = (val) => {
    console.log('onChangeSearch', val)
    // hided for clear input
    // if (val.length === 0) {
    //   this.setState({locations: this.state.allLocation})
    // }

    this.setState({
      searchValue: val
    })
  }

  onclickSearch = (val) => {
    // if (val.length > 2) {
      let tempArr = []
      let temp = {};
      const filterValue = _.filter(this.state.allLocation, (o, index) => {
        const locationName = o.name
        if (_.includes(locationName.toLowerCase(), val.toLowerCase())) {
          temp = o;
          temp.index = index;
          tempArr.push(temp)
          return temp
        }
      })
      this.setState({allLocation: tempArr})
    // } 
  }

  subsOk = () => {
    this.setState({
      subsVisible: false
    },()=>{
      this.props.history.push('./subscription')
    })
  }

  searchFilterChangeHandler = () => {
    this.setState({
      allLocation: this.state.locations,
      searchValue: ''
    })
  }


  render() {

    const { deleteIcon, searchValue, clicked, paymentSubs, search, paymentStatus, locations, loading } = this.state
    return (
      <div className="card-modal" style={{ padding: '10px',overflow:'hidden' }}>
        <Card style={styles.card} id="modal-card-list">
          <div style={{ display: 'flex', flexDirection: 'row', }}>
            {/* <div style={styles.header}>Snow Intel</div> */}
          </div>
          <div>
            <Card id="add-location-modal">
              <div className="homeHeader" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <div style={styles.location}>My Locations</div>
                <div className="homeIcon" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
                  <Icon
                    id="homeIconStyle"
                    type="search"
                    style={{ fontSize: 20, color: 'black', marginTop: 5, marginRight: 25 }}
                    onClick={() => this.searchLocation()}
                  />
                  <Icon
                    id="homeIconStyle"
                    type="plus"
                    style={{ fontSize: 20, color: 'black', marginTop: 5, marginRight: 25 }}
                    onClick={() => this.props.history.push('./home')}
                  />
                  <Icon
                    id="homeIconStyle"
                    type="delete"
                    style={{ fontSize: 20, color: 'black', marginTop: 5, marginRight: 50 }}
                    onClick={() => this.deleteView()}
                  />
                </div>
              </div>
              {search === true &&
                <div id="home-search">
                  <Search
                          placeholder="input search text"
                          onSearch={value => this.onclickSearch(value)}
                          onChange={value => this.onChangeSearch(value.target.value)}
                          value={searchValue} // for clear input
                          addonAfter={ // for clear input
                            <Icon type="close-circle-o"
                              onClick={() => this.searchFilterChangeHandler() }
                              style={styles.closeIcon}
                            />
                          }
                          enterButton
                          // allowClear={true}
                        />
                </div>
              }
              {this.state.locations !== null && this.state.locations !== undefined &&
              <div className="details-list-view">
              <List
              
                style={{ marginTop: 10, marginRight: 30, }}
                itemLayout="horizontal"
                dataSource={this.state.allLocation}
                renderItem={(item, index) => (

                  <List.Item>
                    <Skeleton avatar title={false} loading={item.loading} active></Skeleton>
                    <List.Item.Meta
                    id="location-list"
                      style={item.clicked === true ? styles.listNewStyle : styles.listStyle}
                      title={<div style={{color: item.clicked === true ? '#fff' : 'black'}}>{item.name}</div>}
                      description={<div style={{color: item.clicked === true ? '#fff' : 'black'}}>{item.desc}</div>}
                      key={index}
                      onClick={() => deleteIcon === true ? this.deleteLocation(item.name,index, this.setState({clicked: true})) : this.locationClick(item.name, item.coords[0].lat, item.coords[1].lan, index)}
                    // onClick={() => this.locationClick(item.name,item.latitude,item.longitude)}
                    />
                    {
                      deleteIcon === true &&
                      <Icon
                        type="delete"
                        style={{ fontSize: 20, color: 'black', marginTop: 5 }}
                        onClick={() => this.deleteLocation(item.name, index, this.setState({ clicked: true }))}
                      >
                      </Icon>
                    }
                  </List.Item>
                )}
              />
              </div>
                  }
              {
                locations.length > 0 && clicked === true && deleteIcon === true && 
                <div style={{ display: 'flex', justifyContent: 'center', marginTop: 30 }}>
                  <Button
                  id="common-button-view"
                    onClick={()=> this.showModal(locations)}
                  >
                    Delete Selected Locations
                  </Button>
                  <Modal
                    title="Deleting Locations"
                    visible={this.state.visible}
                    onOk={() => this.handleOk(locations)}
                    onCancel={this.handleCancel}
                  >
                    {
                      this.state.locations.map((data, key) => {
                        if (data.clicked === true) {
                          return (
                          <div key={key}>
                            {data.name}
                          </div>
                          )
                        }
                      })
                    }
                  </Modal>
                </div>
              }
              {/* {paymentStatus === 'Cancelled' && paymentSubs === true &&
          
                <Modal
                    title="Subscription Required!"
                    visible={this.state.subsVisible}
                    footer={null}
                    // onOk={this.subsOk}
                    onCancel={this.subsOk}
                    // cancelText='Subscribe Now'
                  >
                    <div>
                      Your account does not have a current subscription or active free trial.
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: 20}}>
                      <Button id="common-button-view" onClick={()=> this.subsOk()}>
                        Subscribe Now
                      </Button>
                    </div>
                  </Modal>
            
              } */}
               <div>
                    {this.renderLoader()}
                </div>
            </Card>
          </div>
        </Card>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  const { location, locationLoading } = state.locationData // Reducer Name
  return {
    location,// Reducer variable
    locationLoading,
  }
}

const mapDispatchToProps = {
  getLocation, //Action
}
export default
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(HomeScreen);

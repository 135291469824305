import React, { Component } from 'react';
import { Layout,Modal,Icon } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import intelIcon from '../../themes/assets/images/intelIcon.png';
import intelWord from '../../themes/assets/images/intelWord.png';
import SnowIntelLogo from '../../themes/assets/images/SnowIntelLogo.png';
import userProfile from '../../themes/assets/images/user_profile.png';
import Angle from '../../themes/assets/images/Angle1.png';
import Layers from '../../themes/assets/images/layers.png';
import Dot from '../../themes/assets/images/dot.png';
import map from '../../themes/assets/images/map.png';
import grass from '../../themes/assets/images/grass1.png';
import tab1 from '../../themes/assets/images/tap1.png';
import tab2 from '../../themes/assets/images/tap2.png';
import tab3 from '../../themes/assets/images/tap3.png';
import Next from '../../themes/assets/images/next.png';
import Compass from '../../themes/assets/images/Compass1.png';
import Ski from '../../themes/assets/images/ski.png';
import Height from '../../themes/assets/images/height1.png';
import Tree from '../../themes/assets/images/Tree1.png';
import weather from '../../themes/assets/images/weather.png';
import AngleThick from '../../themes/assets/images/angle_thick.png';
import Snow1 from '../../themes/assets/images/snow(1).png';
import camera from '../../themes/assets/images/ar-camera1.png';

import {AuthService} from '../auth/auth.service';
import styles from './styles';
import { Layer } from 'react-mapbox-gl';

import { Link, withRouter } from "react-router-dom";

const { Header,Sider } = Layout;

export class HeaderComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
          currentUser:{},
          visible: false,
          mapLayers: false,
          snow: false,
          pitDetail: false,
          iconDefinitions: false,
        };
    }

    componentDidMount() {
        //subscribe the urrent user details
        this.subscription = AuthService.getCurrentUser().subscribe(user => {
            if (user) {
                this.setState({currentUser:user.currentUser});
            }
        });

    }
    handleCancel = () => {
        console.log('Clicked cancel button');
        this.setState({
          visible: false,
        });
      }
      snowCancel =() => {
        console.log('Clicked cancel button');
        this.setState({
            snow : false,
        });
      }
      handlemaplayer = () => {
        console.log('Clicked cancel button');
        this.setState({
            mapLayers : false,
        });
      }
      pitCancel =() =>{
        console.log('Clicked cancel button');
        this.setState({
            pitDetail : false,
        });
      }
      iconDefinitions = () =>  {
        console.log('Clicked cancel button');
        this.setState({
          iconDefinitions : false,
        });
      }

      checkPathName = () => {
        console.log('pathname', window.location.pathname);
        if (window.location.pathname === '/location') {
          this.setState({pitDetail: true})
        } else {
          this.setState({visible: true})
        }
      }

    render() {
        const {currentUser,visible,mapLayers,snow,pitDetail,iconDefinitions} =this.state;

        return (
            <div className="header-view">
                <Header style={styles.onHeaderRow} className="mainheaderContent" id="mobile-logo-view" >
   
                <QuestionCircleOutlined 
                  onClick={() => this.checkPathName()}
                style={{color:'white',
                fontSize:25,
                alignSelf:'center',position:'absolute',left:15}}/>
                
                    {/* <div style={styles.onHeaderRow}>
                        <img src={userProfile} alt="Profile" align="left" width='40' height='50' marginTop='10' style={{borderRadius:45}} />
                        {currentUser && (currentUser.displayName) &&
                        <p style={styles.displayName}>{currentUser.displayName}</p>
                        }
                    </div> */}
                   
                    <div style={styles.titleStyle}>
                       {/* <div><img src={intelIcon} alt="Icon" align="left" width='64' height='64' marginTop='10' /></div> */}
                    <div style={{display:'flex',justifyContent:'center'}}>
					<Link to="/home">
                  
                        <img src={SnowIntelLogo} id="logo-view" alt="Icon" align="left"/>
						</Link>
						</div>
                    </div>
                </Header>
                <Modal
                 className="header-modal-view"
                 id="sample"
                // icon={<QuestionCircleOutlined />}
                title={<span style={{display:'flex',flexDirection:'row'}}>
                    <QuestionCircleOutlined 
                    style={{paddingRight: 15, paddingTop:4,color:'#fff',fontSize:17}}  />
                    <p style ={{fontSize: 16,fontWeight: 'bold',color:'#fff',fontSize:20}}>How To Use Snow Intel</p>
                </span>}
              visible={visible}
              footer ={null}
            //   onOk={this.handleOk}
        //   confirmLoading={confirmLoading}
          onCancel={this.handleCancel}
        >
           <p style ={{fontSize: 16,textDecoration: 'underline',fontWeight: 'bold',color:'#fff'}}>1)  Find Where It’s Freshest</p>
          <div style={{borderWidth:2,borderColor:'gray',borderRadius:10,borderStyle:'solid',padding:10,marginTop:5,marginBottom:5}}>
         
          <div 
           onClick={() => {
            this.setState({visible: false,mapLayers: true})
        }}
          style={{
         
              
              display: 'flex',
              flexDirection: 'row',
        }}>
            <img src={Layers} style={{width: 30,height:30, alignSelf: 'center'}} />
            
             <p style={{fontSize: 15,marginLeft:15,alignSelf: 'center',color:'#fff',marginBottom:0}}>Use map overlays to research the terrain and snow cover.</p>
             <Icon type="right" style ={{fontSize: 20,alignSelf: 'center',marginLeft: 10,color:'#fff'}} />
          </div>
          </div>
          <p style ={{fontSize: 16,textDecoration: 'underline',fontWeight: 'bold',color:'#fff', paddingTop: 50}}>2)  Dig Deeper</p>
          <div style={{borderWidth:2,borderColor:'gray',borderRadius:10,borderStyle:'solid',padding:10,marginTop:5,marginBottom:5}}>
          
          <div 
              onClick={() => {
                this.setState({visible: false,snow: true})
            }}
          style={{
              
              display: 'flex',
              flexDirection: 'row',
        }}>
            <img src={Dot} style={{width: 30,height:30, alignSelf: 'center'}} />
            
             <p style={{fontSize: 15,marginLeft:15,alignSelf: 'center',color:'#fff',marginBottom:0}}>Add a pit to explore detailed snow and weather predictions for a location.</p>
             <Icon type="right" style ={{fontSize: 20,alignSelf: 'center',marginLeft: 10,color:'#fff'}} />
          </div>
          </div>
        </Modal>
        <Modal
        className="header-modal-view"
                // icon={<QuestionCircleOutlined />}
                title={<span style={{display:'flex',flexDirection:'row'}}>
                <QuestionCircleOutlined 
                style={{paddingRight: 15, paddingTop:4,color:'#fff',fontSize:17}}  />
                <p style ={{fontSize: 16,fontWeight: 'bold',color:'#fff',fontSize:20}}>How To Use Snow Intel</p>
            </span>}
              visible={mapLayers}
              footer ={null}
            //   onOk={this.handleOk}
        //   confirmLoading={confirmLoading}
          onCancel={this.handlemaplayer}
        >
          <div style={{marginTop: '-20px'}}>
          <div
          style={{
             display: 'flex',
              flexDirection: 'row',
        }}>
           <div id="help-slope-view" style={{width:'20%',display:'flex',justifyContent:'center'}}>
            <img src={Layers} style={{width: 30,height:30,}} />
            </div>
            
             <p style={{fontSize: 15,marginLeft:35,alignSelf: 'center',color:'#fff'}}>Use map overlays to research the terrain and snow cover.</p>
          </div>
          <div style={{width:'80%'}}>
          <p style ={{fontSize: 15,textDecoration: 'underline',fontWeight: 'bold',textAlign: 'center',color:'#fff', marginTop: '20px'}}>Available Map Layers</p>
          </div>
          <div
          style={{
              
              display: 'flex',
              flexDirection: 'row',
              marginBottom:15
        }}>
            <div 
             id="help-slope-view"
            style={{
              display: 'flex',
              borderWidth:2,
              borderColor:'gray',
              borderRadius:10,
              paddingTop: 2,
              borderStyle:'solid',
              flexDirection: 'column',
              alignItems:'center',
              justifyContent:'center',
              width:'20%',
              height:70,
              padding:3
             }}>
               <img src={Snow1} style={{width: 30,height:30,alignSelf: 'center' }} />
               <p style={{fontSize: 15,color:'#fff',marginBottom:0}}>Forecast</p>
            </div>
            <div style={{width:'80%'}}>
             <p style={{fontSize: 15,marginLeft:35,alignSelf: 'center',color:'#fff'}}>Snow forecasts, generated by Snow Intel, help you track where it's fresh.</p>
             </div>
          </div>
          <div
          style={{
            
              display: 'flex',
              flexDirection: 'row',
              marginBottom:15
        }}>
             <div 
             id="help-slope-view"
            style={{
              display: 'flex',
              borderWidth:2,
              borderColor:'gray',
              borderRadius:10,
              paddingTop: 2,
              borderStyle:'solid',
              flexDirection: 'column',
              alignItems:'center',
              justifyContent:'center',
              width:'20%',
              height:70,
              padding:3
             }}>
               <img src={AngleThick} style={{width: 30,height:30, alignSelf: 'center'}} />
               <p style={{fontSize: 15,color:'#fff',marginBottom:0}}>Slope</p>
            </div>
            
            <div style={{width:'80%'}}>
             <p style={{fontSize: 15,marginLeft:35,alignSelf: 'center',color:'#fff'}}>Overlay slope angle shading for further terrain analysis.</p>
             </div>
          </div>
          <div
          style={{
              display: 'flex',
              flexDirection: 'row',
              marginBottom:15
        }}>
               <div 
               id="help-slope-view"
            style={{
              display: 'flex',
              borderWidth:2,
              borderColor:'gray',
              borderRadius:10,
              paddingTop: 2,
              borderStyle:'solid',
              flexDirection: 'column',
             alignItems:'center',
             justifyContent:'center',
              width:'20%',
              height:70,
              padding:3
             }}>
               <img src={camera} style={{width: 30,height:30, alignSelf: 'center'}} />
               <p style={{fontSize: 15,color:'#fff',marginBottom:0}}>Satellite</p>
            </div>
            <div style={{width:'80%'}}>
            
             <p style={{fontSize: 15,marginLeft:35,alignSelf: 'center',color:'#fff'}}>Show the latest satellite imagery. Browse older imagery if clouds block your research.</p>
             </div>
          </div>
          <div
             onClick={() => {
              this.setState({visible: true,mapLayers: false})
          }}
          style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent:'center',
              color:'#fff',
			  paddingTop: '5vh',
			  paddingRight: '150px'
             
        }}>
            <Icon type="left" style ={{fontSize: 20,marginTop:5,marginRight:10,}} />
          <p style ={{fontSize: 16,fontWeight: 'bold', borderRightWidth:2,
              borderRightStyle:'solid',
              borderColor:'gray',
              paddingRight:10,marginBottom:0}}>Main Help Screen</p>
          </div>
          </div>
        </Modal>
        <Modal
        className="header-modal-view"
                // icon={<QuestionCircleOutlined />}
                title={<span style={{display:'flex',flexDirection:'row'}}>
                <QuestionCircleOutlined 
                style={{paddingRight: 15, paddingTop:4,color:'#fff',fontSize:17}}  />
                <p style ={{fontSize: 20,fontWeight: 'bold',color:'#fff'}}>How To Use Snow Intel</p>
            </span>}
              visible={snow}
              footer ={null}
            //   onOk={this.handleOk}
        //   confirmLoading={confirmLoading}
          onCancel={this.snowCancel}
        >
          <div 
          style={{
			  marginTop: '-20px',
              display: 'flex',
              flexDirection: 'row',
        }}>
            <img src={Dot} style={{width: 30,height:30, alignSelf: 'center'}} />
             <p style={{fontSize: 16,marginLeft:35,alignSelf: 'center',color:'#fff'}}>Add a pit to explore detailed snow and weather predictions.</p>
          </div>
          <p style ={{fontSize: 16,textDecoration: 'underline',fontWeight: 'bold',textAlign: 'center',color:'#fff'}}>To Access Pit Detail View:</p>
          <div 
           id="wrap-content-view"
          style={{
              justifyContent: 'space-between',
              display: 'flex',
              flexDirection: 'row',
        }}>
            <div 
          style={{
         
              borderRadius: 5,
              display: 'flex',
              alignSelf: 'center',
              justifyContent:'center',
              flexDirection: 'column',
              alignItems:'center'
        }}>
            <img src={tab1} id="wrap-content-image" style={{height:'30vh', alignSelf: 'center'}} />
            <p style ={{fontSize: 15,textAlign: 'center',width: 90,color:'#fff'}}>Tap Map To Add A Pit</p>
        </div>
        <div 
          style={{
         
              display: 'flex',
              flexDirection: 'column',
              alignSelf: 'center',
              justifyContent:'center',
              alignItems:'center'
        }}>
           <img src={tab2} id="wrap-content-image" style={{height:'30vh', marginLeft:15,alignSelf: 'center'}} />

            <p style ={{fontSize: 15,textAlign: 'center',width: 90,color:'#fff'}}>Tap Pit To See Details</p>
        </div>
        <div 
          style={{
         
              display: 'flex',
              justifyContent:'center',
              alignSelf: 'center',
              flexDirection: 'column',
              alignItems:'center'
        }}>
         <img src={tab3} id="wrap-content-image" style={{height:'30vh', marginLeft:15,alignSelf: 'center'}} />
         <p style ={{fontSize: 15,textAlign: 'center',width: 90,color:'#fff'}}>See Pit Details</p>
        </div>
           
           
        </div>
        <div
           style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent:'center',
            alignItems:'center',
			  marginTop: '10vh'
      }}>

       
        <div
             onClick={() => {
              this.setState({visible: true,snow: false})
          }}
          style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent:'center',
              color:'#fff',
              borderRightWidth:2,
              borderRightStyle:'solid',
              borderColor:'gray',
              paddingRight:10
        }}>
            <Icon type="left" style ={{fontSize: 15,marginTop:5,marginRight:5}} />
          <p style ={{fontSize: 15,fontWeight: 'bold',marginBottom:0}}>Main Help Screen</p>
          </div>
          <div
             onClick={() => {
              this.setState({pitDetail: true,snow: false})
          }}
          style={{
              display: 'flex',
              flexDirection: 'row',
              marginLeft: 10,
              justifyContent:'center',
              color:'#fff',
        }}>
          <p style ={{fontSize: 15,fontWeight: 'bold',marginBottom:0}}>Pit Detail View</p>
          <Icon type="right" style ={{fontSize: 15,marginTop:5,marginLeft:5}} />
          </div>
          </div>
        </Modal>
  <Modal   
  className="header-modal-view"        
  title={<span style={{display:'flex',flexDirection:'row'}}>
  <QuestionCircleOutlined 
  style={{paddingRight: 15, paddingTop:4,color:'#fff',fontSize:17}}  />
  <p style ={{fontSize: 16,fontWeight: 'bold',color:'#fff',fontSize:20}}>How To Use Snow Intel</p>
</span>}
    visible={pitDetail}
    footer ={null}
    onCancel={this.pitCancel}>
        <p style ={{marginTop: '-30px',fontSize: 18,textDecoration: 'underline',fontWeight: 'bold',textAlign: 'center',color:'#fff'}}>Research Conditions</p>
		<p style ={{marginTop: '-20px',fontSize: 14, textAlign: 'center',color:'#fff', marginBottom: 20}}>After Tapping A Pit</p>
     <div 
      id="pit-detail-view"
        style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent:'center',
     }}>
      <div 
        style={{
              display: 'flex',
              flexDirection: 'column',
              alignitems: 'center',
              justifyContent:'center'
        }}>
          <p style ={{fontSize: 16,textAlign: 'center',color:'#fff', marginBottom: 5}}>Snow Tab</p>
           <img src={tab3} style={{height: '30vh',alignSelf: 'center', marginBottom: 10}} />
            <p style ={{fontSize: 15,textAlign: 'center', alignSelf: 'cnter',color:'#fff', maxWidth: '300px'}}>Snow profiles help you understand how firm the snow will be under foot. Change the forecast time to watch for melt freeze effects!</p>
      </div>
      <div 
          style={{
              display: 'flex',
              justifyContent:'center',
              alignSelf: 'center',
              flexDirection: 'column',
        }}>
           <p style ={{fontSize: 16,textAlign: 'center',color:'#fff', marginBottom: 5}}>Weather Tab</p>
         <img src={weather} style={{height: '30vh',alignSelf: 'center', marginBottom: 10}} />
         <p style ={{fontSize: 15,textAlign: 'center',color:'#fff', maxWidth: '300px'}}>Hourly forecasts show pinpoint weather from weather.gov. Compare your pit locations using the arrows aside the location name</p>
      </div>  
    </div>
    <div
      style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent:'center',
            color:'#fff',
			marginTop: 30
      }}>
        <div
          onClick={() => {
          this.setState({snow: true, pitDetail:false})
          }}
          style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent:'center',
              color:'#fff',
              borderRightWidth:2,
              borderRightStyle:'solid',
              borderColor:'gray',
              paddingRight:10,
        }}>
            <Icon type="left" style ={{fontSize: 15,marginTop:5,marginRight:5}} />
            <p style ={{fontSize: 15,fontWeight: 'bold',marginBottom:0}}>Add A Pit</p>
        </div>
        <div
          onClick={() => {
          this.setState({iconDefinitions: true,pitDetail: false})
          }}
          style={{
              display: 'flex',
              flexDirection: 'row',
              marginLeft: 10,
              justifyContent:'center',
              color:'#fff'
        }}>
           <p style ={{fontSize: 15,fontWeight: 'bold',marginBottom:0}}>Icon Definitions</p>
           <Icon type="right" style ={{fontSize: 15,marginTop:5,marginLeft:5}} />
        </div>
    </div>
  </Modal>
  <Modal 
  className="header-modal-view"            
  title={
 
  <p style ={{fontSize: 16,fontWeight: 'bold',color:'#fff',fontSize:20}}>How To Use Snow Intel</p>
}
    visible={iconDefinitions}
    footer ={null}
    onCancel={this.iconDefinitions}>
        <p style ={{marginTop: '-20px',fontSize: 16,textDecoration: 'underline',fontWeight: 'bold',textAlign: 'center',color:'#fff'}}>Icons On The Pit Detail Screen</p>
     <div 
        style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent:'space-around',
              alignSelf: 'center'

     }}>
       <div style={{display:'flex',}}>
       {/* <p style ={{fontSize: 20,textDecoration: 'underline',fontWeight: 'bold',textAlign: 'center',color:'#fff',width:'48%'}}>Icon</p>
       <p style ={{fontSize: 20,textDecoration: 'underline',fontWeight: 'bold',textAlign: 'center',color:'#fff',width:'52%'}}>Meaning</p> */}
       </div>
       {/* <div style={{width:'30%',display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center'}}> */}
 
        <div style={{marginBottom:'1em',display:'flex',}}>  
        <div id="content-view-icon" style={{width:'35%',display:'flex',justifyContent:'flex-end'}}>
        <img src={Next} style={{height:30,paddingBottom:5,}} />
        </div>
        <div style={{width:'56%'}} id="content-view-text">
          <p style ={{fontSize: 15,textAlign: 'center',color:'#fff',}}>Next/Previous Pit in Account</p> 
          </div>
         </div>
         <div style={{marginBottom:'1em',display:'flex',}}> 
         <div id="content-view-icon" style={{width:'35%',display:'flex',justifyContent:'flex-end'}}>
         <img src={Angle} style={{width: 30,height:30,paddingBottom:5,}} />
         </div>
         <div style={{width:'56%'}} id="content-view-text">
          <p style ={{fontSize: 15,textAlign: 'center',color:'#fff',}}>Slope Angle of Pit Location</p>
          </div>
         </div>
         <div style={{marginBottom:'1em',display:'flex',}}> 
         <div id="content-view-icon" style={{width:'35%',display:'flex',justifyContent:'flex-end'}}>
         <img src={Height} style={{width: 30,height:30,paddingBottom:5,}} />
         </div>
         <div style={{width:'56%'}} id="content-view-text">
          <p style ={{fontSize: 15,textAlign: 'center',color:'#fff',}}>Altitude of Pit Location</p>
          </div>
         </div>
         <div style={{marginBottom:'1em',display:'flex'}}>
         <div id="content-view-icon" style={{width:'35%',display:'flex',justifyContent:'flex-end'}}> 
         <img src={Compass} style={{height:30,paddingBottom:5,}} />
         </div>
         <div style={{width:'56%'}} id="content-view-text">
          <p style ={{fontSize: 15,textAlign: 'center',color:'#fff',}}>Aspect of Pit Location</p>
          </div>
         </div>
         <div style={{marginBottom:'1em',display:'flex',}}>  
         <div id="content-view-icon" style={{width:'35%',display:'flex',justifyContent:'flex-end'}}>
         <img src={Tree} style={{width: 25,height:30,paddingBottom:5,}} />
         </div>
         <div style={{width:'56%'}} id="content-view-text">
          <p style ={{fontSize: 15,textAlign: 'center',color:'#fff',}}>Canopy Cover of Pit Location</p>
          </div>
         </div>
         <div style={{marginBottom:'1em',display:'flex',}}> 
         <div id="content-view-icon" style={{width:'35%',display:'flex',justifyContent:'flex-end'}}>
         <img src={Ski} style={{width: 25,height:30,paddingBottom:5,}} />
         </div>
         <div style={{width:'56%'}} id="content-view-text">
          <p style ={{fontSize: 15,textAlign: 'center',color:'#fff',}}>Skier Snow Penetration</p>
          </div>
         </div>
        {/* </div> */}
        <div>        
          
          
        
          
         
          
         
        </div>

    
    </div>
    {/* <div 
        style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent:'center',

     }}>
        
        
    
    </div> */}
    {/* <div 
        style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent:'center',

     }}>
       
        
    
    </div>
    <div 
        style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent:'center',

     }}>
        
        
    
    </div> */}
    {/* <div 
        style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent:'center',

     }}>
       
       
    
    </div> */}
    {/* <div 
        style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent:'center',

     }}>
       
        
    
    </div> */}
    {/* <div 
        style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent:'center',

     }}>
       
        
    
    </div> */}
    <div
      style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent:'center',
			marginRight: '150px'
      }}>
        <div
          onClick={() => {
          this.setState({iconDefinitions: false, pitDetail:true})
          }}
          style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems:'center',
              // justifyContent:'center',
              color:'#fff',
              borderRightWidth:2,
              borderRightStyle:'solid',
              borderColor:'gray',
              paddingRight:10,
			  marginTop: '10vh'
        }}>
            <Icon type="left" style ={{fontSize: 15,marginRight:5}} />
            <p style ={{fontSize: 15,fontWeight: 'bold',marginBottom:0}}>Pit Detail View</p>
        </div>
    </div>
  </Modal>
            </div>
        );
    }
}

export default HeaderComponent;

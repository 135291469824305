export default {
    header:{
        display: 'flex',
        justifyContent: 'center',
        color: 'black',
        fontSize: 20,
        fontWeight: 500
    },
    snowHeader: {
        display: 'flex',
        justifyContent: 'center',
        color: 'black',
        fontSize: 20,
        fontWeight: 500
    },
    card:{
        // width: 1320,
        height: 670,
    },
    modalText:{
        display: 'flex',
        color: '#ffffff',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    radioStyle: {
        // display: 'block',
        height: '4',
        // lineHeight: '30',
      },
      locationStyle: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },

}
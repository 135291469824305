export default {
    HOME: 'Home',
    LOCATION: 'Location',
    PREFERENCE: 'Preferences',
    USERSHOME: 'My Locations',
    SUBSCRIPTION: 'Subscription',
    PAYMENT: 'Payment',
    FEEDBACK: 'Feedback',
    ADD_CARD: 'Addcard',
    SEARCH_LOCATION: 'Search Existing',
    CHANGE_PASSWORDS:'Change Password',
    ADDTOHOMESCREEN: 'Add To Home Screen',
}

import React, { Component } from 'react';
import { Card, Modal, Button, Radio, Input, List, Avatar } from 'antd';
import { connect } from 'react-redux';
import styles from './preferences.Style';
import { getLocation } from '../../actions/locationAction';
import * as userApi from '../../api/users';
import { AuthService } from "../auth/auth.service";
import { userFields } from '../../api/collectionName';

const data = [
  // {
  //   title: 'Quality',
  //   description: 'Show the skiing quality of each layer based on both the density and hardness of each layer.',
  //   radioChecked: 'Ql',
  //   // PreferencesScreen:'Ql'
  // },
  {
    title: 'Density',
    description: 'Show the density of each layer. The denser the snow, the wetter and more consolidated it is.',
    radioChecked: 'De',
    // PreferencesScreen:'De'
  },
  {
    title: 'Hardness',
    description: 'Show the hardness of each layer. The harder the snow, the more it behaves like a cohesive slab.',
    radioChecked: 'Hd',
    // PreferencesScreen:'Hd'
  },
  {
    title: 'Temperature',
    description: 'Show the temperature of each layer.',
    radioChecked: 'Te',
    // PreferencesScreen:'Te'
  },
];

const detailData = [
  {
    title: 'Snow Tab',
    description: 'When a pit location is tapped on the map, the default view will be snow profile view.',
    radioChecked: 'St',
  },
  {
    title: 'Weather Tab',
    description: 'When a pit location is tapped on the map, the default view will be weather chart view.',
    radioChecked: 'Wt',
  },
]

class PreferencesScreen extends Component {
  constructor(props) {
    super(props);
	this.goBack = this.handleOk.bind(this); 
    this.state = {
      visible: false,
      value: null,
      detailValue: null,
      snowScale: false,
      radioItem: null,
      currentPreferenceValue: null,
      currentUser: null,
      currentUserUid: (localStorage.getItem('currentUserUid'))
    }
  }

  onFocusFunction() {
    //subscribe the urrent user details
    this.subscription = AuthService.getCurrentUser().subscribe(user => {
      console.log("componentDidMount this.state.currentUser", user)
      if (user) {
        this.setState({ currentUser: user.currentUser });
        // console.log("this.state.currentUser",this.state.currentUser)
      }
    });
  }

  async componentDidMount() {
    // this.focusListener = this.props.navigation.addListener('didFocus', () => {
    //     this.onFocusFunction()
    // })
    this.setState({
      currentUserUid: (localStorage.getItem('currentUserUid'))
    }, () => {
      this.props.getLocation(this.state.currentUserUid)
    });
    console.log("PREFERENCE Props", (localStorage.getItem('currentUserUid')))
  }

  componentWillReceiveProps(nxtprops) {
    this.setState({ currentUserUid: (localStorage.getItem('currentUserUid')) });
    if (nxtprops && nxtprops.location && nxtprops.location.preference) {
        console.log('location111', nxtprops.location)
        this.setState({
          value: nxtprops.location.preference.stake
        })
    }

    if (nxtprops && nxtprops.location && nxtprops.location.preference) {
       if(nxtprops.location.preference.detail) {
        this.setState({
          detailValue: nxtprops.location.preference.detail
        })
       } else {
        this.setState({
          detailValue: detailData[1].radioChecked
        })
       }
    }

  }

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = async (value, detailValue) => {
    try {
      if (value && this.state.currentUserUid) {
        let prefObject = {
          [userFields.STAKE]: value,
          [userFields.DETAIL]: detailValue
        }
        //update the preference value in users collection
        // console.log("this.state.currentUser",this.state.currentUser)
        await userApi.updateUserPreference(this.state.currentUserUid, prefObject, userFields.PREFERENCE);
      }

      if (value !== null) {
		  
		  
			this.props.history.goBack();

			  
    }} catch (e) {
      console.log("preference screen" + e);
    }
  };

  handleCancel = (e) => {
    this.setState({
      visible: false,
    });
  };

  onClick = (data) => {
  }

  onChange = (e) => {
    this.setState({
      value: e.target.value,
    });
  };

  onChangeDetailView = (e) => {
    this.setState({
      detailValue: e.target.value,
    });
  }

  preferenceClick = (data) => {
    if (data !== null) {
      this.setState({
        value: data
      })
    }

  }


  detailPreferenceClick = (data) => {
    if (data !== null) {
      this.setState({
        detailValue: data
      })
    }
  }

  render() {

    const { value, radioItem, detailValue } = this.state

    return (
      <div className="card-modal" id="prefrence-view" style={{ padding: '10px' }}>
        <Card style={styles.card} id="modal-card-view" className="preference-view">
          <div style={{ backgroundColor: 'white', height: 70, paddingTop: 15 }}>
            <div style={styles.header}>Preferences</div>
            {/* <div style={styles.header}>
                        Control what data you see
                    </div> */}
          </div>
          <div style={{ padding: 10 }}>

          </div>
          <div style={styles.snowHeader}>
            Default Detail View:
            </div>
          <Card style={styles.locationStyle}>
            <div
            //  style={{ paddingTop: 75 }} 
            id="preference-detail-value"
            >
              <Radio.Group onChange={this.onChangeDetailView} value={this.state.detailValue}>
                <List
                  // style={{ backgroundColor: 'white', marginLeft: '0%', marginRight: '0%' }}
                  className="preference-list-value"
                  itemLayout="horizontal"
                  dataSource={detailData}
                  value={this.state.detailValue}
                  renderItem={item => (
                    <List.Item>
                      <List.Item.Meta
                        title={item.title}
                        value={this.state.detailValue}
                        description={item.description}
                        onClick={() => this.detailPreferenceClick(item.radioChecked)}
                      />

                      <Radio
                        style={styles.radioStyle} value={item.radioChecked}
                       defaultChecked={detailData[1].radioChecked}
                      >
                      </Radio>

                    </List.Item>
                  )}
                />
              </Radio.Group>
            </div>

          </Card>
          <div style={styles.snowHeader}>
            Snow Profile View:
            </div>
          <Card style={styles.locationStyle}>
            <div
            //  style={{ paddingTop: 75 }}
            >
              <Radio.Group onChange={this.onChange} value={this.state.value}>
                <List
                  style={{ backgroundColor: 'white', marginLeft: '6%', marginRight: '3%' }}
                  itemLayout="horizontal"
                  dataSource={data}
                  value={this.state.value}
                  renderItem={item => (
                    <List.Item>
                      <List.Item.Meta
                        title={item.title}
                        value={this.state.value}
                        description={item.description}
                        onClick={() => this.preferenceClick(item.radioChecked)}
                      />

                      <Radio
                        style={styles.radioStyle} value={item.radioChecked}
                      //  defaultChecked={true}
                      >
                      </Radio>

                    </List.Item>
                  )}
                />
              </Radio.Group>
              {this.state.value !== null &&
                <div style={{ display: 'flex', justifyContent: 'flex-end', paddingTop: 10 }}>
                  <Button type="primary"
                    className="preferance-btn"
                    id="common-button-view"
                    onClick={() => this.handleOk(this.state.value, this.state.detailValue)}
                  >
                    Apply
             </Button>
                </div>
              }
            </div>

          </Card>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { location, locationLoading } = state.locationData // Reducer Name
  return {
    location,// Reducer variable
    locationLoading,
  }
}

const mapDispatchToProps = {
  getLocation, //Action
}
export default
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(PreferencesScreen);


export default {
    header:{
        display: 'flex',
        justifyContent: 'flex-start',
        color: 'black',
        marginLeft: 50,
        fontSize: 20,
    },
    card:{
        // width: 1320,
        height: 670,
        // display: 'flex',
        // justifyContent: 'center'
    },
    locationStyle: {
        // width: 1260,
        // height: 543,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        // backgroundColor: 'black',
        // marginLeft: 260
    },
    
    location: {
        display: 'flex',
        justifyContent: 'flex-start',
        color: 'black',
        paddingBottom: 20,
        fontSize: 18,
        fontWeight: 500
    },
    listStyle: {
        marginTop: 10, 
        marginRight: 30
    },
    listNewStyle: {
        marginTop: 10, 
        marginRight: 30,
        backgroundColor: '#333333'
    },
    filterStyle: {
        justifyContent: 'space-between',
        display: 'flex'
    },
    closeIcon: {
        position: 'absolute',
        right: 0,
        width: '100%',
        color: 'black',
        height: 32,
        padding: 7,
        borderStyle: 'solid',
        borderColor: '#e8e8e8',
        borderRadius: '1% 1% 1% 1%'

    }
}
import firebase from '../common/firebase';
import { collectionConfig, userFields } from './collectionName';

const db = firebase.firestore();

export async function createUser(userId, userObject, paymentInfo, preference) {
	
        console.log("userObject", userObject, paymentInfo, preference)
		var newRef = db.collection(collectionConfig.USERS_COLLECTION).doc(userId)
	   
		
		let document1 = {userInfo: userObject,
						 paymentInfo: paymentInfo,
						 preference: preference}
						 
						 console.log(document1)
        
                    newRef.set(document1, { merge: true })

}


export function updateUser(userId, userObject, paymentInfo, preference) {

    return new Promise((resolve, reject) => {
        let document = { [userFields.USER_INFO]: userObject }
        try {
			
			var oldRef = db.collection(collectionConfig.USERS_COLLECTION).doc(userId);
			/*
			oldRef.get().then(function(doc) {
			if (doc.exists) {
				
				console.log(doc.data())
				console.log(doc.data().paymentInfo)

				
				if(doc.data().preference == null){
				console.log("No Prefs")
				console.log(doc.data.preference)
				let document3 = {[userFields.PREFERENCE]: preference}
				oldRef.set(document3, { merge: true })

				}
				
		if(doc.data().paymentInfo == null){
				console.log("No Payment")
				console.log(doc.data.paymentInfo)

				let document2 =  {[userFields.PAYMENT_INFO]: paymentInfo}
				oldRef.set(document2, { merge: true })

			}
				
				if(doc.data().userInfo == null){
				console.log("No User")
				console.log(doc.data.userInfo)

		let document1 = {[userFields.USER_INFO]: userObject}
				oldRef.set(document1, { merge: true })

				}
				
			} else {
				// doc.data() will be undefined in this case
		
			}
				}).catch(function(error) {
					console.log("Error getting document:", error);
				});	  
			  
			  */
			
			
            oldRef.update(document).then((res) => {
                resolve(res);
            })
        } catch (err) {
            reject(err);
        }
    })
}

export function updateUserPreference(userId, prefObject, objectName) {

    let document = { [objectName]: prefObject }

    try {
        db.collection(collectionConfig.USERS_COLLECTION).doc(userId).update(document);
    } catch (err) {
        console.log("updateUserPreference" + err);
    }
}

export function updateUserLocation(userId, locationObject, objectName) {

    let document = { [objectName]: locationObject }

    console.log("document", document, userId)

    try {
        db.collection(collectionConfig.USERS_COLLECTION).doc(userId).update(document);
    } catch (err) {
        console.log("updateUserLocation" + err);
    }
}

import React, { Component } from 'react';
import { Alert, Card, Input, Icon, Button, Avatar, message, Spin, Row, Layout, Menu,Tag ,Form} from 'antd';
import styles from './styles';
import logo_forecast from '../../themes/assets/images/logo_forecast.png';
import  StartFirebaseUI  from './firebaseUI'
import firebase from '../../common/firebase';
import {AuthService, TemplateService} from "./auth.service";
// import VerifyEmailModal from '../../common/verifyEmailModal';
import ChangePasswordModal from '../../common/ChangePasswordModal'


const validEmailRegex =
  RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mailId: null,
            password: null,
            errors: {
                mailId: '',
                password: '',
              },
            emailValid: false,
            passwordValid: false,
            uid: '',
            formValid: false,
            currentUserData:null,
            enableForgotWindow:false,
            showPassAlert:false,
            loginLoader:false,
            currentUsermail: false
        };
    }
    async componentDidMount(){
        AuthService.getCurrentUser().subscribe((user)=>{
            if (user&& user.currentUser){
                this.setState({currentUserData:user.currentUser});
            }
        })
       await TemplateService.getVerify().subscribe((email)=>{
            if(email && email.verifiedEmail){
                this.setState({
                    currentUsermail: email
                 },()=>{
                    //  console.log('currentUsermail',this.state.currentUsermail)
                 })
            }
           
        })
    }

    componentWillReceiveProps(newsprops){
        AuthService.getCurrentUser().subscribe((user)=>{
            if (user&& user.currentUser){
                this.setState({currentUserData:user.currentUser});
            }
        })
        TemplateService.getVerify().subscribe((email)=>{
            if(email && email.verifiedEmail){
                this.setState({
                    currentUsermail: email
                 },()=>{
                    //  console.log('this.state.currentUsermail.verifiedEmail',this.state.currentUsermail, this.state.currentUsermail.verifiedEmail)
                 })
                 if(this.state.currentUsermail.verifiedEmail === true){
                    window.location = '/home'
                }
            }
           
        })
        
    }


    handleUserInput = (e) => {
        this.setState({
            mailId: e.target.value
        });
    }

    handleUserPassword = (e) => {
       this.setState({
        password: e.target.value
    });
    }

    resendVerificationEmail=()=>{
        this.setState({loginLoader:true})
        console.log("resendVerificationEmail currentUser",this.state.currentUserData)
        var user = (!firebase.auth().currentUser) ? (firebase.auth().currentUser) : this.state.currentUserData;
        console.log("resendVerificationEmail",user)
        if (user){
            user.sendEmailVerification().then(()=>{
                this.setState({loginLoader:false})
            }).catch((err)=>{
                this.setState({loginLoader:false})
            })
        }

}


    forgotPass(e){
        const {mailId}=this.state
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                // console.log('Received values of form: ', values);
                if(values.mailId && values.mailId != null){
                    firebase.auth().sendPasswordResetEmail(values.mailId).then((res)=>{
                        // console.log("forgotpass",res)
                       this.setState({showPassAlert:true,enableForgotWindow:false})
                    }).catch((err)=>{
                        // console.log("forgotpass",err)
                    })
                }
            }
        });

}

    renderAlert(){
        return  <div style={{display:'flex',justifyContent:'center', alignItems:'center'}}>
            <Alert
            message="Verification Link has been sent to your email account"
            description="Please click on the link to continue Forecast"
            type="success"
            showIcon
        />
        </div>
    }

    renderPasswordAlert(){
        return (
            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <Alert
                    message="Reset Password Link has been sent to your email account"
                    description="Please click on the link to reset password"
                    type="success"
                    showIcon
                />
            </div>
        )

    }

    handleClick(value,alertValue){
        // console.log("handleClickenableForgotWindow",value,alertValue);
        this.setState({enableForgotWindow:value,showPassAlert:alertValue});
    }

    render() {
        const {mailId, password,showPassAlert} = this.state
        const { getFieldDecorator, getFieldsError, getFieldError, isFieldTouched } = this.props.form;

        return (
            <div className="containerStyle">
                <Card>
                <div style={styles.logoPosition}>
                <img
                        // height={150}
                        height={120}
                        src={logo_forecast}
                        alt=''
                       />

                </div>
                <b style={{ display: 'flex', justifyContent: 'center', fontSize: 20}}>
                         Snow Intel
                      </b>
                    {this.state.loginLoader ?
                        <div style={{fontWeight: 'bold', fontSize: 18, textAlign: 'center'}}>
                            <Spin tip="Loading..." size="large"/>
                        </div>

                        :
                        <div>
                            {
                                ((this.state.currentUserData != null) && (this.state.currentUserData.emailVerified != null) && (this.state.currentUserData.emailVerified === false)) &&
                                this.renderAlert()
                            }

                            <div style={styles.cardPosition}>
                                <Card>
                             
                                    {
                                        !this.state.enableForgotWindow ?
                                            <div>
                                                {showPassAlert&&
                                                 this.renderPasswordAlert()
                                                }
                                    <StartFirebaseUI history={this.props.history}/>
                                                
                                                <a
                                                    type="primary"
                                                    style={{
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        marginTop: 15,
                                                        color: '#00AEEF',
                                                        textDecoration: 'underline'
                                                    }}
                                                    onClick={() => {
                                                        this.setState({enableForgotWindow: true})
                                                    }}>
                                                    Forgot Password
                                                </a>
                                                {
                                                    ((this.state.currentUserData != null) && (this.state.currentUserData.emailVerified != null) && (this.state.currentUserData.emailVerified !== true)) &&
                                                    <a
                                                        type="primary"
                                                        style={{
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                            marginTop: 15,
                                                            color: '#00AEEF',
                                                            textDecoration: 'underline'
                                                        }}
                                                        onClick={() => {
                                                            this.resendVerificationEmail()
                                                        }}>
                                                        Resend Verification Email
                                                    </a>
                                                }
                                            </div>

                                            :
                                            <div>
                                            <ChangePasswordModal screen="login" onResetPassCallBack={(val,alertValue)=>this.handleClick(val,alertValue)}/>
                                            </div>
                                    }

                                </Card>
                            </div>
                        </div>
                    }
                </Card>


            </div>
        );
    }
}

export default Form.create()(Login);



export function createSubscriptionPlan(data) {
    // console.log("createSubscriptionPlan", data)
    return new Promise((resolve, reject) => {
        fetch('https://us-central1-snow-intel.cloudfunctions.net/subscriptionPlan', {
            method: 'POST',
            body: JSON.stringify(data)
        })
            .then(res => res.json())
            .then((result) => {
                // console.log('result', result)
                resolve(result)
            }).catch((error) => {
            reject(error)
        })
    })

}

export function cancelSubscriptionPlan(data) {
    // console.log("cancelSubscriptionPlan", data)
    return new Promise((resolve, reject) => {
        fetch('https://us-central1-snow-intel.cloudfunctions.net/cancelSubscriptionPlan', {
            method: 'POST',
            body: JSON.stringify(data)
        })
            .then(res => res.json())
            .then((result) => {
                // console.log('result', result)
                resolve(result)
            }).catch((error) => {
            reject(error)
        })
    })

}


import React, {useEffect, useState} from 'react';
import {Elements, StripeProvider} from 'react-stripe-elements';
import CardForm from './CardForm';


const testKey = "pk_test_yDPPmkEHA1t9nEKBqd151LMe00lSsTnbaG "

const StripeWrapper = (choosenValue) => {
    const [stripe, setStripe] = useState(null);
console.log("choosenValue", choosenValue)
    useEffect(() => {
        // for SSR
        if (typeof window == 'undefined') return;
        // for browser
        if (window.Stripe) {
            // setStripe(window.Stripe('pk_test_WzWZpk627v1FQyYAhBQ4zIbA00w6y1QklQ'));
            setStripe(window.Stripe('pk_live_xhiKDPso7K2SwVib71zBvJ3T00yKMghNJL '));
            console.log(setStripe, 'setStripe')
        } else {
            const stripeScript = document.querySelector('#stripe-js');
            stripeScript.onload = () => {
                // setStripe(window.Stripe('pk_test_WzWZpk627v1FQyYAhBQ4zIbA00w6y1QklQ'));
                setStripe(window.Stripe('pk_live_xhiKDPso7K2SwVib71zBvJ3T00yKMghNJL '));
            };
        }
    }, []);

    return (
        <StripeProvider stripe={stripe}>
            <Elements>
                <CardForm choosenValue={choosenValue} history={choosenValue.history}/>
            </Elements>
        </StripeProvider>
    );
};

const AddCard = (props) => {
    console.log("element", props)
    return <section className="cart-area ptb-60">
        <div className="cart-totals">
            <StripeWrapper choosenValue={props.match.params.id} history={props.history}/>
        </div>
    </section>
};

export default AddCard;

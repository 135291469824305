export const collectionConfig={
    USERS_COLLECTION: 'users',
    FEEDBACK:'feedbacks'
}


//users collection fields
export const userFields={
    USER_INFO:'userInfo',
    PAYMENT_INFO:'paymentInfo',
    PREFERENCE:'preference',
    STAKE:'stake',
    LOCATION:'location',
    DETAIL: 'detail',
}

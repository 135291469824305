export default {
    header:{
        display: 'flex',
        justifyContent: 'center',
        color: 'black',
        fontSize: 18,
        fontWeight: 400
    },
    card:{
        // width: 1320,
        minHeight: '95vh'
    },
    picker:{
        display: 'flex',
        marginTop: 20,
        justifyContent: 'center',
        marginBottom: 50
    },
    pickerText:{
        display: 'flex',
        justifyContent: 'center',
        fontSize: 15,
        fontWeight: 600,
        alignItems:'center',
        // marginBottom:15
        // marginTop: 10,
    },
    pickerView:{
      display: 'flex',
	  position: 'fixed',
	  bottom: '0px',
      justifyContent: 'center',
      flexDirection:'column',
      background:'#000',
      alignItems:'center',
      fontSize: 15,
      fontWeight: 600,
      // width:'35%',
      // marginBottom:15
      marginTop: 30,
      paddingTop:10,
      paddingBottom:10,
      width:'100%'
  },
    avalancheText:{
        display: 'flex',
        justifyContent: 'center',
        fontSize: 15,
        fontWeight: 600,
        marginBottom: '-8px'
    },
    scaleView:{
        display: 'flex',
        justifyContent: 'center',
        flexDirection : 'column',
        alignItems: 'center'
        // overflowY:'auto'
        // overflowY:'auto'
        // marginTop:20
        // fontSize: 13,
        // fontWeight: 600,
        // marginTop: 10,
        // height: 385,
    },
	hideView:{
        display: 'flex',
        justifyContent: 'center',
        flexDirection : 'column',
        alignItems: 'center',
        height: '0px',
        // overflowY:'auto',
        //marginTop: '5vh'
        // overflowY:'auto'
        // marginTop:20
        // fontSize: 13,
        // fontWeight: 600,
        // marginTop: 10,
        // height: 385,
    },
   
    menuItem: {
        borderRight: '5px solid', 
        borderRightColor: 'black', 
        borderLeft: '5px solid',
        borderLeftColor: 'black'
    },
    menuText: {
        // marginTop: 10, 
        fontSize: 15, 
        fontWeight: 700,
        display: 'flex',
        justifyContent:'center'
    },
    grassImage: {
        marginTop: 10, 
        fontSize: 15, 
        fontWeight: 700,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    warningText: {
        display: 'flex',
        justifyContent: 'center'
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
      },
      textField: {
        marginLeft: 20,
        marginRight:10,
        width: 200,
      },
      tabStyle: {
        //   color: 'black',
          marginTop: '10px',
          display: 'flex',
          justifyContent: 'center'
      },
      tabNewStyle: {
        marginTop: '10px',
        display: 'flex',
        justifyContent: 'center',
        color: 'white',
        background: 'lightgrey',
        border: '1px solid lightgrey'

      },
      afterIconStyle: {
        marginLeft: '30px',
        color: 'black'
      },
      afterIcondisable: {
        marginLeft: '30px',
        color: 'grey'
      },
      beforeIconStyle: {
        marginRight: '30px',
        color: 'black'
      },
      beforeIcondisable: {
        marginRight: '30px',
        color: 'grey'
      }
       
    // scaleHeight: {
    //     height: this.scaleHeight()
    // }
}
